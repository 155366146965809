import React from 'react';
import { Card, Row, Col, Tabs } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link'
import { formatDate } from '../../component/util'
import { useTab } from '../../component/tab';
import Picture from '../spk/picture'
import Fetch from '../../Fetch';
import WidgetPosition from './widget_position';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/stnk/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const tab = useTab(props, 'tab', 'info')

    const printClicked = () => {
        window.open(Fetch.getUrl(`/stnkprint/${record.id}/pdf?access_token=${localStorage.getItem('token')}`), "_blank")
    }

    return (
        <Card loading={loading} title={<Title title={"Detail STNK"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="STNK" url="stnk" showDelete={false}
                showPrint={loading ? false : record.status === 'done'} printClicked={printClicked} />} />} >
            {!loading &&
                <Tabs  {...tab}>
                    <Tabs.TabPane tab="Info" key="info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Tanggal DO">{record ? <MyLink to={`/delivery/${record.delivery_id}`}>{formatDate(record.created_at)}</MyLink> : ''}</TextField>
                                <TextField {...col} label="No SPK">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.number}</MyLink> : ''}</TextField>
                                <TextField {...col} label="Motor">{record ? record.motor ? record.motor.name : '' : ''}</TextField>
                                <TextField {...col} label="Warna">{record ? record.color ? record.color.name : '' : ''}</TextField>
                                <TextField {...col} label="No Mesin">{record ? <MyLink to={`/stock/${record.stock.id}`}>{record.stock.machine_number}</MyLink> : ''}</TextField>
                                <TextField {...col} label="Catatan">{record ? record.note : ''}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Tanggal KTP">{formatDate(record.ktp_date)}</TextField>
                                <TextField {...col} label="Tanggal Faktur">{formatDate(record.input_faktur_date)}</TextField>
                                <TextField {...col} label="Tanggal Samsat">{formatDate(record.samsat_process)}</TextField>
                                <TextField {...col} label="Tanggal Jadi">{formatDate(record.recieved)}</TextField>
                                <TextField {...col} label="Tanggal Konsumen">{formatDate(record.sent_consument)}</TextField>
                                <TextField {...col} label="No Polisi">{record.plate_number}</TextField>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Posisi" key="position">
                        <WidgetPosition stnk={record.id} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Gambar" key="picture">
                        <Picture spkid={record.spk_id} type="stnk" />
                    </Tabs.TabPane>
                </Tabs>
            }
        </Card >
    );
}

export default Detail;