import React, { Fragment } from 'react';
import { Card, Tabs, Drawer, Form, Button, Select, message, DatePicker } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { useTab } from '../../component/tab';
import Info from './info'
import History from '../prospek/history';
import Comment from '../prospek/comment'
import Picture from './picture'
import Payment from './payment'
import Fetch from '../../Fetch';
import { surveyStatus } from '../../constant'
import moment from 'moment'
import { InputText } from '../../component/input';
import Complain from './complain'
import UpdateStatus from './drawer_update_status';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/spk/${props.match.params.id}`);
    const { getFieldDecorator } = props.form;
    const [state, setState] = React.useState({
        drawerVisible: false, saveLoading: false, total: 0,
        drawerSurveyorVisible: false, drawerPOVisible: false, drawerIndent: false
    })
    const tab = useTab(props, 'tab', 'info')

    const formSurveyorSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.post(`/spk/surveyor/status/${record.id}`, values);
                    message.info('Status surveyor berhasil di update');
                    fetchData();
                    setState({ ...state, saveLoading: false, drawerSurveyorVisible: false })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const formPOSubmited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.put(`/spk/${record.id}`, { po: values.po_date !== null, po_number: values.po_number, po_date: values.po_date });
                    message.info('Status PO berhasil di update');
                    fetchData();
                    setState({ ...state, saveLoading: false, drawerPOVisible: false })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const formIndentSubmited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.put(`/spk/${record.id}`, { indent: values.indent !== null, indent_date: values.indent_date });
                    message.info('Status indent berhasil di update');
                    fetchData();
                    setState({ ...state, saveLoading: false, drawerIndent: false })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const fixPayment = async () => {
        await Fetch.post(`/spkpayment/fix/${record.id}`, {});
        message.info('Pembayaran di reset');
        fetchData();
    }

    React.useEffect(() => {
        const d = -record.discount + record.progressive + record.violation
        const total = record.cash ? record.otr_price + d : record.dp + d;
        setState({ ...state, total: total })
    }, [record])

    return (
        <Card loading={loading} title={<Title title={"Detail SPK"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="SPK" url="spk" record={record}
                onUpdateStatus={() => setState({ ...state, drawerVisible: true })}
                onUpdateStatusSurveyor={() => setState({ ...state, drawerSurveyorVisible: true })}
                onPoClick={() => setState({ ...state, drawerPOVisible: true })}
                onFixPaymentClick={fixPayment}
                onIndentClick={() => setState({ ...state, drawerIndent: true })} />} />} >
            {!loading &&
                <Fragment>
                    <Tabs {...tab}>
                        <Tabs.TabPane tab="Info" key="info">
                            <Info record={record} total={state.total} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Pembayaran" key="payment">
                            <Payment record={record} reload={() => fetchData()} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Gambar" key="picture">
                            <Picture spkid={record.id} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Komentar" key="comment">
                            <Comment spkid={record.id} spk />
                        </Tabs.TabPane>
                        {record.spk_status === 'do' &&
                            <Tabs.TabPane tab="Komplain" key="complain">
                                <Complain spkid={record.id} spk />
                            </Tabs.TabPane>
                        }
                        <Tabs.TabPane tab="Sejarah" key="history">
                            <History spkid={record.id} />
                        </Tabs.TabPane>
                    </Tabs>
                    <UpdateStatus visible={state.drawerVisible} onClose={() => setState({ ...state, drawerVisible: false })} record={record}
                        fetchData={fetchData} />
                    <Drawer visible={state.drawerSurveyorVisible} title="Update status Surveyor" width={350}
                        onClose={() => setState({ ...state, drawerSurveyorVisible: false })}>
                        {state.drawerSurveyorVisible && <Form name="update_status" onSubmit={formSurveyorSummited}>
                            <Form.Item label="Status surveyor baru">
                                {getFieldDecorator('surveyor_status', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(
                                    <Select placeholder="Pilih status surveyor baru">
                                        {surveyStatus.filter(v => v.value !== 'none' && v.value !== record.surveyor_status).map(v =>
                                            <Select.Option key={v.value}>{v.text}</Select.Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Catatan">
                                {getFieldDecorator('spk_note', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(
                                    <InputText placeholder="Masukkan catatan" />
                                )}
                            </Form.Item>
                            <Button icon="pushpin" type="primary" htmlType="submit" loading={state.saveLoading}>Update status</Button>
                        </Form>}
                    </Drawer>
                    <Drawer visible={state.drawerPOVisible} title="Update PO" width={350}
                        onClose={() => setState({ ...state, drawerPOVisible: false })}>
                        {state.drawerPOVisible && <Form name="update_status" onSubmit={formPOSubmited}>
                            <Form.Item label="Tanggal PO">
                                {getFieldDecorator('po_date', {
                                    initialValue: record.po_date ? moment(record.po_date) : undefined,
                                })(
                                    <DatePicker placeholder="Masukkan tanggal PO" format="DD-MM-YYYY" />
                                )}
                            </Form.Item>
                            <Form.Item label="No PO">
                                {getFieldDecorator('po_number', {
                                    initialValue: record.po_number,
                                    rules: [{ required: true, message: 'Wajib diisi' }],
                                })(
                                    <InputText placeholder="Masukkan no PO" />
                                )}
                            </Form.Item>
                            <Button icon="pushpin" type="primary" htmlType="submit" loading={state.saveLoading}>Update PO</Button>
                        </Form>}
                    </Drawer>
                    <Drawer visible={state.drawerIndent} title="Update Indent" width={350}
                        onClose={() => setState({ ...state, drawerIndent: false })}>
                        {state.drawerIndent && <Form name="update_status" onSubmit={formIndentSubmited}>
                            <Form.Item label="Tanggal Indent">
                                {getFieldDecorator('indent_date', {
                                    initialValue: record.indent_date ? moment(record.indent_date) : undefined,
                                })(
                                    <DatePicker placeholder="Masukkan tanggal indent" format="DD-MM-YYYY" />
                                )}
                            </Form.Item>
                            <Button icon="pushpin" type="primary" htmlType="submit" loading={state.saveLoading}>Update Indent</Button>
                        </Form>}
                    </Drawer>
                </Fragment>
            }
        </Card >
    );
}

export default Form.create({ name: 'update_status' })(Detail);