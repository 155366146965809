import React from 'react';
import { Card, Button, Select, Table } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import moment from 'moment'

const Home = props => {
    const [state, setState] = React.useState({ loading: true, data: [], year: (new Date()).getFullYear(), month: (new Date()).getMonth() })

    React.useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async (year = state.year, month = state.month) => {
        setState({ ...state, loading: true })
        const { data } = await Fetch.get(`/performance/${year}/${month}`)
        let dealer = { prospek: 0, spk: 0, delivery: 0, ktp: 0, po: 0, payment: 0, claim: 0, target_prospek: 0, target_spk: 0, target_sale: 0 }
        let d = [];
        for (let i = 0; i < data.length; i++) {
            const team = data[i];
            let t = { prospek: 0, spk: 0, delivery: 0, ktp: 0, po: 0, payment: 0, claim: 0, target_prospek: 0, target_spk: 0, target_sale: 0 };
            for (let j = 0; j < team.users.length; j++) {
                const user = team.users[j];
                const prospek = team.prospeks === null ? { count: 0, target: 0 } : team.prospeks.find(v => v.user_id === user.id) || { count: 0, target: 0 };
                const spk = team.spks === null ? { count: 0, target: 0 } : team.spks.find(v => v.user_id === user.id) || { count: 0, target: 0 };
                const delivery = team.deliveries === null ? { count: 0, target: 0 } : team.deliveries.find(v => v.marketing_id === user.id) || { count: 0, target: 0 };
                const ktp = team.ktp === null ? { count: 0 } : team.ktp.find(v => v.marketing_id === user.id) || { count: 0 };
                const po = team.po === null ? { count: 0 } : team.po.find(v => v.user_id === user.id) || { count: 0 };
                const payment = team.payment === null ? { count: 0 } : team.payment.find(v => v.user_id === user.id) || { count: 0 };
                const claim = team.claim === null ? { count: 0 } : team.claim.find(v => v.marketing_id === user.id) || { count: 0 };
                d.push({
                    'user': user, 'team': team.team, 'prospek': prospek.count, 'spk': spk.count, 'delivery': delivery.count, "ktp": ktp.count, "po": po.count,
                    "payment": payment.count, "claim": claim.count, 'target_prospek': user.target_prospek,
                    'target_spk': user.target_spk, 'target_sale': user.target_sale,
                })
                dealer = {
                    prospek: dealer.prospek + prospek.count, spk: dealer.spk + spk.count,
                    delivery: dealer.delivery + delivery.count, ktp: dealer.ktp + ktp.count,
                    po: dealer.po + po.count, payment: dealer.payment + payment.count, claim: dealer.claim + claim.count,
                    target_prospek: dealer.target_prospek + user.target_prospek,
                    target_spk: dealer.target_spk + user.target_spk, target_sale: dealer.target_sale + user.target_sale
                }
                t = {
                    prospek: t.prospek + prospek.count, spk: t.spk + spk.count,
                    delivery: t.delivery + delivery.count, ktp: t.ktp + ktp.count,
                    po: t.po + po.count, payment: t.payment + payment.count, claim: t.claim + claim.count,
                    target_prospek: t.target_prospek + user.target_prospek,
                    target_spk: t.target_spk + user.target_spk, target_sale: t.target_sale + user.target_sale
                }
            }
            d.unshift({ ...t, user: { name: 'SEMUA' }, team: { name: team.team.name } })
        }
        d.unshift({ ...dealer, user: { name: 'SEMUA' }, team: { name: 'SEMUA' } })
        setState({ ...state, loading: false, data: d })
    }

    const getYears = () => {
        const d = (new Date()).getFullYear();
        let arr = [];
        for (let i = 0; i < 2; i++)
            arr.push(d - i);
        return arr;
    }

    const getMonths = () => {
        let arr = [];
        for (let i = 0; i < 12; i++) {
            arr.push({ value: i, name: moment().month(i).format('MMMM') })
        }
        return arr;
    }

    const actions = (
        <span>
            <span style={{ padding: '0 5px' }}>
                <Select style={{ width: 100 }} defaultValue={(new Date()).getFullYear()} onChange={v => {
                    setState({ ...state, year: v });
                    fetchData(v, state.month);
                }}>
                    {getYears().map(v =>
                        <Select.Option value={v} key={v}>{v}</Select.Option>
                    )}
                </Select>
            </span>
            <span style={{ padding: '0 5px' }}>
                <Select style={{ width: 120 }} defaultValue={(new Date()).getMonth()} onChange={v => {
                    setState({ ...state, month: v });
                    fetchData(state.year, v);
                }}>
                    {getMonths().map(v =>
                        <Select.Option value={v.value} key={v.value}>{v.name}</Select.Option>
                    )}
                </Select>
            </span>
            <Button icon="reload" onClick={() => fetchData()} />
        </span>
    )

    return (
        <Card loading={state.loading} title={<Title title={"Performance"} actions={actions} />} >
            {!state.loading && <div>
                <Table dataSource={state.data} rowKey="id">
                    <Table.Column rowKey="team" dataIndex="team.name" title="Team" />
                    <Table.Column rowKey="name" dataIndex="user.name" title="Nama" />
                    <Table.Column rowKey="prospek" dataIndex="prospek" title="Prospek" render={(t, r) => `${r.prospek} / ${r.target_prospek}`} />
                    <Table.Column rowKey="spk" dataIndex="spk" title="SPK" render={(t, r) => `${r.prospek} / ${r.target_spk}`} />
                    <Table.Column rowKey="delivery" dataIndex="delivery" title="DO" render={(t, r) => `${r.prospek} / ${r.target_sale}`} />
                    <Table.Column rowKey="ktp" dataIndex="ktp" title="Pending KTP" />
                    <Table.Column rowKey="po" dataIndex="po" title="Pending PO" />
                    <Table.Column rowKey="payment" dataIndex="payment" title="Pending Pembayaran" />
                    <Table.Column rowKey="claim" dataIndex="claim" title="Pending Pelunasan Unit" />
                </Table>
            </div>}
        </Card >
    );
}

export default Home;