import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, DatePicker, InputNumber } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop, MyLink } from '../../component/link'
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import { LabelHeader } from '../../component/header'
import moment from 'moment'
import { InputText, InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false });
    const col = { labelSpan: 8, valueSpan: 16 }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/stnk/${props.match.params.id}`, values);
                        message.info('STNK berhasil diedit');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/stnk');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/stnk/${props.match.params.id}`);
        setState({ ...state, loading: false, record: data });
        setFieldsValue({
            ktp_date: data.ktp_date ? moment(data.ktp_date) : undefined,
            samsat_process: data.samsat_process ? moment(data.samsat_process) : undefined,
            recieved: data.recieved ? moment(data.recieved) : undefined,
            sent_consument: data.sent_consument ? moment(data.sent_consument) : undefined,
            input_faktur_date: data.input_faktur_date ? moment(data.input_faktur_date) : undefined,
            note: data.note, plate_number: data.plate_number,
            bbn: data.bbn, biro: data.biro, other_cost: data.other_cost,
            srut: data.srut,
        });
    }

    const { record } = state;

    return (
        <Card title={<Title title={edit ? "Edit stnk" : "Tambah stnk"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_stnk" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <TextField {...col} label="Tanggal DO">{record ? <MyLink to={`/delivery/${record.delivery_id}`}>{formatDate(record.created_at)}</MyLink> : ''}</TextField>
                        <TextField {...col} label="Motor">{record && record.motor ? record.motor.name : ''}</TextField>
                        <TextField {...col} label="Warna">{record ? record.color.name : ''}</TextField>
                        <TextField {...col} label="No Mesin">{record ? <MyLink to={`/stock/${record.stock.id}`}>{record.stock.machine_number}</MyLink> : ''}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="No SPK">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.number}</MyLink> : ''}</TextField>
                    </Col>
                </Row>
                <LabelHeader>STNK</LabelHeader>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tanggal KTP">
                            {getFieldDecorator('ktp_date')(
                                <DatePicker placeholder="Masukkan tanggal KTP" format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                        <Form.Item label="Tanggal Faktur">
                            {getFieldDecorator('input_faktur_date')(
                                <DatePicker placeholder="Masukkan input faktur" format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                        <Form.Item label="Tanggal Samsat">
                            {getFieldDecorator('samsat_process')(
                                <DatePicker placeholder="Masukkan tanggal dikirim samsat" format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                        <Form.Item label="Tanggal Jadi">
                            {getFieldDecorator('recieved')(
                                <DatePicker placeholder="Masukkan tanggal STNK jadi" format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Tanggal Konsumen">
                            {getFieldDecorator('sent_consument')(
                                <DatePicker placeholder="Masukkan tanggal diterima konsumen" format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                        <Form.Item label="SRUT">
                            {getFieldDecorator('srut')(
                                <InputText autosize placeholder="Masukkan SRUT" />
                            )}
                        </Form.Item>
                        <Form.Item label="Nomor polisi">
                            {getFieldDecorator('plate_number')(
                                <InputText autosize placeholder="Masukkan plat nomor" />
                            )}
                        </Form.Item>
                        <Form.Item label="Catatan">
                            {getFieldDecorator('note')(
                                <InputTextArea autosize placeholder="Masukkan catatan" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <LabelHeader>Biro Jasa</LabelHeader>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="BBN">
                            {getFieldDecorator('bbn')(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Biaya BBN" />
                            )}
                        </Form.Item>
                        <Form.Item label="Biro Jasa">
                            {getFieldDecorator('biro')(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Biaya biro jasa" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/stnk')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_stnk' })(Add);