import React from 'react';
import { Card, Form, Drawer, Divider, Button, message, Table, Popconfirm, Tag } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import MySelect from '../../component/select';
import { InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [, tbLocation] = useTable('/stocklocation', {})
    const [, tbStock] = useTable('/stock', { filter: { on_stock: 't' } })
    const take = props.match.path === '/stocksent/:id/take'
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({
        loading: edit, saveLoading: false, drawerPlaceVisible: false,
        drawerStockVisible: false, stocks: [], removed: [], added: [],
        selectedRowKeys: [], selectedRows: []
    });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit && !take) {
                        await Fetch.put(`/stocksent/${props.match.params.id}`, values);
                        for (let i = 0; i < state.removed.length; i++) {
                            const f = state.oldStock.find(v => v.stock.id === state.removed[i].id);
                            if (f) {
                                await Fetch.del(`/stocksentdata/${f.id}`)
                            }
                        }
                        for (let i = 0; i < state.added.length; i++) {
                            const s = state.added[i];
                            await Fetch.post('/stocksentdata', { motor_id: s.motor_id, color_id: s.color_id, stock_id: s.id, stock_sent_id: props.match.params.id })
                        }
                        message.info('Kirim stock berhasil diedit');
                    } else {
                        const res = await Fetch.post('/stocksent', values);
                        if (take) {
                            for (let i = 0; i < state.selectedRows.length; i++) {
                                const s = state.selectedRows[i];
                                await Fetch.post('/stocksentdata', { motor_id: s.motor_id, color_id: s.color_id, stock_id: s.id, stock_sent_id: res.id })
                            }
                        } else {
                            for (let i = 0; i < state.stocks.length; i++) {
                                const s = state.stocks[i];
                                await Fetch.post('/stocksentdata', { motor_id: s.motor_id, color_id: s.color_id, stock_id: s.id, stock_sent_id: res.id })
                            }
                        }
                        message.info('Kirim stock berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/stocksent');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/stocksent/${props.match.params.id}`);
        const stocks = await Fetch.get(`/stocksentdata?filter=(stock_sent_data.stock_sent_id,,eq,,${props.match.params.id})`)
        setFieldsValue({
            from_id: take ? data.to_id : data.from_id,
            to_id: take ? data.from_id : data.to_id,
            note: take ? 'Tarik stock' : data.note,
        });
        setState({
            ...state, loading: false, oldStock: stocks.data,
            stocks: take ? stocks.data.filter(v => v.stock.on_stock).map(v => ({ ...v.stock, motor: v.motor, color: v.color })) :
                stocks.data.map(v => ({ ...v.stock, motor: v.motor, color: v.color }))
        });
    }

    const onRowLocation = (record) => {
        return {
            onClick: () => {
                if (state.cur === 'from') {
                    setFieldsValue({ from: record.name })
                    setState({ ...state, drawerPlaceVisible: false, from: record })
                } else {
                    setFieldsValue({ to: record.name })
                    setState({ ...state, drawerPlaceVisible: false, to: record })
                }
            }
        }
    }
    const onRowStock = (record) => {
        return {
            onClick: () => {
                const ind = state.stocks.findIndex(v => v.id === record.id)
                if (ind < 0) {
                    if (edit) {
                        const oldIn = state.oldStock.findIndex(v => v.stock.id === record.id);
                        const removed = state.removed.filter(v => v.id !== record.id)
                        setState({
                            ...state, drawerPlaceVisible: false, stocks: [...state.stocks, record],
                            added: oldIn < 0 ? [...state.added, record] : state.added, removed: removed
                        })
                    } else {
                        setState({ ...state, drawerPlaceVisible: false, stocks: [...state.stocks, record] })
                    }
                }
                else message.error('Stock sudah masuk ')
            }
        }
    }
    const onConfirm = (record) => {
        return () => {
            if (edit) {
                const addIn = state.added.findIndex(v => v.id === record.id);
                const added = addIn < 0 ? state.added : state.added.filter(v => v.id !== record.id)
                const removed = addIn < 0 ? [...state.removed, record] : state.removed;
                setState({ ...state, stocks: state.stocks.filter(v => v.id !== record.id), added, removed })
            } else
                setState({ ...state, stocks: state.stocks.filter(v => v.id !== record.id) })
        }
    }

    const rowSelection = {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setState({ ...state, selectedRowKeys: selectedRowKeys, selectedRows: selectedRows, })
        },
        getCheckboxProps: record => ({
            disabled: record.subsidi_ahm === 0,
        }),
    };

    return (
        <Card title={<Title title={take ? 'Tarik stock' : edit ? "Edit kirim stock " : "Tambah kirim stock"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_stocksend" onSubmit={formSummited} {...formItemLayout}>
                <Form.Item label="Asal" style={{ marginBottom: 4 }}>
                    {getFieldDecorator('from_id', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <MySelect url="/stocklocation?order=name ASC&limit=1000" valueIndex="id" dataIndex="name" showSearch
                            placeholder="Pilih asal" disabled={take} />
                    )}
                </Form.Item>
                <Form.Item label="Tujuan" style={{ marginBottom: 4 }}>
                    {getFieldDecorator('to_id', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <MySelect url="/stocklocation?order=name ASC&limit=1000" valueIndex="id" dataIndex="name" showSearch
                            placeholder="Pilih tujuan" disabled={take} />
                    )}
                </Form.Item>
                <Form.Item label="Catatan" style={{ marginBottom: 4 }}>
                    {getFieldDecorator('note')(
                        <InputTextArea autosize placeholder="Masukkan catatan" />
                    )}
                </Form.Item>
                <Divider />
                <LabelHeader actions={!take &&
                    <Button icon="plus" type="primary" onClick={() => setState({ ...state, drawerStockVisible: true })}>Tambah motor</Button>}>
                    Stock yang dikirim</LabelHeader>
                <Table rowKey="id" dataSource={state.stocks} pagination={false} rowSelection={take ? rowSelection : null}>
                    <Table.Column key="motor" dataIndex="motor.name" title="Motor" />
                    <Table.Column key="color" dataIndex="color.name" title="Warna" />
                    <Table.Column key="machine_number" dataIndex="machine_number" title="No Mesin" />
                    <Table.Column key="on_stock" dataIndex="on_stock" title="On Stock" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Stock' : 'Laku'}</Tag>} />
                    {!take && <Table.Column key="id" dataIndex="id" title="aksi" render={(t, record) =>
                        <Popconfirm placement="bottomRight" title={`Yakin menghapus?`} onConfirm={onConfirm(record)} okText="Iya" cancelText="Tidak">
                            <span className="span-click">hapus</span>
                        </Popconfirm>
                    } width={80} />}
                </Table>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/stocksend')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
            <Drawer title="Pilih lokasi" visible={state.drawerPlaceVisible} width={600} onClose={() => setState({ ...state, drawerPlaceVisible: false })}>
                <Table {...tbLocation.tableProps} onRow={onRowLocation}>
                    <Table.Column key="name" dataIndex="name" title="Nama" {...tbLocation.columnProps({ key: 'name', hint: 'Cari nama' })} />
                    <Table.Column key="phone" dataIndex="phone" title="Telepon" />
                    <Table.Column key="address" dataIndex="address" title="Alamat" />

                </Table>
            </Drawer>
            <Drawer title="Pilih stock" visible={state.drawerStockVisible} width={600} onClose={() => setState({ ...state, drawerStockVisible: false })}>
                <Table {...tbStock.tableProps} onRow={onRowStock}>
                    <Table.Column key="motor" dataIndex="motor.name" title="Nama" {...tbStock.columnProps({ key: 'motor.name', hint: 'Cari motor' })} />
                    <Table.Column key="color" dataIndex="color.name" title="Warna" />
                    <Table.Column key="machine_number" dataIndex="machine_number" title="No Mesin"  {...tbStock.columnProps({ key: 'machine_number', hint: 'Cari no mesin' })} />
                    <Table.Column key="chasis_number" dataIndex="chasis_number" title="No Rangka"  {...tbStock.columnProps({ key: 'chasis_number', hint: 'Cari no rangka' })} />
                </Table>
            </Drawer>
        </Card>
    );
}

export default Form.create({ name: 'create_stocksend' })(Add);