import React from 'react';
import { Row, Col, Tabs } from 'antd';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link';
import { formatDate } from '../../component/util'
import { withRouter } from 'react-router-dom';
import { useTab } from '../../component/tab';
import Picture from '../spk/picture'

const Detail = props => {
    const col = { labelSpan: 8, valueSpan: 16 }
    const tab = useTab(props, 'tab', 'info')
    const record = props.record;

    return (
        <Tabs  {...tab}>
            <Tabs.TabPane tab="Info" key="info">
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField {...col} label="Tanggal DO">{formatDate(record.created_at)}</TextField>
                        <TextField {...col} label="No DO">{<MyLink to={`/delivery/${record.do_id}`}>{record.delivery.number}</MyLink>}</TextField>
                        <TextField {...col} label="Nama pembeli">{<MyLink to={`/spk/${record.spk.id}`}>{record.spk.buyer_name}</MyLink>}</TextField>
                        <TextField {...col} label="Marketing">{<MyLink to={`/user/${record.marketing.id}`}>{record.marketing.name}</MyLink>}</TextField>
                        <TextField {...col} label="Motor">{record.motor.name}</TextField>
                        <TextField {...col} label="Warna">{record.color.name}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="No PO">{record.spk.po_number}</TextField>
                        <TextField {...col} label="Tanggal PO">{formatDate(record.spk.po_date)}</TextField>
                        <TextField {...col} label="Leasing">{record.leasing.name}</TextField>
                        <TextField {...col} label="OTR">{record.spk.otr_price.toLocaleString('in-ID')}</TextField>
                        <TextField {...col} label="Alamat">{record.leasing_address}</TextField>
                        <TextField {...col} label="Catatan">{record.note}</TextField>
                    </Col>
                </Row>
                {props.children}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Gambar" key="picture">
                <Picture spkid={record.spk_id} />
            </Tabs.TabPane>
        </Tabs>
    );
}

export default withRouter(Detail);