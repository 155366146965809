import React from 'react'
import { Card, Empty, Upload, Icon, Divider, Row, Col, Button } from 'antd';
import Fetch from '../../Fetch';
import ImageView from '../spk/imageview'

const Picture = props => {
    const [state, setState] = React.useState({ loading: false, fileList: [], title: '', uploadLoading: false })

    const onChange = ({ fileList }) => {
        setState({ ...state, fileList })
    }

    const uploadButton = (
        <div>
            <Icon type={state.loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const onUpload = async () => {
        const formData = new FormData();
        formData.append('file', state.fileList[0].originFileObj);
        setState({ ...state, uploadLoading: true })
        Fetch.postForm(`/bpkbreceipt/image/${props.record.id}`, formData).then(r => {
            if (props.reload) props.reload();
        });
    }

    return (
        <Card bordered={false}>
            <div>
                {props.record.bpkb_receipt_images == null && <Empty />}
                <ImageView images={props.record.bpkb_receipt_images || []} />
            </div>
            <Divider />
            <div>
                <Row>
                    <Col span={12} >
                        Upload image
                        <div style={{ marginTop: 10 }} />
                        <Upload
                            beforeUpload={() => {
                                return false
                            }}
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={true}
                            onChange={onChange}
                            fileList={state.fileList}
                        >
                            {state.fileList.length > 0 ? null : uploadButton}
                        </Upload>
                        <div style={{ marginTop: 10 }} />
                        <Button loading={state.uploadLoading} icon="upload" type="primary" onClick={onUpload}
                            disabled={state.fileList.length === 0}>
                            Upload</Button>
                    </Col>
                </Row>
            </div>
        </Card >
    )
}

export default Picture;