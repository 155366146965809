import React from 'react'
import { Tabs, Card, Badge } from 'antd'
import CrmList from './list'
import Fetch from '../../Fetch'

const All = () => {
    const [summary, setSummary] = React.useState({ c7: 0, stnk: 0, bpkb: 0, c180: 0 })

    const getSummary = async () => {
        const res = await Fetch.get('/crm/summary');
        setSummary(res)
    }

    React.useEffect(() => {
        getSummary();
    }, []);

    return <Card>
        <Tabs>
            <Tabs.TabPane key="1" tab={<Badge count={summary.c7}><div style={{ width: 100 }}>7 Hari</div></Badge>}>
                <CrmList filter="filter=(show_7,,bool,,true)" statusIndex="show_7_done" />
            </Tabs.TabPane>
            <Tabs.TabPane key="2" tab={<Badge count={summary.stnk}><div style={{ width: 100 }}>STNK</div></Badge>}>
                <CrmList filter="filter=(show_stnk,,bool,,true)" statusIndex="show_stnk_done" />
            </Tabs.TabPane>
            <Tabs.TabPane key="3" tab={<Badge count={summary.bpkb}><div style={{ width: 100 }}>BPKB</div></Badge>}>
                <CrmList filter="filter=(show_bpkb,,bool,,true)" statusIndex="show_bpkb_done" />
            </Tabs.TabPane>
            <Tabs.TabPane key="4" tab={<Badge count={summary.c180}><div style={{ width: 100 }}>180 Hari</div></Badge>}>
                <CrmList filter="filter=(show_180,,bool,,true)" statusIndex="show_180_done" />
            </Tabs.TabPane>
        </Tabs>
    </Card>
}

export default All;