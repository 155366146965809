import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, InputNumber, Checkbox } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { InputText, InputTextArea } from '../../component/input';
import MySelect from '../../component/select';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/leasing/${props.match.params.id}`, values);
                        message.info('Leasing berhasil diedit');
                    } else {
                        await Fetch.post('/leasing', values);
                        message.info('Leasing berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/leasing');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/leasing/${props.match.params.id}`);
        setState({ ...state, loading: false });
        setFieldsValue({
            name: data.name,
            fullname: data.fullname,
            address: data.address,
            kwitansi1: data.kwitansi1,
            kwitansi2: data.kwitansi2,
            note: data.note,
            subsidi_leasing1: data.subsidi_leasing1, subsidi_leasing2: data.subsidi_leasing2,
            insentive_leasing: data.insentive_leasing, leasing_group_id: data.leasing_group_id === 0 ? undefined : data.leasing_group_id,
            insentive_leasing_pph: data.insentive_leasing_pph,
            subsidi_leasing1_pph: data.subsidi_leasing1_pph, subsidi_leasing2_pph: data.subsidi_leasing2_pph
        });
    }

    return (
        <Card title={<Title title={edit ? "Edit leasing" : "Tambah leasing"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_leasing" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama leasing">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama leasing" />
                            )}
                        </Form.Item>
                        <Form.Item label="Nama lengkap">
                            {getFieldDecorator('fullname', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama lengkap" />
                            )}
                        </Form.Item>
                        <Form.Item label="Group">
                            {getFieldDecorator('leasing_group_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih group leasing" url="/leasinggroup?order=name asc" valueIndex="id" dataIndex="name" />
                            )}
                        </Form.Item>
                        <Form.Item label="Alamat">
                            {getFieldDecorator('address')(
                                <InputTextArea autosize placeholder="Masukkan alamat" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Kwitansi 1">
                            {getFieldDecorator('kwitansi1')(
                                <InputTextArea autosize placeholder="Masukkan kwitansi1" />
                            )}
                        </Form.Item>
                        <Form.Item label="Kwitansi 2">
                            {getFieldDecorator('kwitansi2')(
                                <InputTextArea autosize placeholder="Masukkan kwitansi2" />
                            )}
                        </Form.Item>
                        <Form.Item label="Catatan">
                            {getFieldDecorator('note')(
                                <InputTextArea autosize placeholder="Masukkan catatan" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Subsidi 1">
                            {getFieldDecorator('subsidi_leasing1')(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan subsidi 1" />
                            )}
                        </Form.Item>
                        <Form.Item label="Pph Subsidi 1">
                            {getFieldDecorator('subsidi_leasing1_pph', { valuePropName: 'checked' })(
                                <Checkbox>include PPh</Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item label="Subsidi 2">
                            {getFieldDecorator('subsidi_leasing2')(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan subsidi 2" />
                            )}
                        </Form.Item>
                        <Form.Item label="Pph Subsidi 2">
                            {getFieldDecorator('subsidi_leasing2_pph', { valuePropName: 'checked' })(
                                <Checkbox>include PPh</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Insentif">
                            {getFieldDecorator('insentive_leasing')(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan insentif 1" />
                            )}
                        </Form.Item>
                        <Form.Item label="Pph Insentif">
                            {getFieldDecorator('insentive_leasing_pph', { valuePropName: 'checked' })(
                                <Checkbox>include PPh</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/leasing')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_leasing' })(Add);