import React from 'react'
import { Card, Empty, Upload, Icon, Divider, Row, Col, Button, Radio } from 'antd';
import Fetch from '../../Fetch';
import ImageView from './imageview'
import { InputText } from '../../component/input';

const Picture = props => {
    const [state, setState] = React.useState({ loading: true, fileList: [], title: '', uploadLoading: false })
    const [typ, setTyp] = React.useState(props.type ? props.type : 'spk')

    React.useEffect(() => {
        if (typ === 'all') {
            Fetch.get(`/spkimage?filter=(spk_id,,eq,,${props.spkid})`).then(res => {
                setState({ ...state, loading: false, images: res.data })
            })
        } else {
            Fetch.get(`/spkimage?filter=(spk_id,,eq,,${props.spkid})(type,,eq,,${typ})`).then(res => {
                setState({ ...state, loading: false, images: res.data })
            })
        }
    }, [props.spkid, typ])

    const onChange = ({ fileList }) => {
        setState({ ...state, fileList })
    }

    const uploadButton = (
        <div>
            <Icon type={state.loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const onUpload = async () => {
        const formData = new FormData();
        formData.append('file', state.fileList[0].originFileObj);
        formData.append('title', state.title);
        formData.append('type', typ);
        setState({ ...state, uploadLoading: true })
        Fetch.postForm(`/spk/uploadimage/${props.spkid}`, formData).then(r => {
            Fetch.get(`/spkimage?filter=(spk_id,,eq,,${props.spkid})`).then(res => {
                setState({ ...state, loading: false, images: res.data, uploadLoading: false, fileList: [], title: '' })
            })
        });
    }

    const handleType = (value) => {
        setTyp(value.target.value);
    }

    return (
        <Card loading={state.loading} bordered={false}>
            {!state.loading &&
                <div>
                    <Radio.Group value={typ} onChange={handleType}>
                        <Radio.Button value="all">Semua</Radio.Button>
                        <Radio.Button value="spk">Spk</Radio.Button>
                        <Radio.Button value="stnk">Stnk</Radio.Button>
                        <Radio.Button value="bpkb">Bpkb</Radio.Button>
                        <Radio.Button value="delivery">DO</Radio.Button>
                        <Radio.Button value="ktp">KTP</Radio.Button>
                        <Radio.Button value="kk">KK</Radio.Button>
                        <Radio.Button value="po">PO</Radio.Button>
                        <Radio.Button value="other">Lain-lain</Radio.Button>
                    </Radio.Group>
                    <div style={{ marginTop: 16 }} />
                    {state.images.length === 0 && <Empty />}
                    {state.images.length > 0 && <ImageView images={state.images} />}
                </div>
            }
            <Divider />
            <div>
                <Row>
                    <Col span={12} >
                        Upload image
                        <div style={{ marginTop: 10 }} />
                        <Upload
                            beforeUpload={() => {
                                return false
                            }}
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={true}
                            onChange={onChange}
                            fileList={state.fileList}
                        >
                            {state.fileList.length > 0 ? null : uploadButton}
                        </Upload>
                        <div style={{ marginTop: 10 }} />
                        <InputText placeholder="Masukkan judul gambar" value={state.title} onChange={(e) => setState({ ...state, title: e })} />
                        <div style={{ marginTop: 10 }} />
                        <Button loading={state.uploadLoading} icon="upload" type="primary" onClick={onUpload}
                            disabled={state.fileList.length === 0 || state.title === ''}>
                            Upload</Button>
                    </Col>
                </Row>
            </div>
        </Card >
    )
}

export default Picture;
