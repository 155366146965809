import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import { formatDate } from '../../component/util'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/stocksent/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/stocksent/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [, tb] = useTable('/stocksent?order=created_at desc', {})

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/stocksent/${id}`);
                message.info("Berhasil menghapus stocksent");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus stocksent: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/stocksent/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/stocksent/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/stocksent/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <a href="void()"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</a>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar kirim stock"} actions={<Actions  {...props} refresh={tb.fetchData} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'stock.created_at', type: "startend" })} {...tb.sortProps('created_at')} />
                <Table.Column key="number" dataIndex="number" title="Nomor" {...tb.columnProps({ key: 'number', hint: 'Cari nomor pengiriman' })} />
                <Table.Column key="from.name" dataIndex="from.name" title="Asal" {...tb.columnProps({ key: 'from.name', hint: 'Cari asal' })} />
                <Table.Column key="to.name" dataIndex="to.name" title="Tujuan" {...tb.columnProps({ key: 'to.name', hint: 'Cari tujuan' })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;