import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Typography, Row, Col, Divider, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { TextField } from '../../component/field'
import {
    paymentType, paymentTypeToString, paymentTypeToColor,
    paymentOnlineTypeToString, paymentOnlineTypeToColor, paymentOnlineType,
    paymentStatus, paymentStatusToColor, paymentStatusToString,
} from '../../constant'
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch'
import Permission, { P_MONEY_CLOSING } from '../../permission'

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="check" onClick={props.closingClicked}>Buat closing</Button>
            <Button icon="reload" onClick={props.refresh} />
        </Button.Group>
    );
}

const List = props => {
    const [, tb] = useTable('/spkpayment', { filter: { 'money_close_id': 0, 'payment_position_status': 'done' }, order: { key: 'created_at', order: 'descend' } })
    const [state, setState] = React.useState({ data: [], loading: true, create: false, selectedRowKeys: [], saveLoading: false, refresh: true })
    const ref = React.useRef({})

    React.useEffect(() => {
        if (state.refresh)
            Fetch.get(`/spkpayment/summaryready`).then(v => {
                setState({ ...state, refresh: false, loading: false, data: v.data.map(v => { return { ...v, total_selected: 0 } }) })
            });
    }, [state.refresh])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/spk/${record.spk_id}?tab=payment`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/spk/${record.spk_id}?tab=payment`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
        </Menu>
    )

    const closingClicked = () => {
        setState({ ...state, create: true })
    }

    const rowSelection = {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            let old = state.data.map(v => { return { ...v, total_selected: 0 } })
            let total = 0;
            for (let i = 0; i < selectedRows.length; i++) {
                const val = selectedRows[i];
                if (!ref.current.hasOwnProperty(selectedRows[i].id)) {
                    ref.current[selectedRows[i].id] = val;
                }
            }
            for (let i = 0; i < selectedRowKeys.length; i++) {
                if (ref.current.hasOwnProperty(selectedRowKeys[i])) {
                    const val = ref.current[selectedRowKeys[i]];
                    const bank_id = val.payment_type === 'offline' ? val.bank_id : 1000000;
                    const index = old.findIndex(v => v.bank_id === bank_id)
                    old[index].total_selected += val.value;
                    total += val.value;
                }
            }
            old[old.length - 1].total_selected = total
            setState({ ...state, selectedRowKeys: selectedRowKeys, data: old })
        },
    };

    const processClosing = async () => {
        const total = state.data[state.data.length - 1].total_selected;
        if (total === 0) {
            message.error("Total tidak boleh 0")
            return
        }
        setState({ ...state, saveLoading: true })
        try {
            await Fetch.post('/moneyclose', { total: total, monies: state.selectedRowKeys })
            message.info("Berhasil membuat closing")
            tb.fetchData()
            setState({ ...state, refresh: true, create: false, selectedRowKeys: [] })
        } catch (e) {
            message.error(e)
            setState({ ...state, saveLoading: false })
        }
    }

    return <React.Fragment>
        {
            Permission.hasPermission(P_MONEY_CLOSING) &&
            <Card title={<Title title={"Daftar Uang Di Kasir belum closing"} actions={<Actions  {...props} refresh={tb.fetchData} closingClicked={closingClicked} />} />}>
                <Typography.Text strong>Summary</Typography.Text><br /><br />
                <Row gutter={32}>
                    <Col md={14}>
                        <Table loading={state.loading} dataSource={state.data} pagination={false} rowKey="bank_id">
                            <Table.Column key="name" dataIndex="name" title="Nama" render={r => r === 'Total' ? <b>Total</b> : r} />
                            <Table.Column key="total" dataIndex="total" title="Total" align="right" render={formatMoney} />
                            {state.create &&
                                <Table.Column key="total_selected" dataIndex="total_selected" title="Total terpilih" align="right" render={formatMoney} />
                            }
                        </Table>
                    </Col>
                    <Col md={10}>
                        {state.create &&
                            <Card title="Buat closing baru">
                                <TextField label="Total terpilih" labelSpan={10} valueSpan={14}>{formatMoney(state.data[state.data.length - 1].total_selected)}</TextField>
                                <Divider />
                                <Button type="primary" onClick={processClosing} loading={state.saveLoading}>Proses closing</Button>
                            </Card>
                        }
                    </Col>
                </Row>
                <br />
                <Divider />
                <Typography.Text strong>List pembayaran</Typography.Text><br /><br />
                <Table {...tb.tableProps} onRow={onRow} rowSelection={state.create ? rowSelection : undefined}>
                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                        {...tb.columnProps({ key: 'spk_payment.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                    <Table.Column key="number" dataIndex="number" title="Nomor" />
                    <Table.Column key="spknumber" dataIndex="spk.number" title="No SPK"  {...tb.columnProps({ key: 'name', hint: 'Cari no SPK' })}
                        render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>} />
                    <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama SPK"  {...tb.columnProps({ key: 'name', hint: 'Cari nama SPK' })}
                        {...tb.sortProps('spk.buyer_name')} />
                    <Table.Column key="tipe" dataIndex="type" title="Tipe"
                        render={t => <Tag color={paymentTypeToColor(t)}>{paymentTypeToString(t)}</Tag>}
                        {...tb.columnProps({ key: 'spk_payment.type', type: "radio", options: paymentType })} />
                    <Table.Column key="payment_type" dataIndex="payment_type" title="Tipe" render={t => <Tag color={paymentOnlineTypeToColor(t)}>{paymentOnlineTypeToString(t)}</Tag>}
                        {...tb.columnProps({ key: 'spk_payment.payment_type', type: "radio", options: paymentOnlineType })} />
                    <Table.Column key="payment_status" dataIndex="payment_status" title="Status" render={t => <Tag color={paymentStatusToColor(t)}>{paymentStatusToString(t)}</Tag>}
                        {...tb.columnProps({ key: 'spk_payment.payment_status', type: "radio", options: paymentStatus })} />
                    <Table.Column key="value" dataIndex="value" title="Jumlah" align="right" render={formatMoney} />
                    <Table.Column key="bank" dataIndex="bank.name" title="Bank" render={(t, r) => r.payment_type === 'xendit' ? '-' : t === undefined ? 'Cash' : t} />
                    <Table.Column key="card_number" dataIndex="card_number" title="No transaksi" />
                    <Table.Column key="chasis" dataIndex="stock.chasis_number" title="No rangka"  {...tb.columnProps({ key: 'stock.chasis_number', hint: 'Cari no rangka' })} />
                    <Table.Column key="machine" dataIndex="stock.machine_number" title="No mesin"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                    <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                        return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                    }} fixed="right" />
                </Table>
            </Card >
        }
        {
            !Permission.hasPermission(P_MONEY_CLOSING) && <Typography.Title>Anda tidak memiliki akses</Typography.Title>
        }
    </React.Fragment >

}

export default List;