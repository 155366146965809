import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Button } from 'antd';
import Title from '../../component/title';
import { withRouter } from 'react-router-dom';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field'
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch';
import { LabelHeader } from '../../component/header';
import { MyLink, push } from '../../component/link';

const Act = props => {
    return (
        <Button.Group>
            <Button icon="edit" href={`/${props.url}/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/${props.url}/${props.dataId}/edit`);
            }}>Edit</Button>
            <Button icon="printer" href={Fetch.getUrl(`/subsidiwmsinvoiceprint/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)}
                target="_blank">
                Print</Button>
            <Button icon="reload" onClick={props.reload} />
        </Button.Group>
    );
}

const Action = withRouter(Act);

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/subsidiwmsinvoice/${props.match.params.id}`);
    const [state, setState] = React.useState({ data: [], loading: true })
    const col = { labelSpan: 8, valueSpan: 16 }

    React.useEffect(() => {
        if (record.id)
            Fetch.get(`/claim?filter=(subsidi_wms_invoice_id,,eq,,${record.id})&order=created_at DESC&limit=1000`).then(v => {
                setState({ ...state, loading: false, data: v.data });
            })
    }, [record])

    return (
        <Card loading={loading} title={<Title title={"Detail tagihan WMS"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Tagihan" url="subsidiwmsinvoice" />} />} >
            {!loading &&
                <Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Tanggal">{formatDate(record.date)}</TextField>
                            <TextField {...col} label="Nomor">{record.number}</TextField>
                            <TextField {...col} label="Catatan">{record.note || '-'}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Pembuat">{record.user.name}</TextField>
                            <TextField {...col} label="Total invoice">{formatMoney(record.total_invoice)}</TextField>
                        </Col>
                    </Row>
                    <LabelHeader>Subsidi WMS</LabelHeader>
                    <Table loading={state.loading} dataSource={state.data} rowKey="id" pagination={false} scroll={{ x: true }}>
                        <Table.Column key="faktur_date" dataIndex="faktur_date" title="Tanggal Faktur" render={formatDate} />
                        <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"
                            render={(t, r) => <MyLink to={`/stock/${r.stock_id}`}>{t}</MyLink>} />
                        <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama pembeli"
                            render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} />
                        <Table.Column key="marketing" dataIndex="marketing.name" title="Nama marketing" render={(t, r) => <MyLink to={`/user/${r.marketing.id}`}>{t}</MyLink>} />
                        <Table.Column key="subsidi_wms" dataIndex="subsidi_wms" title="Subsidi"
                            render={(t, r) => <MyLink to={`/subsidiwms/${r.id}`}>{formatMoney(t)}</MyLink>} />
                        <Table.Column key="motor" dataIndex="motor.name" title="Motor" />
                        <Table.Column key="warna" dataIndex="color.name" title="Warna" />

                    </Table>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;