import React from 'react';
import { Card, Table, Button, Typography } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { formatDate, formatMoney } from '../../component/util'
import Permission, { P_MONEY_CLOSING } from '../../permission'

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="reload" onClick={props.refresh} />
        </Button.Group>
    );
}

const List = props => {
    const [, tb] = useTable('/moneyclose', { order: { key: 'created_at', order: 'descend' } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/moneyclose/${record.id}`, '_blank')
            }
        }
    }

    return <React.Fragment>
        {
            Permission.hasPermission(P_MONEY_CLOSING) &&
            <Card title={<Title title={"Daftar closingan"} actions={<Actions  {...props} refresh={tb.fetchData} />} />}>
                <Table {...tb.tableProps} onRow={onRow}>
                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                        {...tb.columnProps({ key: 'moneyclose.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                    <Table.Column key="number" dataIndex="number" title="Nomor" />
                    <Table.Column key="user.name" dataIndex="user.name" title="User" />
                    <Table.Column key="total" dataIndex="total" title="Total" align="right" render={formatMoney} width={150} />
                </Table>
            </Card >
        }
        {
            !Permission.hasPermission(P_MONEY_CLOSING) && <Typography.Title>Anda tidak memiliki akses</Typography.Title>
        }
    </React.Fragment>
}

export default List;