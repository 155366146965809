import React from 'react'
import useTable from '../../component/table';
import { Table } from 'antd';
import { formatDate } from '../../component/util'
import Fetch from '../../Fetch'

const WigetBpkbPosition = (props) => {
    const [, tb] = useTable('/bpkbposition', { order: { key: 'created_at', order: 'descend' }, filter: { 'bpkb_id': props.bpkbId } })
    return <div>
        <Table {...tb.tableProps}>
            <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate} width={200} />
            <Table.Column key="user" dataIndex="user.name" title="Nama user" />
            <Table.Column key="sign" dataIndex="sign" title="Tanda tangan" width={100}
                render={r => <a href={Fetch.getUrl('/public/stnks/' + r)} target="_blank" rel="noopener noreferrer">TTD</a>} />
        </Table>
    </div>
}

export default WigetBpkbPosition;