import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Statistic, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { deliveryStatus, deliveryStatusToString, deliveryStatusToColor } from '../../constant'
import { formatDate, getDefaultCurMonth, filterDate } from '../../component/util'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [summary, setSummary] = React.useState({ total: 0, confirmed: 0, not_confirmed: 0, canceled: 0 })
    const [{ filter, order }, tb] = useTable('/delivery', { filter: { 'delivery.created_at': getDefaultCurMonth() }, order: { key: 'created_at', order: 'descend' } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/delivery/${record.id}`, '_blank')
            }
        }
    }

    React.useEffect(() => {
        Fetch.get(tb.genFilterUrl('/delivery/summary', filter, order)).then(v => {
            setSummary(v)
        })
    }, [filter])

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/delivery/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar DO"} actions={<Actions  {...props} refresh={() => tb.fetchData()}
            exportClick={() => {
                let err = filterDate(filter, 'delivery.created_at')
                if (err !== '') {
                    message.error(err)
                    return;
                }
                const url = tb.genFilterUrl(`/delivery/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
                window.open(Fetch.getUrl(url))
            }} />} />}>
            <div style={{ display: 'flex' }}>
                <Statistic title="Total DO" value={summary.total} decimalSeparator="," groupSeparator="." />
                <Statistic title="Belum Terkonfirm" value={summary.not_confirmed} decimalSeparator="," groupSeparator="." style={{ marginLeft: 50 }} />
                <Statistic title="Terkonfirmasi" value={summary.confirmed} decimalSeparator="," groupSeparator="." style={{ marginLeft: 50 }} />
                <Statistic title="Batal" value={summary.canceled} decimalSeparator="," groupSeparator="." style={{ marginLeft: 50 }} />
            </div>
            <br />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'delivery.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                <Table.Column key="number" dataIndex="number" title="Nomor" {...tb.columnProps({ key: 'number', hint: 'Cari nomor' })} />
                <Table.Column key="spk.buyer_name" dataIndex="spk.buyer_name" title="Nama Pembeli"  {...tb.columnProps({ key: 'spk.buyer_name', hint: 'Cari nama pembeli' })}
                    {...tb.sortProps('spk.buyer_name')} />
                <Table.Column key="spk.stnk_name" dataIndex="spk.stnk_name" title="Nama STNK"  {...tb.columnProps({ key: 'spk.stnk_name', hint: 'Cari nama STNK' })}
                    {...tb.sortProps('spk.stnk_name')} />
                <Table.Column key="spk.buyer_phone" dataIndex="spk.buyer_phone" title="Telepon"  {...tb.columnProps({ key: 'spk.buyer_phone', hint: 'Cari telepon' })} />
                <Table.Column key="spk.buyer_whatsapp" dataIndex="spk.buyer_whatsapp" title="Whatsapp"  {...tb.columnProps({ key: 'spk.buyer_whatsapp', hint: 'Cari whatsapp' })} />
                <Table.Column key="motor.name" dataIndex="motor.name" title="Motor" {...tb.sortProps('motor.name')} />
                <Table.Column key="color.name" dataIndex="color.name" title="Warna" />
                <Table.Column key="stock.chasis_number" dataIndex="stock.chasis_number" title="No rangka"  {...tb.columnProps({ key: 'stock.chasis_number', hint: 'Cari no rangka' })} />
                <Table.Column key="stock.machine_number" dataIndex="stock.machine_number" title="No mesin"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                <Table.Column key="leasing" dataIndex="leasing" title="Leasing/Cash" render={t => t.name ? t.name : 'CASH'} {...tb.sortProps('leasing.name')} />
                <Table.Column key="user" dataIndex="marketing" title="Marketing" render={t => t == null ? '-' : <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>}
                    {...tb.columnProps({ key: 'marketing_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="marko" dataIndex="marko" title="Marko" render={t => t === null ? '-' : <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>} />
                <Table.Column key="status" dataIndex="status" title="Status"
                    render={t => <Tag color={deliveryStatusToColor(t)}>{deliveryStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'status', type: 'radio', options: deliveryStatus })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;