import React from 'react'
import {
    LineChart, Line, XAxis, YAxis
} from 'recharts';

const Chart = props => {

    return <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <LineChart width={400} height={300} data={props.data}>
            <XAxis dataKey="label" />
            <YAxis />
            <Line type="monotone" dataKey="value" stroke="#8884d8" />
        </LineChart>
    </div>
}

export default Chart;