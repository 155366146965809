import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Drawer, message, Tag, Form, Divider, Input } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import { userTypeToString, userTypeToColor } from '../../constant'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/user/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/user/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [state, setState] = React.useState({ drawerVisible: false, saveLoading: false })
    const [, tb] = useTable('/user', { order: { key: 'username', order: 'ascend' } })
    const { getFieldDecorator, resetFields } = props.form;

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/user/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/user/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><span onClick={() => {
                setState({ ...state, drawerVisible: true, record: record })
                resetFields();
            }}><Icon type="unlock" style={{ marginRight: 8 }} />Reset password</span></Menu.Item>
            <Menu.Item><MyLink to={`/user/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu >
    )

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.put(`/user/${state.record.id}`, values);
                    message.info('Password berhasil direset');
                    resetFields();
                    setState({ ...state, saveLoading: false, drawerVisible: false })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    return (
        <Card title={<Title title={"Daftar User"} actions={<Actions  {...props} refresh={tb.fetchData} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="username" dataIndex="username" title="Username" {...tb.columnProps({ key: 'username', hint: 'Cari username' })} {...tb.sortProps('username')} />
                <Table.Column key="name" dataIndex="name" title="Nama"  {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} {...tb.sortProps('name')} />
                <Table.Column key="email" dataIndex="email" title="Email"  {...tb.columnProps({ key: 'email', hint: 'Cari email' })} />
                <Table.Column key="phone" dataIndex="phone" title="Telepon"  {...tb.columnProps({ key: 'phone', hint: 'Cari telepon' })} />
                <Table.Column key="type" dataIndex="type" title="Tipe" render={t => <Tag color={userTypeToColor(t)}>{userTypeToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'type', type: 'radio', options: [{ value: 'normal', text: 'Normal' }, { value: 'surveyor', text: 'Surveyor' }] })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
            <Drawer visible={state.drawerVisible} title="Reset password" width={400}>
                <Form name="reset_password" onSubmit={formSummited}>
                    <Form.Item label="Password baru">
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Harus diisi' }]
                        })(
                            <Input.Password placeholder="Masukkan password baru" />
                        )}
                    </Form.Item>
                    <Divider />
                    <Form.Item>
                        <Button style={{ marginRight: 10 }} onClick={() => setState({ ...state, drawerVisible: false })} >Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={state.saveLoading}>Simpan</Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card >
    )
}

export default Form.create({ name: 'reset_password' })(List);