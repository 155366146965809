import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, InputNumber, Checkbox, DatePicker } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop, MyLink } from '../../component/link';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import { LabelHeader } from '../../component/header';
import moment from 'moment';
import { InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false });
    const col = { labelSpan: 8, valueSpan: 16 }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        if (state.record.intensive_leasing_date === null && values.unit_date !== null)
                            await Fetch.put(`/claim/${props.match.params.id}`, { ...values, intensive_leasing_date: values.unit_date });
                        else
                            await Fetch.put(`/claim/${props.match.params.id}`, values);
                        message.info('Tagihan berhasil diedit');
                    } else {
                        await Fetch.post('/claim', values);
                        message.info('Tagihan berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/claim');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/claim/${props.match.params.id}`);
        setState({ ...state, loading: false, record: data });
        setFieldsValue({
            dp_po: data.dp_po, dp_po_done: data.dp_po_done,
            dp: data.dp, dp_done: data.dp_done,
            unit: data.unit, unit_done: data.unit_done,
            subsidi_leasing1: data.subsidi_leasing1, subsidi_leasing1_done: data.subsidi_leasing1_done,
            subsidi_leasing2: data.subsidi_leasing2, subsidi_leasing2_done: data.subsidi_leasing2_done,
            subsidi_ahm: data.subsidi_ahm, subsidi_ahm_done: data.subsidi_ahm_done,
            subsidi_wms: data.subsidi_wms, subsidi_wms_done: data.subsidi_wms_done,
            intensive_leasing: data.intensive_leasing, intensive_leasing_done: data.intensive_leasing_done,
            intensive_leasing2: data.intensive_leasing2, intensive_leasing2_done: data.intensive_leasing2_done,
            intensive_leasing3: data.intensive_leasing3, intensive_leasing3_done: data.intensive_leasing3_done,
            unit_date: data.unit_date ? moment(data.unit_date) : undefined,
            note: data.note, leasing_address: data.leasing_address,
            intensive_leasing_pph: data.intensive_leasing_pph,
            subsidi_leasing1_pph: data.subsidi_leasing1_pph, subsidi_leasing2_pph: data.subsidi_leasing2_pph
        });
    }
    const { record } = state;

    return (
        <Card title={<Title title={edit ? "Edit tagihan" : "Tambah tagihan"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_claim" onSubmit={formSummited} {...formItemLayout}>
                <LabelHeader useTopMargin={false}>Info DO & SPK</LabelHeader>
                <Row gutter={32}>
                    <Col span={12}>
                        <TextField {...col} label="Tanggal DO">{record ? formatDate(record.created_at) : ''}</TextField>
                        <TextField {...col} label="No DO">{record ? <MyLink to={`/delivery/${record.do_id}`}>{record.delivery.number}</MyLink> : ''}</TextField>
                        <TextField {...col} label="Nama pembeli">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.buyer_name}</MyLink> : ''}</TextField>
                        <TextField {...col} label="Marketing">{record ? record.marketing ? <MyLink to={`/spk/${record.marketing.id}`}>{record.marketing.name}</MyLink> : '-' : ''}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="Motor">{record ? record.motor.name : ''}</TextField>
                        <TextField {...col} label="Warna">{record ? record.color.name : ''}</TextField>
                        <TextField {...col} label="Leasing">{record && record.leasing ? record.leasing.name : ''}</TextField>
                        <TextField {...col} label="OTR">{record ? record.spk.otr_price.toLocaleString('in-ID') : ''}</TextField>
                    </Col>
                </Row>
                <LabelHeader>Tagihan</LabelHeader>
                <Row gutter={32}>
                    <Col span={12}>
                        <Row gutter={8}>
                            <Col span={19}>
                                <Form.Item label="DP PO">
                                    {getFieldDecorator('dp_po', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                            parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={19}>
                                <Form.Item label="DP">
                                    {getFieldDecorator('dp', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                            parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={19}>
                                <Form.Item label="Unit">
                                    {getFieldDecorator('unit', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                            parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                {record && <Form.Item labelCol={{ sm: 0 }} wrapperCol={{ sm: 24 }}>
                                    {getFieldDecorator('unit_done', { valuePropName: 'checked' })(
                                        <Checkbox disabled={/*record.spk.po_number === ''*/false}>Lunas</Checkbox>
                                    )}
                                </Form.Item>}
                            </Col>
                        </Row>
                        <Form.Item label="Tanggal Unit" labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
                            {getFieldDecorator('unit_date')(
                                <DatePicker />
                            )}
                        </Form.Item>
                        <Row gutter={8}>
                            <Col span={19}>
                                <Form.Item label="Subsidi Leasing 1">
                                    {getFieldDecorator('subsidi_leasing1', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                            parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                {record && <Form.Item labelCol={{ sm: 0 }} wrapperCol={{ sm: 24 }}>
                                    {getFieldDecorator('subsidi_leasing1_done', { valuePropName: 'checked' })(
                                        <Checkbox disabled={/*record.spk.po_number === ''*/false}>Lunas</Checkbox>
                                    )}
                                </Form.Item>}
                            </Col>
                        </Row>
                        <Form.Item label="PPH?" labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
                            {getFieldDecorator('subsidi_leasing1_pph', { valuePropName: 'checked' })(
                                <Checkbox>Include PPH</Checkbox>
                            )}
                        </Form.Item>
                        <Row gutter={8}>
                            <Col span={19}>
                                <Form.Item label="Subsidi Leasing 2">
                                    {getFieldDecorator('subsidi_leasing2', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                            parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                {record && <Form.Item labelCol={{ sm: 0 }} wrapperCol={{ sm: 24 }}>
                                    {getFieldDecorator('subsidi_leasing2_done', { valuePropName: 'checked' })(
                                        <Checkbox disabled={/*record.spk.po_number === ''*/false}>Lunas</Checkbox>
                                    )}
                                </Form.Item>}
                            </Col>
                        </Row>
                        <Form.Item label="PPH?" labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
                            {getFieldDecorator('subsidi_leasing2_pph', { valuePropName: 'checked' })(
                                <Checkbox>Include PPH</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Row gutter={8}>
                            <Col span={19}>
                                <Form.Item label="Insentive leasing">
                                    {getFieldDecorator('intensive_leasing', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                            parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                            </Col>
                        </Row>
                        <Form.Item label="PPH?" labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
                            {getFieldDecorator('intensive_leasing_pph', { valuePropName: 'checked' })(
                                <Checkbox>Include PPH</Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item label="Address" labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
                            {getFieldDecorator('leasing_address')(
                                <InputTextArea autosize placeholder="Masukkan alamat leasing" />
                            )}
                        </Form.Item>
                        <Form.Item label="Catatan" labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }}>
                            {getFieldDecorator('note')(
                                <InputTextArea autosize placeholder="Masukkan catatan" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/claim')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card >
    );
}

export default Form.create({ name: 'create_claim' })(Add);
