import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Menu, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch';
import { push } from '../../component/link';

const Actions = props => {

    const menu = (
        <Menu>
            <Menu.Item>
                <a href={Fetch.getUrl(`/claimprint/dppo/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" rel="noopener noreferrer">DP PO</a>
            </Menu.Item>
            <Menu.Item>
                <a href={Fetch.getUrl(`/claimprint/dp/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" rel="noopener noreferrer">DP</a>
            </Menu.Item>
            <Menu.Item>
                <a href={Fetch.getUrl(`/claimprint/unit/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" rel="noopener noreferrer">Pelunasan unit</a>
            </Menu.Item>
            <Menu.Item>
                <a href={Fetch.getUrl(`/claimprint/tandajadi1/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" rel="noopener noreferrer">Tanda Jadi 1</a>
            </Menu.Item>
            <Menu.Item>
                <a href={Fetch.getUrl(`/claimprint/tandajadi2/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" rel="noopener noreferrer">Tanda Jadi 2</a>
            </Menu.Item>
            <Menu.Item>
                <a href={Fetch.getUrl(`/claimprint/intensiveleasing/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" rel="noopener noreferrer">Intensive Leasing</a>
            </Menu.Item>
            <Menu.Item>
                <a href={Fetch.getUrl(`/claimprint/bpkb/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" rel="noopener noreferrer">Pernyataan BPKB</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Button.Group>
            <Button icon="edit" href={`/${props.url}/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/${props.url}/${props.dataId}/edit`);
            }}>Edit</Button>
            <Button icon="check" onClick={props.onPoClicked}>PO</Button>
            <Dropdown overlay={menu} placement="bottomLeft">
                <Button icon="printer">print</Button>
            </Dropdown>
            <Button icon="reload" onClick={props.reload} />
        </Button.Group>
    );
}

Actions.propTypes = {
    dataId: PropTypes.number.isRequired,
    reload: PropTypes.func,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    showPrint: PropTypes.bool,
}

export default withRouter(Actions);