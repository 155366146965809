import React from 'react';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Detail = props => {
    const col = { labelSpan: 8, valueSpan: 16 }

    const getType = (t) => {
        switch (t) {
            case 'va': return 'Virtual Account';
            case 'cc': return 'Kartu kredit';
            case 'ovo': return 'OVO';
            default: return 'Unknown'
        }
    }

    return (
        <div>
            {props.record &&
                <div>
                    <TextField label="Tipe" {...col}>{getType(props.record.method)}</TextField>
                    <TextField label="Url pembayaran" {...col}><div>{props.record.xendit_url}</div>
                        <CopyToClipboard text={props.record.xendit_url}>
                            <Button style={{ marginTop: 10 }} >Copy</Button>
                        </CopyToClipboard>
                    </TextField>
                    <TextField label="Expired" {...col}>{formatDate(props.record.expired, false)}</TextField>
                </div>
            }
        </div >
    );
}
//https://wa.me/${getPhone(state)}?text=${props.record.xendit_url}
export default Detail;