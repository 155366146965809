import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { MyLink } from '../component/link';
import { GlobalStore } from '../store/store'
import packageJson from '../../package.json'
import Permission, {
    P_USER_READ, P_TEAM_READ,
    P_MOTOR_READ, P_BANK_READ,
    P_PAYMENT_ADMIN, P_LEASING_READ,
    P_STOK_LOCATION_READ, P_MASTER_PRICE_READ, P_STOCK_WRITE, P_STOCK_READ,
    P_STOCK_VALUE, P_SPK_SOURCE_READ, P_SPK_READ,
    P_SPK_ADMIN, P_DELIVERY_READ,
    P_CLAIM_READ, P_SALE_READ,
    P_STNK_READ, P_BPKB_READ, P_DASHBAORD, P_CUSTOMER, P_CRM, P_SPK_DO,
    P_STAMP
} from '../permission'

const MySider = (props) => {
    const [{ global }] = React.useContext(GlobalStore);

    const menus = [
        { label: 'Dashboard', key: '/dashboard', path: '/dashboard', icon: 'bar-chart', permissions: [P_DASHBAORD] },
        { label: 'Performance', key: '/performance', path: '/performance', icon: 'rise', permissions: [] },
        {
            label: 'User & Team', key: '/ut', path: '/ut', icon: 'user', children: [
                { label: 'User', key: '/user', path: '/user', icon: 'user', permissions: [P_USER_READ] },
                { label: 'Team', key: '/team', path: '/team', icon: 'team', permissions: [P_TEAM_READ] },
            ], permissions: [P_USER_READ, P_TEAM_READ]
        },
        {
            label: 'Stock', key: '/st', path: '/st', icon: 'scan', children: [
                { label: 'Master display', key: '/stocklocation', path: '/stocklocation', icon: 'desktop', permissions: [P_STOK_LOCATION_READ] },
                { label: 'Stock', key: '/stock', path: '/stock', icon: 'audit', permissions: [P_STOCK_READ] },
                { label: 'Kirim Stock', key: '/stocksent', path: '/stocksent', icon: 'select', permissions: [P_STOCK_WRITE] },
            ], permissions: [P_STOK_LOCATION_READ, P_STOCK_READ, P_STOCK_WRITE]
        },
        {
            label: 'Marketing', key: '/mark', path: '/mark', icon: 'solution', children: [
                { label: 'Prospek', key: '/prospek', path: '/prospek', icon: 'user', permissions: [P_SPK_READ] },
                { label: 'SPK', key: '/spk', path: '/spk', icon: 'audit', permissions: [P_SPK_READ] },
                { label: 'Daftar Indent', key: '/indent', path: '/indent', icon: 'coffee', permissions: [P_SPK_DO] },
            ], permissions: [P_SPK_READ, P_SPK_ADMIN, P_SPK_DO]
        },
        { label: 'Delivery Order', key: '/delivery', path: '/delivery', icon: 'car', permissions: [P_DELIVERY_READ] },
        {
            label: 'Tagihan', key: '/tag', path: '/tag', icon: 'solution', children: [
                { label: 'Tagihan', key: '/claim', path: '/claim', icon: 'profile', permissions: [P_CLAIM_READ] },
                { label: 'Tanda Terima Tagihan', key: '/claimreceipt', path: '/claimreceipt', icon: 'audit', permissions: [P_CLAIM_READ] },
                { label: 'Tagihan Outstanding', key: '/claimoutstanding', path: '/claimoutstanding', icon: 'profile', permissions: [P_CLAIM_READ] },
                { label: 'Daftar PO', key: '/po', path: '/po', icon: 'schedule', permissions: [P_SPK_ADMIN] },
                { label: 'STNK', key: '/stnk', path: '/stnk', icon: 'idcard', permissions: [P_STNK_READ] },
                { label: 'BPKB', key: '/bpkb', path: '/bpkb', icon: 'read', permissions: [P_BPKB_READ] },
                { label: 'Tanda Terima BPKB', key: '/bpkbreceipt', path: '/bpkbreceipt', icon: 'reconciliation', permissions: [P_BPKB_READ] },
                { label: 'Tagihan Biro Jasa', key: '/biro', path: '/biro', icon: 'reconciliation', permissions: [P_BPKB_READ] },
                { label: 'Pembayaran Biro Jasa', key: '/biropayment', path: '/biropayment', icon: 'money-collect', permissions: [P_BPKB_READ] },
            ], permissions: [P_CLAIM_READ, P_STNK_READ, P_BPKB_READ, P_SPK_ADMIN]
        },
        {
            label: 'Subsidi & Insentif', key: '/si', path: '/si', icon: 'solution', children: [
                { label: 'Insentif Leasing', key: '/subsidileasing', path: '/subsidileasing', icon: 'audit', permissions: [P_CLAIM_READ] },
                { label: 'Subsidi WMS', key: '/subsidiwms', path: '/subsidiwms', icon: 'audit', permissions: [P_CLAIM_READ] },
                { label: 'Subsidi AHM', key: '/subsidiahm', path: '/subsidiahm', icon: 'audit', permissions: [P_CLAIM_READ] },
            ], permissions: [P_CLAIM_READ]
        },
        {
            label: 'Keuangan', key: '/mon', path: '/mon', icon: 'dollar', children: [
                { label: 'Penjualan', key: '/sale', path: '/sale', icon: 'dollar', permissions: [P_SALE_READ] },
                { label: 'Nilai Stock', key: '/stockvalue', path: '/stockvalue', icon: 'audit', permissions: [P_STOCK_VALUE] },
                { label: 'Pendingan pembayaran', key: '/pendingpayment', path: '/pendingpayment', icon: 'dollar', permissions: [P_PAYMENT_ADMIN] },
                { label: 'Uang Masuk', key: '/money', path: '/money', icon: 'dollar', permissions: [P_PAYMENT_ADMIN] },
            ], permissions: [P_SALE_READ, P_STOCK_VALUE, P_PAYMENT_ADMIN]
        },
        { label: 'CRM', key: '/crm', path: '/crm', icon: 'deployment-unit', permissions: [P_CRM] },
        {
            label: 'Customer', key: '/customer1', path: '/customer1', icon: 'team', children: [
                { label: 'Customer', key: '/customer', path: '/customer', icon: 'team', permissions: [P_CUSTOMER] },
                { label: 'Pekerjaan', key: '/customerjob', path: '/customerjob', icon: 'robot', permissions: [P_CUSTOMER] },
                { label: 'Pengeluaran', key: '/customerexpense', path: '/customerexpense', icon: 'robot', permissions: [P_CUSTOMER] },
            ], permissions: [P_CUSTOMER]
        },
        {
            label: 'Master', key: '/none', path: '/none', icon: 'form', children: [
                { label: 'Master Harga', key: '/masterprice', path: '/masterprice', icon: 'snippets', permissions: [P_MASTER_PRICE_READ] },
                { label: 'Subsidi WMS', key: '/wms', path: '/wms', icon: 'snippets', permissions: [P_MASTER_PRICE_READ] },
                { label: 'Subsidi AHM', key: '/ahm', path: '/ahm', icon: 'snippets', permissions: [P_MASTER_PRICE_READ] },
                //{ label: 'Region Master Harga', key: '/region', path: '/region', icon: 'snippets', permissions: [P_REGION] },
                { label: 'Motor', key: '/motor', path: '/motor', icon: 'snippets', permissions: [P_MOTOR_READ] },
                { label: 'Leasing', key: '/leasing', path: '/leasing', icon: 'snippets', permissions: [P_LEASING_READ] },
                { label: 'Sumber SPK', key: '/spksource', path: '/spksource', icon: 'snippets', permissions: [P_SPK_SOURCE_READ] },
                { label: 'Bank', key: '/bank', path: '/bank', icon: 'bank', permissions: [P_BANK_READ] },
                { label: 'Stempel & TTD', key: '/stamp', path: '/stamp', icon: 'snippets', permissions: [P_STAMP] },
            ], permissions: [P_MASTER_PRICE_READ, P_BANK_READ, P_SPK_SOURCE_READ, P_LEASING_READ, P_MOTOR_READ]
        },
    ]

    const buildMenuItem = (arr) => {
        return arr.filter(v => Permission.hasOneOf(v.permissions)).map(menu => {
            if (Array.isArray(menu.children)) {
                return <Menu.SubMenu key={menu.key}
                    title={<span><Icon type={menu.icon} /><span className="nav-text">{menu.label}</span></span>}>
                    {buildMenuItem(menu.children)}
                </Menu.SubMenu>
            }
            return (<Menu.Item key={menu.key}>
                <MyLink to={menu.path}>
                    <Icon type={menu.icon} />
                    <span className="nav-text">{menu.label}</span>
                </MyLink>
            </Menu.Item>
            )
        })
    }

    const iof = props.location.pathname.indexOf('/', 1);
    const path = iof > 0 ? props.location.pathname.substring(0, iof) : props.location.pathname

    return (
        <div>
            <Layout.Sider style={{
                overflowY: 'auto', height: '100%', backgroundColor: 'white',
            }} width={250} collapsible collapsed={global.collapse} trigger={null}
            >
                <div>
                    <Menu theme="light" mode="inline" defaultSelectedKeys={[path]}>
                        {buildMenuItem(menus)}
                    </Menu>
                </div>
                <div style={{ height: 20 }}></div>
            </Layout.Sider>
            <div style={{
                width: global.collapse ? 80 : 250, textAlign: 'center', fontSize: 10, backgroundColor: 'crimson',
                color: 'white', padding: '5px 0px 5px 0px', position: 'absolute',
                bottom: 0, left: 0
            }}>{packageJson.version}</div>
        </div>
    );
};

export default MySider;