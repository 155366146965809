import React from 'react';
import { Card, Row, Col, Tag, Tabs } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import { deliveryStatusToString, deliveryStatusToColor } from '../../constant'
import { LabelHeader } from '../../component/header'
import { MyLink } from '../../component/link';
import { useTab } from '../../component/tab';
import Picture from '../spk/picture'
import Ship from './ship';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/delivery/${props.match.params.id}`);
    const tab = useTab(props, 'tab', 'info')
    const col = { labelSpan: 8, valueSpan: 16 }

    return (
        <Card loading={loading} title={<Title title={"Detail DO"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Leasing" url="leasing" record={record} />} />} >
            {!loading &&
                <Tabs  {...tab}>
                    <Tabs.TabPane tab="Info" key="info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Tanggal DO">{formatDate(record.created_at)}</TextField>
                                <TextField {...col} label="Status">{<Tag color={deliveryStatusToColor(record.status)}>{deliveryStatusToString(record.status)}</Tag>}</TextField>
                                <TextField {...col} label="Alamat pengiriman">{record.address}</TextField>
                            </Col>
                        </Row>
                        <LabelHeader>Info SPK</LabelHeader>
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Tanggal SPK">{formatDate(record.spk.spk_date)}</TextField>
                                <TextField {...col} label="No SPK">{<MyLink to={`/spk/${record.spk.id}`}>{record.spk.number}</MyLink>}</TextField>
                                <TextField {...col} label="Nama pembeli">{record.spk.buyer_name}</TextField>
                                <TextField {...col} label="Telepon">{record.spk.buyer_phone}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Nama STNK">{record.spk.stnk_name}</TextField>
                                <TextField {...col} label="Marketing">{record.marketing ? record.marketing.name : '-'}</TextField>
                                <TextField {...col} label="Surveyor">{record.spk.surveyor ? record.spk.surveyor.name : '-'}</TextField>
                            </Col>
                        </Row>
                        <LabelHeader>Info Stock</LabelHeader>
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Motor">{record.motor.name}</TextField>
                                <TextField {...col} label="Color">{record.color.name}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="No mesin">{<MyLink to={`/stock/${record.stock.id}`}>{record.stock.machine_number}</MyLink>}</TextField>
                                <TextField {...col} label="No rangka">{<MyLink to={`/stock/${record.stock.id}`}>{record.stock.chasis_number}</MyLink>}</TextField>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Pengiriman" key="send">
                        <Ship record={record} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Photo" key="photo">
                        <Picture spkid={record.spk_id} type='delivery' />
                    </Tabs.TabPane>
                </Tabs>
            }
        </Card >
    );
}

export default Detail;