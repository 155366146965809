import React, { Fragment } from 'react';
import { Card, Row, Col, Divider, Button, Drawer, Table, Menu, Icon, Popconfirm, Dropdown, message, Form } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import Fetch from '../../Fetch';
import { InputText } from '../../component/input';

const Detail = props => {
    const [state, setState] = React.useState({ drawerVisible: false, edit: false })
    const [, tb] = useTable('/color', {}, {}, true)
    const [{ record, loading }, { fetchData }] = useDetail(`/motor/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const { getFieldDecorator, setFieldsValue, resetFields } = props.form;

    React.useEffect(() => {
        if (record && record.id) {
            tb.setFilter({ motor_id: record.id })
        }
    }, [record])

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/color/${id}`);
                message.info("Berhasil menghapus warna");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus motor: " + err);
            }
        }
    }

    const editClick = rec => {
        return () => {
            setFieldsValue({ code: rec.code, name: rec.name })
            setState({ ...state, drawerVisible: true, edit: true, colorId: rec.id })
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><span onClick={editClick(record)}><Icon type="edit" style={{ marginRight: 8 }} />Edit</span></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (state.edit) {
                        await Fetch.put(`/color/${state.colorId}`, { ...values, motor_id: record.id });
                        message.info('Master harga berhasil diedit');
                    } else {
                        await Fetch.post('/color', { ...values, motor_id: record.id });
                        message.info('Warna berhasil ditambahkan');
                    }
                    resetFields();
                    setState({ ...state, saveLoading: false, drawerVisible: false })
                    tb.setFilter({ motor_id: record.id })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    return (
        <Card loading={loading} title={<Title title={"Detail motor"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Motor" url="motor" />} />} >
            {!loading && <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField {...col} label="Code">{record.code}</TextField>
                        <TextField {...col} label="Nama">{record.name}</TextField>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <LabelHeader actions={<Button type="primary" onClick={() => {
                    setState({ ...state, drawerVisible: true, edit: false });
                    resetFields();
                }}>Tambah warna</Button>} >Warna motor</LabelHeader>
                <Table {...tb.tableProps} style={{ height: '100%' }} >
                    <Table.Column key="code" dataIndex="code" title="Code" />
                    <Table.Column key="name" dataIndex="name" title="Nama warna" />
                    <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                        return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                    }} />
                </Table>
                <Drawer title="Tambah warna" onClose={() => setState({ ...state, drawerVisible: false })} visible={state.drawerVisible} width={400}>
                    <Form name="create_color" onSubmit={formSummited}>
                        <Form.Item label="Kode warna">
                            {getFieldDecorator('code', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan kode warna" />
                            )}
                        </Form.Item>
                        <Form.Item label="Nama warna">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama warna" />
                            )}
                        </Form.Item>
                        <Divider />
                        <Form.Item>
                            <Button style={{ marginRight: 10 }} onClick={() => setState({ ...state, drawerVisible: false })} >Cancel</Button>
                            <Button type="primary" htmlType="submit" loading={state.saveLoading}>Simpan</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </Fragment>}
        </Card >
    );
}

export default Form.create({ name: 'create_color' })(Detail);