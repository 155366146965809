import React from 'react';
import { Form, Divider, Button, message, InputNumber, Select, Input, Alert } from 'antd';
import Fetch from '../../Fetch';
import { TextField } from '../../component/field'
import { paymentType } from '../../constant'

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const ref = React.useRef({ value: 0, admin: 0, total: 0, method: undefined })
    const [xendit, setXendit] = React.useState([])
    const [state, setState] = React.useState({
        loading: false, saveLoading: false, helper: null,
        paymentType: undefined, value: 0, max: 0, admin: 0, total: 0,
        xenditHelper: '', showPhone: false, error: '',
    });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    React.useEffect(() => {
        Fetch.get('/xendit/cost').then(v => {
            setXendit(v.data)
        })
        setFieldsValue({ 'email': props.record.buyer_email, 'phone': props.record.buyer_phone })
    }, [])

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true, error: '' })
                    await Fetch.post('/spkpayment/xendit', {
                        ...values, additional: state.admin, additional_message: "Biaya admin xendit",
                        spk_id: props.record.id, bank_id: 0
                    });
                    message.info('Pembayaran berhasil ditambahkan');
                    setState({ ...state, saveLoading: false })
                    props.onCancel(true);
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false, error: err })
                }
            }
        });
    }

    const typeOnChange = v => {
        const total = v === 'pelunasan' || v === 'tandajadi' ? props.record.payment_residual :
            v === 'progressive' ? props.record.progressive : props.record.violation;
        setFieldsValue({ 'value': total })
        setState(s => { return { ...s, paymentType: v, helper: `Max : ${total.toLocaleString('in-ID')}`, max: total } })
        ref.current.value = total
        calculate()
    }

    const valueChange = v => {
        ref.current.value = v
        calculate()
    }

    const onCancel = () => {
        props.onCancel();
    }

    const methodChange = v => {
        ref.current.method = v
        calculate()
        setTimeout(() =>
            setFieldsValue({ 'email': props.record.buyer_email, 'phone': props.record.buyer_phone }), 10)
    }

    const calculate = () => {
        const add = ref.current.method === undefined ? { cost: '', helper: '' } : xendit.filter(v1 => v1.code === ref.current.method)[0]
        const arr = add.cost.split('+')
        let cost = 0;
        for (let i = 0; i < arr.length; i++) {
            const a = arr[i]
            if (a.indexOf('%') > 0) {
                cost += parseInt((ref.current.value * parseFloat(a.substr(0, a.length - 1)) / 100))
            } else {
                cost += parseInt(a)
            }
        }
        const t = ref.current.value + cost
        setState(s => {
            return {
                ...s, value: ref.current.value, total: t, admin: cost,
                method: ref.current.method, xenditHelper: add.helper,
                showPhone: ref.current.method === 'ovo'
            }
        })
    }

    const renderValue = v => <div style={{ width: '100%', textAlign: 'right', paddingRight: 50 }}>
        {v.toLocaleString('in-ID')}
    </div>

    return (

        <Form layout="horizontal" name="create_xendit" onSubmit={formSummited} {...formItemLayout}>
            <Alert message="Untuk pembayaran offline, silakan menggunakan Android App, karena dibutuhkan tanda tangan" />
            <div style={{ marginTop: 8 }} />
            <Form.Item label="Tipe" style={{ marginBottom: 4 }}>
                {getFieldDecorator('type', {
                    rules: [{ required: true, message: 'Harus diisi' }]
                })(
                    <Select placeholder="Pilih tipe pembayaran" onChange={typeOnChange} >
                        {paymentType.filter(fil => {
                            if (fil.value === 'tandajadi' && props.tandajadi) return false;
                            if (fil.value === 'progressive' && props.record.progressive === 0) return false;
                            if (fil.value === 'progressive' && props.record.progressive_paid) return false;
                            if (fil.value === 'violation' && props.record.violation === 0) return false;
                            if (fil.value === 'violation' && props.record.violation_paid) return false;
                            return true;
                        }).map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Jumlah" extra={state.helper} style={{ marginBottom: 4 }}>
                {getFieldDecorator('value', {
                    rules: [{ required: true, message: 'Harus diisi' }]
                })(
                    <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }} onChange={valueChange}
                        readOnly={state.paymentType === 'progressive' || state.paymentType === 'violation'}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Jumlah pembayaran" max={state.max} />
                )}
            </Form.Item>
            <Form.Item label="Metode" extra={state.xenditHelper} style={{ marginBottom: 4 }}>
                {getFieldDecorator('method', {
                    rules: [{ required: true, message: 'Harus diisi' }]
                })(
                    <Select placeholder="Pilih metode pembayaran" onChange={methodChange} >
                        {xendit.map(v => <Select.Option key={v.code} value={v.code}>{v.name}</Select.Option>)}
                    </Select>
                )}
            </Form.Item >
            {!state.showPhone && <Form.Item label="Email" style={{ marginBottom: 4 }}>
                {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Harus diisi' }, { type: 'email', message: 'Email tidak valid' }]
                })(
                    <Input placeholder="Email" />
                )}
            </Form.Item>}
            {state.showPhone && <Form.Item label="No HP OVO" style={{ marginBottom: 4 }}>
                {getFieldDecorator('phone', {
                    rules: [{ required: true, message: 'Harus valid no HP' },]
                })(
                    <Input placeholder="No HP OVO" />
                )}
            </Form.Item>}
            <Divider />
            <TextField label="Sub Total" labelSpan={8} valueSpan={16}>{renderValue(state.value)}</TextField>
            <TextField label="Admin Xendit" labelSpan={8} valueSpan={16}>{renderValue(state.admin)}</TextField>
            <TextField label="Total" labelSpan={8} valueSpan={16}><div style={{ width: '100%', textAlign: 'right', paddingRight: 50, fontSize: 16 }}>
                {state.total.toLocaleString('in-ID')}
            </div></TextField>
            <Divider />
            {state.error !== '' && <Alert message={state.error} type="error" />}
            <div style={{ marginTop: 8 }} />

            <Form.Item>
                <Button style={{ marginRight: 10 }} onClick={onCancel} >Cancel</Button>
                <Button type="primary" htmlType="submit" loading={state.saveLoading} >Bayar</Button>
            </Form.Item>
        </Form>
    );
}

export default Form.create({ name: 'create_xendit' })(Add);