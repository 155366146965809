import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, DatePicker } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { InputText } from '../../component/input';
import { TextField } from '../../component/field'
import { formatDate, formatMoney } from '../../component/util';
import moment from 'moment';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, data: {} });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/savebank/${props.match.params.id}`, values);
                        message.info('Bank berhasil diedit');
                    } else {
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/savecash');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/savebank/${props.match.params.id}`);
        setState({ ...state, loading: false, data: data });
        setFieldsValue({
            date: data.date ? moment(data.date) : undefined,
            resi: data.resi,
        });
    }

    return (
        <Card title={<Title title={edit ? "Edit setoran tunai" : "Tambah bank"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_bank" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <TextField labelSpan={8} valueSpan={16} label={"Tanggal dibuat"}>{formatDate(state.data.created_at)}</TextField>
                        <TextField labelSpan={8} valueSpan={16} label={"Nomor"}>{state.data.number}</TextField>
                        <TextField labelSpan={8} valueSpan={16} label={"Total"}>{formatMoney(state.data.total)}</TextField>
                        <Divider />
                        <Form.Item label="Tanggal Setor">
                            {getFieldDecorator('date', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker placeholder="Masukkan tanggal setor" format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                        <Form.Item label="Nomer bukti">
                            {getFieldDecorator('resi', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan no bukti setor" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/bank')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading} > Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_bank' })(Add);