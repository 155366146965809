import React, { Fragment } from 'react';
import { Card, Form, Row, Col, Divider, Button, Input, message, Select, InputNumber, Modal } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import { LabelHeader } from '../../component/header';
import { TextField } from '../../component/field';
import { InputText, InputTextArea } from '../../component/input';
import { GlobalStore } from '../../store/store'

const Add = props => {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;
    const [{ global }] = React.useContext(GlobalStore);
    const edit = props.match.params.id !== undefined;
    const prospek = props.match.path === '/prospek/:id/spk'
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, colors: [], otr: undefined, cash: undefined, total: 0, region: 1, show_other: true });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        if (prospek) {
                            await Fetch.post(`/prospek/process_to_spk/${props.match.params.id}`, { ...values, cash: values.cash === 'cash' ? true : false });
                            message.info('Prospek berhasil diproses SPK');
                        } else {
                            await Fetch.put(`/spk/${props.match.params.id}`, { ...values, cash: values.cash === 'cash' ? true : false });
                            message.info('SPK berhasil diedit');
                        }
                    } else {
                        const c = await Fetch.post('/prospek/check', { buyer_name: values.buyer_name, buyer_phone: values.buyer_phone })
                        if (c.status === "duplicate") {
                            Modal.confirm({
                                title: 'SPK sudah ada',
                                content: <p>SPK dengan no telp / no ktp sudah ada, klik <a href={`/spk/${c.id}`} target="_blank" rel="noopener noreferrer">disini</a> untuk detail. Yakin untuk tetap menambah?</p>,
                                async onOk() {
                                    await Fetch.post('/spk', { ...values, cash: values.cash === 'cash' ? true : false });
                                    message.info('SPK berhasil ditambahkan');
                                    setState({ ...state, saveLoading: false })
                                    pop(props, '/spk');
                                },
                                onCancel() {
                                    setState({ ...state, saveLoading: false })
                                },
                            });
                            return;
                        } else if (c.status === "repeat") {
                            Modal.info({
                                title: "Konsumen Repeat Order",
                                content: <p>Konsumen merupakan repeat order, klik <a href={`/spk/${c.id}`} target="_blank" rel="noopener noreferrer">disini</a> untuk detail pesanan sebelumnya.</p>,
                                async onOk() {
                                    await Fetch.post('/spk', { ...values, repeat: true, cash: values.cash === 'cash' ? true : false });
                                    message.info('SPK berhasil ditambahkan');
                                    return
                                },
                            });
                        } else {
                            await Fetch.post('/spk', { ...values, cash: values.cash === 'cash' ? true : false });
                            message.info('SPK berhasil ditambahkan');
                        }
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/spk');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    React.useEffect(() => {
        calculate('p')(0)
    }, [state.cash])

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/spk/${props.match.params.id}`);
        const t = {
            buyer_name: data.buyer_name, buyer_address: data.buyer_address,
            buyer_phone: data.buyer_phone, buyer_email: data.buyer_email,
            motor_id: data.motor_id, color_id: data.color_id,
            spk_source_id: data.spk_source_id, note: data.note,
            stnk_name: data.stnk_name, stnk_address: data.stnk_address,
            otr_price: prospek ? undefined : data.otr_price, cash: data.cash ? 'cash' : 'credit',
            dp: prospek ? undefined : data.dp, discount: prospek ? undefined : data.discount, violation: prospek ? undefined : data.violation,
            progressive: prospek ? undefined : data.progressive, leasing_id: prospek ? undefined : data.leasing_id,
            installment: prospek ? undefined : data.installment, tenor: prospek ? undefined : data.tenor, prospek_number: data.prospek_number,
            buyer_home_phone: data.buyer_home_phone, buyer_whatsapp: data.buyer_whatsapp, no_ktp: data.no_ktp,
            region_id: data.region_id, other: data.other, user_id: data.user_id,
        };
        setFieldsValue(t);
        let otr = -1;
        const res = await Fetch.get(`/color?filter=(motor_id,,eq,,${data.motor_id})`)
        if (prospek) {
            const p = await Fetch.get(`/masterprice?filter=(master_price.motor_id,,eq,,${data.motor_id})`)
            if (p.data.length > 0) otr = p.data[0].otr;
        }
        setState({ ...state, colors: res.data, motor: data.motor_id, loading: false, cash: t.cash, otr: otr < 0 ? undefined : otr })
        onSumberSpkChanged(data.spk_source_id);
    }

    const onMotorChange = async v => {
        setFieldsValue({ color_id: undefined })
        const c = await Fetch.get(`/color?filter=(motor_id,,eq,,${v})`)
        const p = await Fetch.get(`/masterprice?filter=(master_price.motor_id,,eq,,${v})`)
        if (p.data.length > 0) setState({ ...state, otr: state.region === 2 ? p.data[0].otr_jkt : p.data[0].otr, colors: c.data, motor: v })
        else setState({ ...state, otr: undefined, colors: c.data, motor: v })
    }

    const calculate = (key) => {
        return v => {
            const otr = key === 'otr_price' ? v : getFieldValue('otr_price') || 0;
            const dp = key === 'dp' ? v : getFieldValue('dp') || 0;
            const discount = key === 'discount' ? v : getFieldValue('discount') || 0;
            const violation = key === 'violation' ? v : getFieldValue('violation') || 0;
            const progressive = key === 'progressive' ? v : getFieldValue('progressive') || 0;
            const cash = key === 'cash' ? v : state.cash;
            const total = cash === 'cash' ? otr - discount + violation + progressive : dp - discount + violation + progressive;
            if (key === 'cash') setState({ ...state, total: total, cash: v })
            else setState({ ...state, total: total })
        }
    }

    const regionChanged = async (v) => {
        if (state.motor) {
            const p = await Fetch.get(`/masterprice?filter=(motor_id,,eq,,${state.motor})`)
            if (p.data.length > 0) setState({ ...state, otr: v === 2 ? p.data[0].otr_jkt : p.data[0].otr, region: v })
            else setState({ ...state, otr: undefined, region: v })
        } else {
            setState({ ...state, region: v })
        }
    }

    const onSumberSpkChanged = (d) => {
        Fetch.get(`/spksource/${d}`).then(v => {
            setState(state => { return { ...state, show_other: v.need_other } })
        })
    }
    return (
        <Card title={<Title title={prospek ? 'Proses Prospek ke SPK' : edit ? "Edit spk" : "Tambah spk"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_spk" onSubmit={formSummited} {...formItemLayout}>
                {global.profile.type !== 'normal' && <React.Fragment>
                    <LabelHeader>Info Marketing</LabelHeader>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Marketing">
                                {getFieldDecorator('user_id', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <MySelect showSearch dataIndex="name" valueIndex="id" placeholder="Pilih marketing"
                                        url={`/user?filter=(id,,in,,${global.profile.users.join(',')})&limit=-1`} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </React.Fragment>}
                <LabelHeader>Info Pembeli</LabelHeader>
                <Row gutter={32}>
                    <Col span={12}>
                        {prospek && <Form.Item label="No Prospek">
                            {getFieldDecorator('prospek_number')(
                                <InputText placeholder="Masukkan nama pembeli" readOnly />
                            )}
                        </Form.Item>}
                        <Form.Item label="Nama">
                            {getFieldDecorator('buyer_name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama pembeli" />
                            )}
                        </Form.Item>
                        <Form.Item label="Telepon HP">
                            {getFieldDecorator('buyer_phone', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan telepon HP" />
                            )}
                        </Form.Item>
                        <Form.Item label="No Whatsapp">
                            {getFieldDecorator('buyer_whatsapp')(
                                <InputText placeholder="Masukkan no whatsapp" />
                            )}
                        </Form.Item>
                        <Form.Item label="Telepon Rumah">
                            {getFieldDecorator('buyer_home_phone')(
                                <InputText placeholder="Masukkan telepon rumah" />
                            )}
                        </Form.Item>
                        <Form.Item label="Email">
                            {getFieldDecorator('buyer_email')(
                                <Input placeholder="Masukkan email" />
                            )}
                        </Form.Item>
                        <Form.Item label="Alamat">
                            {getFieldDecorator('buyer_address', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputTextArea placeholder="Masukkan alamat" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="No KTP">
                            {getFieldDecorator('no_ktp', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan No KTP" />
                            )}
                        </Form.Item>
                        <Form.Item label="Nama STNK">
                            {getFieldDecorator('stnk_name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama STNK" />
                            )}
                        </Form.Item>
                        <Form.Item label="Alamat STNK">
                            {getFieldDecorator('stnk_address', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputTextArea placeholder="Masukkan alamat STNK" />
                            )}
                        </Form.Item>
                        <Form.Item label="Sumber SPK">
                            {getFieldDecorator('spk_source_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect showSearch dataIndex="name" valueIndex="id" placeholder="Pilih motor" url="/spksource"
                                    onChange={onSumberSpkChanged} />
                            )}
                        </Form.Item>
                        {state.show_other &&
                            <Form.Item label="Sumber lain">
                                {getFieldDecorator('other')(
                                    <InputText placeholder="Lainnya" />
                                )}
                            </Form.Item>
                        }
                        <Form.Item label="Region">
                            {getFieldDecorator('region_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih region" onChange={regionChanged}>
                                    <Select.Option value={1} key={1}>Tangerang</Select.Option>
                                    <Select.Option value={2} key={2}>Jakarta</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <LabelHeader>Info motor dan pembayaran</LabelHeader>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Motor">
                            {getFieldDecorator('motor_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect showSearch dataIndex="name" valueIndex="id" placeholder="Pilih motor" url="/motor?start=0&limit=1000"
                                    onChange={onMotorChange} />
                            )}
                        </Form.Item>
                        {(edit || state.motor) && <Form.Item label="Color">
                            {getFieldDecorator('color_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Silakan pilih warna">
                                    {state.colors.map(v => <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>}
                        <Form.Item label="Cash/Kredit">
                            {getFieldDecorator('cash', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih cash atau kredit" onChange={v => {
                                    calculate('cash')(v)
                                }}>
                                    <Select.Option key='cash' value='cash'>Cash</Select.Option>
                                    <Select.Option key='credit' value='credit'>Kredit</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Harga OTR" extra={state.otr >= 0 ?
                            <span className="span-click" onClick={() => {
                                setFieldsValue({ otr_price: state.otr })
                                calculate('otr_price')(state.otr)
                            }}>
                                {`Harga master : ${parseInt(state.otr).toLocaleString('in-ID')}`}</span> : null} >
                            {getFieldDecorator('otr_price', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" onChange={calculate('otr_price')} style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Harga otr" />
                            )}
                        </Form.Item>
                        {(state.cash === undefined || state.cash === 'credit') && <Form.Item label="DP" >
                            {getFieldDecorator('dp', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" onChange={calculate('dp')} style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan DP" />
                            )}
                        </Form.Item>}
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Diskon" >
                            {getFieldDecorator('discount', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" onChange={calculate('discount')} style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan diskon" />
                            )}
                        </Form.Item>
                        <Form.Item label="Pelanggaran" >
                            {getFieldDecorator('violation', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" onChange={calculate('violation')} style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan pelanggaran wilayah" />
                            )}
                        </Form.Item>
                        <Form.Item label="Progresif" >
                            {getFieldDecorator('progressive', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" onChange={calculate('progressive')} style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan pajak progresif" />
                            )}
                        </Form.Item>
                        <TextField label="Total"><div style={{ width: '100%', textAlign: 'right', fontSize: 18, paddingRight: 10 }}>
                            {state.total.toLocaleString('in-ID')}</div></TextField>
                    </Col>
                </Row>
                {(state.cash === undefined || state.cash === 'credit') && <Fragment>
                    <LabelHeader>Info leasing</LabelHeader>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Leasing">
                                {getFieldDecorator('leasing_id', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <MySelect showSearch dataIndex="name" valueIndex="id" placeholder="Pilih leasing" url="/leasing?order=name asc&limit=1000" />
                                )}
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label="Tenor" >
                                {getFieldDecorator('tenor', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan tenor dalam bulan" />
                                )}
                            </Form.Item>
                            <Form.Item label="Cicilan" >
                                {getFieldDecorator('installment', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Masukkan cicilan perbulan" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Fragment>}
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/spk')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> {prospek ? 'Proses ke SPK' : 'Simpan'} </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_spk' })(Add);