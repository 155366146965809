import React from 'react';
import { Card, Table, Button, DatePicker } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { formatMoney, getCurrentMonthYear } from '../../component/util'
import moment from 'moment';
import { push } from '../../component/link';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="dollar" href={`/subsidileasingreceipt`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/subsidileasingreceipt`);
                }}>Pembayaran</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [, tb] = useTable('/claim/summaryintensiveleasing', {
        order: { key: 'created_at', order: 'descend' },
        filter: { 'date': getCurrentMonthYear() }
    })

    const [state, setState] = React.useState({ curDate: moment().startOf('month') })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/subsidileasing/${record.id}/${state.curDate.format('YYYY-MM')}`, '_blank')
            }
        }
    }

    const dateChanged = (v) => {
        tb.setFilter({ 'date': v.format('YYYY-MM') })
        setState({ curDate: v })
    }

    return (
        <Card title={<Title title={"Daftar insentif leasing"} actions={<Actions  {...props} refresh={tb.fetchData} />} />}>
            <div style={{ marginBottom: 16 }}>
                <DatePicker.MonthPicker defaultValue={state.curDate} format="MMM - YYYY" onChange={dateChanged} />
            </div>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="name" dataIndex="name" title="Name" {...tb.sortProps('name')} />
                <Table.Column key="total" dataIndex="total" align="right" title="Total" render={formatMoney} width={200} />
            </Table>
        </Card >
    )
}

export default List;