import React from 'react';
import Fetch from '../Fetch';

const useDetail = (url, initialState) => {
    const [state, setState] = React.useState({ loading: true, record: {}, ...initialState })

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(url);
        setState({ ...state, loading: false, record: data })
    }

    return [state, {
        fetchData: fetchData,
    }];
}

export { useDetail }