import React, { Fragment } from 'react'
import Fetch from '../../Fetch';
import { Typography } from 'antd';

const ImageView = props => {
    const [state, setState] = React.useState({ loading: true, images: [] })

    React.useEffect(() => {
        if (props.images.length > 0)
            setState({ ...state, curImage: props.images[0] })
    }, [props.images])

    return (
        <Fragment>
            {state.curImage && <div>
                <div style={{ border: 1, borderColor: '#e0e0e0', borderStyle: 'solid', padding: '5px', borderRadius: '5px', overflow: 'auto' }} >
                    <div style={{ display: 'flex', overflow: 'auto' }}>
                        <div style={{ margin: '5px auto' }}>
                            <a href={Fetch.getUrl(`/public/spkimages/${state.curImage.image}`)} target="_blank" rel="noopener noreferrer">
                                <img alt={state.curImage.title} src={Fetch.getUrl(`/public/spkimages/${state.curImage.image}`)} style={{ height: '400px' }} />
                            </a>
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Typography.Text>{state.curImage.title}</Typography.Text>
                    </div>
                </div>
                <div style={{
                    display: 'flex', margin: '10px 0', border: 1, borderColor: '#e0e0e0',
                    borderStyle: 'solid', padding: '5px', borderRadius: '5px', overflow: 'auto'
                }}>
                    {props.images.map(v =>
                        <div key={v.id} style={{ margin: '0 10px' }} className="span-click" onClick={() => setState({ ...state, curImage: v })}>
                            <img alt={v.title} src={Fetch.getUrl(`/public/spkimages/${v.image}`)} style={{ width: '100px', height: '100px' }} />
                            <div style={{ textAlign: "center" }}>{v.title}</div>
                        </div>
                    )}
                </div>
            </div>}
        </Fragment>
    )
}

export default ImageView;