import React from 'react';
import { Card, Row, Col, Divider, Table, Tag } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link'
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch';
import { biroStatusToColor, biroStatusToString } from '../../constant'

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/biro/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const [state, setState] = React.useState({ data: [], loading: true })

    React.useEffect(() => {
        if (record.id)
            Fetch.get(`/stnk?filter=(biro_id,,eq,,${record.id})&order=created_at DESC&limit=1000`).then(v => {
                setState({ ...state, loading: false, data: v.data });
            })
    }, [record])

    return (
        <Card loading={loading} title={<Title title={"Detail Tagihan Biro Jasa"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Biro" url="biro" showDelete={false} showEdit={record.status === 'new'} />} />} >
            {!loading && <div>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField {...col} label="Tanggal">{formatDate(record.date)}</TextField>
                        <TextField {...col} label="No tagihan">{record.number}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="Status">{<Tag color={biroStatusToColor(record.status)}>{biroStatusToString(record.status)}</Tag>}</TextField>
                        <TextField {...col} label="Total">{formatMoney(record.value)}</TextField>
                    </Col>
                </Row>
                <Divider />
                <Table loading={state.loading} dataSource={state.data} rowKey="id" pagination={false} scroll={{ x: true }}>
                    <Table.Column key="plate_number" dataIndex="plate_number" title="No Polisi" />
                    <Table.Column key="stock.machine_number" dataIndex="stock.machine_number" title="No mesin" />
                    <Table.Column key="spk.buyer_name" dataIndex="spk.buyer_name" title="Nama pembeli" />
                    <Table.Column key="bbn" dataIndex="bbn" title="BBN" render={formatMoney} />
                    <Table.Column key="biro" dataIndex="biro" title="Jasa" render={formatMoney} />
                    <Table.Column key="other_cost" dataIndex="other_cost" title="Biaya lain" render={formatMoney} />
                    <Table.Column key="total" dataIndex="total" title="Total" render={formatMoney} />
                    <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                        return <MyLink to={`/stnk/${r.id}`}>Detail</MyLink>
                    }} />
                </Table>
            </div>
            }
        </Card >
    );
}

export default Detail;
