import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Select, DatePicker, InputNumber, Checkbox } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import MySelect from '../../component/select'
import { pop, push } from '../../component/link';
import moment from 'moment';
import { InputText, InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, colors: [] });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/stock/${props.match.params.id}`, values);
                        message.info('Stock berhasil diedit');
                    } else {
                        await Fetch.post('/stock', values);
                        message.info('Stock berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/stock');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/stock/${props.match.params.id}`);
        setState({ ...state, loading: false });
        setFieldsValue({
            purchase_id: data.purchase_id,
            motor_id: data.motor_id,
            color_id: data.color_id,
            machine_number: data.machine_number,
            chasis_number: data.chasis_number,
            sender: data.sender,
            sj_number: data.sj_number,
            sj_date: moment(data.sj_date),
            on_stock: data.on_stock,
            note: data.note,
            year: data.year,
            rfs: data.rfs, book_service: data.book_service, helm: data.helm, mirror: data.mirror, toolkit: data.toolkit,
        });
    }

    const onMotorChange = v => {
        setFieldsValue({ color_id: undefined })
        Fetch.get(`/color?filter=(motor_id,,eq,,${v})`).then(res => setState({ ...state, colors: res.data, motor: v }))
    }

    return (
        <Card title={<Title title={edit ? "Edit stock" : "Tambah stock"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_stock" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="No SO" help={<span className="span-click" onClick={() => push(props, '/purchase/add')}>Tambah SO baru</span>}>
                            {getFieldDecorator('purchase_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect showSearch placeholder="Silakan pilih SO" dataIndex="number" valueIndex="id" url="/purchase?limit=100&order=date desc" />
                            )}
                        </Form.Item>
                        <Form.Item label="Motor">
                            {getFieldDecorator('motor_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect showSearch placeholder="Silakan pilih motor" dataIndex="name" valueIndex="id" url="/motor?limit=250&order=name asc"
                                    onChange={onMotorChange} />
                            )}
                        </Form.Item>
                        <Form.Item label="Warna">
                            {getFieldDecorator('color_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih warna motor">
                                    {state.colors.map(v => <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="No Mesin">
                            {getFieldDecorator('machine_number', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan no mesin" />
                            )}
                        </Form.Item>
                        <Form.Item label="No Rangka">
                            {getFieldDecorator('chasis_number', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan no rangka" />
                            )}
                        </Form.Item>
                        <Form.Item label="Tahun">
                            {getFieldDecorator('year', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukkan tahun" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Pengirim">
                            {getFieldDecorator('sender', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan pengirim" />
                            )}
                        </Form.Item>
                        <Form.Item label="No pengiriman">
                            {getFieldDecorator('sj_number', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan no SJ" />
                            )}
                        </Form.Item>
                        <Form.Item label="Tanggal pengiriman">
                            {getFieldDecorator('sj_date', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                        <Form.Item label="Tersedia">
                            {getFieldDecorator('on_stock', { valuePropName: 'checked', })(
                                <Checkbox>Stock tersedia</Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item label="Note">
                            {getFieldDecorator('note')(
                                <InputTextArea autosize placeholder="Masukkan catatan" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item >
                            {getFieldDecorator('rfs', { valuePropName: 'checked', })(
                                <Checkbox>RFS</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item >
                            {getFieldDecorator('service_book', { valuePropName: 'checked', })(
                                <Checkbox>Servis</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item >
                            {getFieldDecorator('helm', { valuePropName: 'checked', })(
                                <Checkbox>Helm</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item >
                            {getFieldDecorator('accu', { valuePropName: 'checked', })(
                                <Checkbox>Aki</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item >
                            {getFieldDecorator('mirror', { valuePropName: 'checked', })(
                                <Checkbox>Spion</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item >
                            {getFieldDecorator('toolkit', { valuePropName: 'checked', })(
                                <Checkbox >Toolkit</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/stock')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card >
    );
}

export default Form.create({ name: 'create_stock' })(Add);