import React, { Fragment } from 'react';
import { Card, Row, Col, Tag, Divider } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import {
    customerTypeToString, customerTypeToColor, custBuyTypeToString, custBuyTypeToColor, custHomeStatusToString, custEducationToString,
    custMotorBrandToString, custMotorTypeToString, custUserToString, custPurposeToString
} from '../../constant'

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/customer/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }

    const renderValue = v => <div style={{ width: '100%', textAlign: 'right', paddingRight: 50 }}>
        {v.toLocaleString('in-ID')}
    </div>

    return (
        <Card loading={loading} title={<Title title={"Detail customer"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Customer" url="customer" />} />} >
            {!loading &&
                <Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Tipe"><Tag color={customerTypeToColor(record.type)}>{customerTypeToString(record.type)}</Tag></TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Tanggal pembelian">{formatDate(record.buy_date)}</TextField>
                        </Col>
                    </Row>
                    <Divider />
                    {record.type === 'individu' && <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Jenis pembelian"><Tag color={custBuyTypeToColor(record.buy_type)}>{custBuyTypeToString(record.buy_type)}</Tag></TextField>
                            <TextField {...col} label="No. KTP / KITAS">{record.identity_number}</TextField>
                            <TextField {...col} label="Kewarganegaraan">{record.country}</TextField>
                            <TextField {...col} label="No KK">{record.kk_number}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Nama pemilik">{record.owner_name}</TextField>
                            <TextField {...col} label="Jenis kelamin">{record.gender}</TextField>
                            <TextField {...col} label="Tanggal lahir">{formatDate(record.birth_date)}</TextField>
                        </Col>
                    </Row>}
                    {record.type === 'company' && <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="No. TDP">{record.identity_number}</TextField>
                            <TextField {...col} label="Tanggal NPWP">{formatDate(record.birth_date)}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Telepon Kantor">{record.home_phone}</TextField>
                        </Col>
                    </Row>}
                    <Divider />
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label={record.type === 'individu' ? "Alamat KTP" : "Alamat sesuai TDP"}>{record.address}</TextField>
                            <TextField {...col} label="RT">{record.rt}</TextField>
                            <TextField {...col} label="RW">{record.rw}</TextField>
                            <TextField {...col} label="Kelurahan">{record.kelurahan}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Kecamatan">{record.kecamatan}</TextField>
                            <TextField {...col} label="Kabupaten / Kota">{record.city}</TextField>
                            <TextField {...col} label="Provinsi">{record.province}</TextField>
                            <TextField {...col} label="Kode Pos">{record.poscode}</TextField>
                        </Col>
                    </Row>
                    <Divider />
                    {record.type === 'individu' &&
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Agama">{record.religion}</TextField>
                                <TextField {...col} label="Email">{record.email}</TextField>
                                <TextField {...col} label="Facebook">{record.facebook}</TextField>
                                <TextField {...col} label="Twitter">{record.twitter}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Instagram">{record.instagram}</TextField>
                                <TextField {...col} label="Youtube">{record.youtube}</TextField>
                                <TextField {...col} label="Hobi">{record.hopbby}</TextField>
                            </Col>
                        </Row>}
                    {record.type === 'company' &&
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Nama penanggungjawab">{record.owner_name}</TextField>
                                <TextField {...col} label="Email">{record.email}</TextField>
                                <TextField {...col} label="Telepon HP">{record.mobile_phone}</TextField>
                                {record.mobile_phone !== '' && <TextField {...col} label="Tipe no HP">{record.mobile_type === 'pra' ? 'Prabayar' : 'Pasca Bayar'}</TextField>}
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Facebook">{record.facebook}</TextField>
                                <TextField {...col} label="Twitter">{record.twitter}</TextField>
                                <TextField {...col} label="Instagram">{record.instagram}</TextField>
                                <TextField {...col} label="Youtube">{record.youtube}</TextField>
                            </Col>
                        </Row>}
                    <Divider />
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Alamat tinggal">{record.customer_address_id > 0 ? 'Lainnya' : record.type === 'individu' ? 'Sesuai KTP' : 'Sesuai TDP'}</TextField>
                        </Col>
                        <Col span={12}>
                            {record.type === 'individu' && <TextField {...col} label="Status rumah">{custHomeStatusToString(record.home_status)}</TextField>}
                        </Col>
                    </Row>
                    {record.customer_address_id > 0 &&
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Alamat tinggal">{record.customer_address.address}</TextField>
                                <TextField {...col} label="RT">{record.customer_address.rt}</TextField>
                                <TextField {...col} label="RW">{record.customer_address.rw}</TextField>
                                <TextField {...col} label="Kelurahan">{record.customer_address.kelurahan}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Kecamatan">{record.customer_address.kecamatan}</TextField>
                                <TextField {...col} label="Kabupaten / Kota">{record.customer_address.city}</TextField>
                                <TextField {...col} label="Provinsi">{record.customer_address.province}</TextField>
                                <TextField {...col} label="Kode Pos">{record.customer_address.poscode}</TextField>
                            </Col>
                        </Row>
                    }
                    <Divider />
                    {record.type === 'individu' &&
                        <Fragment>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <TextField {...col} label="Pekerjaan">{record.customer_job.name}</TextField>
                                    {record.customer_job2_id > 0 && <TextField {...col} label="Bidang">{record.customer_job2.name}</TextField>}
                                    <TextField {...col} label="Pengeluaran 1 bulan">{record.customer_expense.name}</TextField>
                                    <TextField {...col} label="Pendidikan terakhir">{custEducationToString(record.customer_education)}</TextField>
                                    <TextField {...col} label="Telepon rumah">{record.home_phone}</TextField>
                                    <TextField {...col} label="Telepon HP">{record.mobile_phone}</TextField>
                                    {record.mobile_phone !== '' && <TextField {...col} label="Tipe no HP">{record.mobile_type === 'pra' ? 'Prabayar' : 'Pasca Bayar'}</TextField>}
                                    <TextField {...col} label="Terima info Honda">{record.send_info ? 'Iya' : 'Tidak'}</TextField>
                                </Col>
                                <Col span={12}>
                                    <TextField {...col} label="Motor sebelumnya">{record.motor_brands.map(v => custMotorBrandToString(v)).join(', ')}</TextField>
                                    <TextField {...col} label="Tipe motor sebelumnya">{record.motor_types.map(v => custMotorTypeToString(v)).join(', ')}</TextField>
                                    <TextField {...col} label="Kegunaan motor">{record.motor_purposes.map(v => custPurposeToString(v)).join(', ')}</TextField>
                                    <TextField {...col} label="Pengguna motor">{record.motor_users.map(v => custUserToString(v)).join(', ')}</TextField>
                                </Col>
                            </Row>
                            <Divider />
                        </Fragment>
                    }
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Penjualan">{record.spk.cash ? "Cash" : "Kredit"}</TextField>
                            <TextField {...col} label="Sales ID">{record.user.ahm_id_sales_person}</TextField>
                            <TextField {...col} label="Sales Name">{record.user.ahm_name}</TextField>
                            {!record.spk.cash && <TextField {...col} label="Leasing">{record.leasing.name}</TextField>}
                            {!record.spk.cash && <TextField {...col} label="Tenor">{renderValue(record.spk.tenor)}</TextField>}
                            {!record.spk.cash && <TextField {...col} label="Cicilan">{renderValue(record.spk.installment)}</TextField>}
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Kode dealer">{record.dealer_code}</TextField>
                            <TextField {...col} label="RO BD ID">{record.ro_bd_id}</TextField>
                            <TextField {...col} label="Referensi ID">{record.reference_id}</TextField>
                            <TextField {...col} label="Catatan">{record.note}</TextField>
                        </Col>
                    </Row>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;