import React, { Fragment } from 'react';
import { Card, Table } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'

const TeamMember = props => {
    return (
        <Table dataSource={props.dataSource} rowKey="id" pagination={{ hideOnSinglePage: true }}>
            <Table.Column key="username" dataIndex="username" title="Username" />
            <Table.Column key="name" dataIndex="name" title="Nama" />
        </Table>
    )
}

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/team/${props.match.params.id}`);
    const col = { labelSpan: 5, valueSpan: 19 }

    return (
        <Card loading={loading} title={<Title title={"Detail team"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Team" url="team" />} />} >
            {!loading &&
                <Fragment>
                    <TextField {...col} label="Nama">{record.name}</TextField>
                    <TextField {...col} label="Supervisor"><TeamMember dataSource={record.supervisors.map(v => v.user)} /></TextField>
                    <TextField {...col} label="Lead"><TeamMember dataSource={record.leads.map(v => v.user)} /></TextField>
                    <TextField {...col} label="Member"><TeamMember dataSource={record.members.filter(v => v.user !== null).map(v => v.user)} /></TextField>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;