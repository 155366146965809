import React from 'react';
import { Card, Form, Row, Col, Divider, Button, Tag, message, Table, Checkbox } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { TextField } from '../../component/field';
import { MyLink, replace } from '../../component/link';
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import { InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [{ filter }, tb] = useTable('/stock', { filter: { on_stock: 't' } }, undefined, true)
    const edit = true;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, record: undefined });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };
    const col = { labelSpan: 8, valueSpan: 16 }
    const { record } = state;

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    if (state.stock === undefined) {
                        message.error('Stock belum dipilih')
                        return
                    }
                    setState({ ...state, saveLoading: true })
                    const { delivery } = await Fetch.post(`/spk/processdo/${record.id}/${state.stock.id}`, values);
                    message.info('SPK berhasil di proses DO');
                    setState({ ...state, saveLoading: false })
                    replace(props, `/delivery/${delivery.id}`)
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/spk/${props.match.params.id}`);
        setState({ ...state, loading: false, record: data });
        setFieldsValue({
            send_address: data.buyer_address,
        });
        tb.fetchData()
    }

    const rowSelection = {
        type: 'radio',
        onSelect: v => {
            setState({ ...state, stock: v })
        }
    }

    return (
        <Card title={<Title title="Proses SPK ke DO" {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_do" onSubmit={formSummited} {...formItemLayout}>
                {record && <Row gutter={32}>
                    <Col span={12}>
                        <TextField {...col} label="No SPK">{record.number}</TextField>
                        <TextField {...col} label="Nama pembeli">{record.buyer_name}</TextField>
                        <TextField {...col} label="Telepon pembeli">{record.buyer_phone}</TextField>
                        <TextField {...col} label="Motor">{record.motor.name}</TextField>
                        <TextField {...col} label="Warna">{record.color.name}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="Nama STNK">{record.stnk_name}</TextField>
                        <TextField {...col} label="Alamat STNK">{record.stnk_address}</TextField>
                        <TextField {...col} label="Cash/Kredit">{<Tag color={record.cash ? 'blue' : 'green'}>{record.cash ? 'Cash' : 'Kredit'}</Tag>}</TextField>
                        {record.leasing && <TextField {...col} label="Leasing">{record.leasing.name}</TextField>}
                        <TextField {...col} label="Marketing">{<MyLink to={`/user/${record.user.id}`}>{record.user.name}</MyLink>}</TextField>
                    </Col>
                </Row>}
                <div style={{ marginTop: 30 }} />
                <LabelHeader actions={<Checkbox onChange={e => {
                    if (e.target.checked) tb.setFilter({ ...filter, 'stock.motor_id': record.motor_id })
                    else {
                        const as = 'stock.motor_id';
                        const { [as]: s, ...rest } = filter;
                        tb.setFilter(rest)
                    }
                }}>Hanya motor yang sesuai</Checkbox>}>
                    Pilih stock</LabelHeader>
                <Table {...tb.tableProps} rowSelection={rowSelection}>
                    <Table.Column key="motor.name" dataIndex="motor.name" title="Motor"  {...tb.columnProps({ key: 'motor.name', hint: 'Cari nama motor' })}
                        render={(t, r) => <span>{r.motor_id === record.motor_id && <Tag color="green">OK</Tag>}{t}</span>} />
                    <Table.Column key="color.name" dataIndex="color.name" title="Warna"
                        render={(t, r) => <span>{r.color_id === record.color_id && <Tag color="green">OK</Tag>}{t}</span>} />
                    <Table.Column key="machine_number" dataIndex="machine_number" title="No Mesin"  {...tb.columnProps({ key: 'machine_number', hint: 'Cari no mesin' })} />
                    <Table.Column key="chasis_number" dataIndex="chasis_number" title="No Rangka"  {...tb.columnProps({ key: 'chasis_number', hint: 'Cari no rangka' })} />
                    <Table.Column key="year" dataIndex="year" title="Tahun"  {...tb.columnProps({ key: 'year', hint: 'Cari tahun' })} />
                </Table>
                <br />
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Alamat pengiriman">
                            {getFieldDecorator('send_address', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputTextArea placeholder="Masukkan alamat pengiriman" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {state.stock && state.stock.motor_id !== record.motor_id && <div style={{ color: 'red' }}>
                    Motor yang dipilih tidak sesuai dengan pesanan pada SPK, yakin untuk lanjut DO?
                </div>}
                {state.stock && state.stock.color_id !== record.color_id && <div style={{ color: 'red' }}>
                    Warna motor yang dipilih tidak sesuai dengan pesanan pada SPK, yakin untuk lanjut DO?
                </div>}
                <Divider style={{ margin: '5px 0' }} />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/spk')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Proses DO </Button>
                </Form.Item>
            </Form>
        </Card >
    );
}

export default Form.create({ name: 'create_do' })(Add);