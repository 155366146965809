
const filterOp = {
    '/motor': { 'name': 'q', 'code': 'q' },
    '/masterprice': { 'motor.name': 'q' },
    '/leasing': { 'name': 'q', 'fullname': 'q', 'leasing.fullname': 'q', 'leasing.name': 'q' },
    '/color': { 'name': 'q', 'motor_id': 'eq' },
    '/user': { 'username': 'q', 'name': 'q', 'email': 'q', 'phone': 'q', 'type': 'eq' },
    '/stocklocation': { 'name': 'q' },
    '/stock': { 'on_stock': 'eq', 'motor.name': 'q', 'machine_number': 'q', 'chasis_number': 'q', 'stock.created_at': 'bw' },
    '/stocksent': { 'number': 'q', 'to.name': 'q', 'from.name': 'q', 'stocksent.created_at': 'bw', },
    '/prospek': { 'buyer_name': 'q', 'buyer_phone': 'q', 'spk.created_at': 'bw' },
    '/spk': {
        'buyer_name': 'q', 'user.name': 'q', 'payment_residual': 'neq', 'spk.spk_date': 'bw', 'spk_status::not': 'neq', 'po_number::not': 'neq',
        'stock.machine_number': 'q', 'stock.chasis_number': 'q'
    },
    '/delivery': { 'delivery.created_at': 'bw', 'spk.buyer_name': 'q', 'spk.stnk_name': 'q', 'spk.buyer_phone': 'q', 'stock.machine_number': 'q', 'stock.chasis_number': 'q' },
    '/claim': {
        'claim.created_at': 'bw', 'spk.po_number::not': 'neq', 'claim.faktur_date::notnull': 'notnull', 'claim.faktur_date': 'bwd', 'spk.cash': 'bool',
        'spk.buyer_name': 'q', 'spk.stnk_name': 'q', 'spk.po_number': 'q', 'stock.machine_number': 'q', 'stock.chasis_number': 'q'
    },
    '/stnk': { 'stnk.created_at': 'bw', 'spk.stnk_name': 'q', 'srut': 'q', 'plate_number': 'q', 'stock.machine_number': 'q', 'stock.chasis_number': 'q' },
    '/bpkb': { 'bpkb.created_at': 'bw', 'bpkb.number': 'neq', "spk.stnk_name": 'q', 'stock.machine_number': 'q', 'stock.chasis_number': 'q' },
    '/bpkbreceipt': { 'bpkbreceipt.created_at': 'bw' },
    '/spkleasingsnapshot': { 'spk_leasing_snapshot.created_at': 'bw' },
    '/spkpayment': {
        'spk_payment.created_at': 'bw', 'payment_position_status::not': 'neq', 'bank.cash': 'bool', 'spk.buyer_name': 'q', 'spk.number': 'q',
        'stock.machine_number': 'q', 'stock.chasis_number': 'q'
    },
    '/sale': { 'sale.created_at': 'bw', 'motor.name': 'q', 'stock.machine_number': 'q', 'stock.chasis_number': 'q' },
    '/crm': { 'show_7': 'bool' },
}

const getFilterOp = (resource, key) => {
    let res = resource.split('?')[0]
    if (res.indexOf('/', 2) > 0) {
        res = `/${res.split('/')[1]}`
    }
    if (filterOp[res]) {
        if (filterOp[res][key])
            return filterOp[res][key]
    }
    return 'eq';
}

const userType = [
    { text: 'Normal', value: 'normal', color: 'green' }, { text: 'Surveyor', value: 'surveyor', color: 'blue' }
]

const userTypeToString = (t) => {
    const f = userType.find(v => v.value === t) || { text: 'Undefined' }
    return f.text;
}

const userTypeToColor = (t) => {
    const f = userType.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const prospekStatus = [
    { text: 'None', value: 'none', color: 'white' }, { text: 'Baru', value: 'new', color: 'blue' },
    { text: 'Cold', value: 'cold', color: 'salmon' }, { text: 'Hot!!!', value: 'hot', color: 'purple' },
    { text: 'SPK', value: 'spk', color: 'green' },
]

const prospekStatusToString = (t) => {
    const f = prospekStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const prospekStatusToColor = (t) => {
    const f = prospekStatus.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const spkStatus = [
    { text: 'None', value: 'none', color: 'white' }, { text: 'Baru', value: 'new', color: 'blue' },
    { text: 'Perbaikan', value: 'need_fix', color: 'salmon' }, { text: 'Konfirm', value: 'confirm', color: 'purple' },
    { text: 'Cancel', value: 'cancel', color: 'red' }, { text: 'DO', value: 'do', color: 'green' },
]

const spkStatusToString = (t) => {
    const f = spkStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const spkStatusToColor = (t) => {
    const f = spkStatus.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const surveyStatus = [
    { text: 'None', value: 'none', color: 'white' }, { text: 'Baru', value: 'new', color: 'blue' },
    { text: 'Proses', value: 'process', color: 'salmon' }, { text: 'Konfirm', value: 'grant', color: 'green' },
    { text: 'Tolak', value: 'reject', color: 'red' }, { text: 'Cancel', value: 'cancel', color: 'cyan' },
]

const surveyStatusToString = (t) => {
    const f = surveyStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const surveyStatusToColor = (t) => {
    const f = surveyStatus.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const deliveryStatus = [
    { text: 'Baru', value: 'new', color: 'blue' }, { text: 'Konfirm', value: 'confirmed', color: 'salmon' },
    { text: 'Sending', value: 'sending', color: 'cyan' }, { text: 'Selesai', value: 'done', color: 'green' },
    { text: 'Batal', value: 'cancel', color: 'red' },
]

const deliveryStatusToString = (t) => {
    const f = deliveryStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const deliveryStatusToColor = (t) => {
    const f = deliveryStatus.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const stnkStatus = [
    { text: 'Baru', value: 'new', color: 'blue' }, { text: 'Samsat', value: 'samsat', color: 'salmon' },
    { text: 'Jadi', value: 'finish', color: 'cyan' }, { text: 'Selesai', value: 'done', color: 'green' },
]

const stnkStatusToString = (t) => {
    const f = stnkStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const stnkStatusToColor = (t) => {
    const f = stnkStatus.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const paymentType = [
    { text: 'Tanda jadi', value: 'tandajadi', color: 'blue' }, { text: 'Pelunasan', value: 'pelunasan', color: 'green' },
    { text: 'Pelanggaran', value: 'violation', color: 'cyan' }, { text: 'Progresif', value: 'progressive', color: 'salmon' },
]

const paymentTypeToString = (t) => {
    const f = paymentType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const paymentTypeToColor = (t) => {
    const f = paymentType.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const spkNoteType = [
    { text: 'Prospek', value: 'prospek', color: 'blue' }, { text: 'SPK', value: 'spk', color: 'green' },
    { text: 'Surveyor', value: 'surveyor', color: 'cyan' },
]

const spkNoteTypeToString = (t) => {
    const f = spkNoteType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const spkNoteTypeToColor = (t) => {
    const f = spkNoteType.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const customerType = [
    { text: 'Individu', value: 'individu', color: 'blue' }, { text: 'Perusahaan', value: 'company', color: 'green' },
]

const customerTypeToString = (t) => {
    const f = customerType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const customerTypeToColor = (t) => {
    const f = customerType.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custBuyType = [
    { text: 'Reguler', value: 'reguler', color: 'blue' }, { text: 'Kolektif', value: 'kolektif', color: 'green' },
    { text: 'Joint Promo', value: 'jointpromo', color: 'cyan' },
]

const custBuyTypeToString = (t) => {
    const f = custBuyType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custBuyTypeToColor = (t) => {
    const f = custBuyType.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custCountry = [
    { text: 'WNI', value: 'wni', color: 'blue' }, { text: 'WNA', value: 'wna', color: 'green' },
]

const custCountryToString = (t) => {
    const f = custCountry.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custCountryToColor = (t) => {
    const f = custCountry.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custGender = [
    { text: 'Laki-laki', value: 'L', color: 'blue' }, { text: 'Perempuan', value: 'P', color: 'green' },
]

const custGenderToString = (t) => {
    const f = custGender.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custGenderToColor = (t) => {
    const f = custGender.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custHomeStatus = [
    { text: 'Rumah sendiri', value: 'own', color: 'blue' }, { text: 'Rumah Orang Tua / Keluarga', value: 'family', color: 'green' },
    { text: 'Rumah sewa', value: 'rent', color: 'cyan' },
]

const custHomeStatusToString = (t) => {
    const f = custHomeStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custHomeStatusToColor = (t) => {
    const f = custHomeStatus.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custEducation = [
    { text: 'Tidak tamat SD', value: 'nosd', color: 'blue' }, { text: 'SD', value: 'sd', color: 'green' },
    { text: 'SLTP / SMP', value: 'sltp', color: 'cyan' }, { text: 'SLTA / SMA', value: 'slta', color: 'amber' },
    { text: 'Diploma', value: 'diploma', color: 'salmon' }, { text: 'Sarjana', value: 'university', color: 'teal' },
    { text: 'Pasca sarjana', value: 'pasca', color: 'olive' },
]

const custEducationToString = (t) => {
    const f = custEducation.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custEducationToColor = (t) => {
    const f = custEducation.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custPhoneType = [
    { text: 'Pra Bayar', value: 'pra', color: 'blue' }, { text: 'Pasca Bayar', value: 'pasca', color: 'green' },
]

const custPhoneTypeToString = (t) => {
    const f = custPhoneType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custPhoneTypeToColor = (t) => {
    const f = custPhoneType.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custMotorBrand = [
    { text: 'Honda', value: 'honda', color: 'red' }, { text: 'Yamaha', value: 'yamaha', color: 'blue' },
    { text: 'Suzuki', value: 'suzuki', color: 'yellow' }, { text: 'Kawasaki', value: 'kawasaki', color: 'green' },
    { text: 'Lainnya', value: 'lainnya', color: 'salmon' }, { text: 'Belum pernah', value: 'belum', color: 'lime' },
]

const custMotorBrandToString = (t) => {
    const f = custMotorBrand.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custMotorBrandToColor = (t) => {
    const f = custMotorBrand.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custMotorType = [
    { text: 'Sport', value: 'sport', color: 'blue' }, { text: 'CUB (Bebek)', value: 'cub', color: 'green' },
    { text: 'Skuter / Matic', value: 'matic', color: 'salmon' }, { text: 'Belum pernah', value: 'never', color: 'red' },
]

const custMotorTypeToString = (t) => {
    const f = custMotorType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custMotorTypeToColor = (t) => {
    const f = custMotorType.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custPurpose = [
    { text: 'Berdagang', value: 'dagang', color: 'blue' }, { text: 'Pemakaian jarak dekat', value: 'jarakdekat', color: 'green' },
    { text: 'Ke sekolah / kampus', value: 'sekolah', color: 'yellow' }, { text: 'Rekreasi / Olah raga', value: 'rekreasi', color: 'salmon' },
    { text: 'Kebutuhan keluarga', value: 'kebutuhan', color: 'lime' }, { text: 'Bekerja', value: 'kerja', color: 'green' },
    { text: 'Lainnya', value: 'lainnya', color: 'green' },
]

const custPurposeToString = (t) => {
    const f = custPurpose.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custPurposeToColor = (t) => {
    const f = custPurpose.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custUser = [
    { text: 'Saya sendiri', value: 'sendiri', color: 'blue' }, { text: 'Anak', value: 'anak', color: 'green' },
    { text: 'Pasangan (suami/istri)', value: 'pasangan', color: 'salmon' }, { text: 'Lainnya', value: 'lainnya', color: 'lime' },
]

const custUserToString = (t) => {
    const f = custUser.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custUserToColor = (t) => {
    const f = custUser.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const custReligion = [
    { text: 'Islam', value: 'islam', color: 'blue' }, { text: 'Kristen', value: 'kristen', color: 'green' },
    { text: 'Katolik', value: 'katolik', color: 'green' }, { text: 'Hindu', value: 'hindu', color: 'green' },
    { text: 'Budha', value: 'budha', color: 'green' }, { text: 'Lainnya', value: 'lainnya', color: 'green' },
]

const custReligionToString = (t) => {
    const f = custReligion.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const custReligionToColor = (t) => {
    const f = custReligion.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const paymentOnline = [{ text: 'Offline', value: 'offline', color: 'blue' }, { text: 'Xendit', value: 'xendit', color: 'green' }]
const paymentOnlineToString = (t) => {
    const f = paymentOnline.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}
const paymentOnlineToColor = (t) => {
    const f = paymentOnline.find(v => v.value === t) || { color: 'Unknown' }
    return f.color;
}

const paymentStatus = [{ text: 'Menunggu', value: 'wait', color: 'olive' }, { text: 'Sukses', value: 'success', color: 'green' },
{ text: 'Gagal', value: 'failed', colod: 'red' }]
const paymentStatusToString = (t) => {
    const f = paymentStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}
const paymentStatusToColor = (t) => {
    const f = paymentStatus.find(v => v.value === t) || { color: 'Unknown' }
    return f.color;
}

const complainStatus = [{ text: 'Tidak', value: 'none', color: 'green' }, { text: 'KOMPLAIN!!', value: 'complain', color: 'red' },
    //{ text: 'Selesai', value: 'done', colod: 'blue' }
]
const complainStatusToString = (t) => {
    const f = complainStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}
const complainStatusToColor = (t) => {
    const f = complainStatus.find(v => v.value === t) || { color: 'Unknown' }
    return f.color;
}

const biroStatus = [
    { text: 'Baru', value: 'new', color: 'blue' }, { text: 'Lunas', value: 'paid', color: 'green' }
]

const biroStatusToString = (t) => {
    const f = biroStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const biroStatusToColor = (t) => {
    const f = biroStatus.find(v => v.value === t) || { color: 'white' }
    return f.color;
}
const stockStatus = [
    { text: 'Baru', value: 'none', color: 'blue' }, { text: 'Problem', value: 'issue', color: 'red' }, { text: 'Clear', value: 'clear', color: 'green' }
]

const stockStatusToString = (t) => {
    const f = stockStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const stockStatusToColor = (t) => {
    const f = stockStatus.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

const paymentOnlineType = [
    { text: 'Offline', value: 'offline', color: 'blue' }, { text: 'Xendit', value: 'xendit', color: 'green' },
]

const paymentOnlineTypeToString = (t) => {
    const f = paymentOnlineType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const paymentOnlineTypeToColor = (t) => {
    const f = paymentOnlineType.find(v => v.value === t) || { color: 'white' }
    return f.color;
}

export {
    filterOp, getFilterOp,
    userTypeToString, userTypeToColor,
    prospekStatus, prospekStatusToString, prospekStatusToColor,
    spkStatus, spkStatusToString, spkStatusToColor,
    surveyStatus, surveyStatusToString, surveyStatusToColor,
    deliveryStatus, deliveryStatusToString, deliveryStatusToColor,
    stnkStatus, stnkStatusToString, stnkStatusToColor,
    paymentType, paymentTypeToString, paymentTypeToColor,
    spkNoteType, spkNoteTypeToString, spkNoteTypeToColor,
    customerType, customerTypeToString, customerTypeToColor,
    custBuyType, custBuyTypeToString, custBuyTypeToColor,
    custCountry, custCountryToString, custCountryToColor,
    custGender, custGenderToString, custGenderToColor,
    custHomeStatus, custHomeStatusToString, custHomeStatusToColor,
    custEducation, custEducationToString, custEducationToColor,
    custPhoneType, custPhoneTypeToString, custPhoneTypeToColor,
    custMotorBrand, custMotorBrandToString, custMotorBrandToColor,
    custMotorType, custMotorTypeToString, custMotorTypeToColor,
    custPurpose, custPurposeToString, custPurposeToColor,
    custUser, custUserToString, custUserToColor,
    custReligion, custReligionToString, custReligionToColor,
    paymentOnline, paymentOnlineToString, paymentOnlineToColor,
    paymentStatus, paymentStatusToString, paymentStatusToColor,
    complainStatus, complainStatusToString, complainStatusToColor,
    biroStatus, biroStatusToColor, biroStatusToString,
    stockStatus, stockStatusToColor, stockStatusToString,
    paymentOnlineType, paymentOnlineTypeToString, paymentOnlineTypeToColor,
}