import React from 'react';
import { Modal, DatePicker } from 'antd';

const ExportDateDialog = props => {

    const [state, setState] = React.useState({ start: null, end: null })

    const onOk = () => {
        props.onExport(state.start.format('YYYY-MM-DD'), state.end.format('YYYY-MM-DD'))
    }

    const dateStartChanged = (v) => {
        setState({ ...state, start: v })
    }

    const dateEndChanged = (v) => {
        setState({ ...state, end: v })
    }

    return <Modal title="Export Excel" visible={props.visible} onCancel={props.onCancel} onOk={onOk}>
        <span>Dari :</span><DatePicker format="DD MMM YYYY" onChange={dateStartChanged} style={{ marginLeft: 10 }} />
        <span style={{ marginLeft: 10 }}>Sampai :</span><DatePicker format="DD MMM YYYY" onChange={dateEndChanged} style={{ marginLeft: 10 }} />
    </Modal>
}

export default ExportDateDialog;