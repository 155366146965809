import React, { Fragment } from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Select, Radio, DatePicker, Input } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop, replace } from '../../component/link';
import { InputText, InputTextArea } from '../../component/input';
import { custBuyType, custReligion, custHomeStatus, custEducation, custMotorBrand, custMotorType, custPurpose, custUser } from '../../constant'
import { LabelHeader } from '../../component/header';
import MySelect from '../../component/select';
import moment from 'moment';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.path !== '/customer/:id/add';
    const [state, setState] = React.useState({
        loading: edit, saveLoading: false,
        customerType: undefined, pos: undefined, mobile: '', jobHasChild: false,
    });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    values = { ...values, buy_date: values.buy_date.toISOString(), birth_date: values.birth_date.toISOString() }
                    if (edit) {
                        await Fetch.put(`/customer/${props.match.params.id}`, values);
                        message.info('Customer berhasil diedit');
                    } else {
                        await Fetch.post('/customer', { ...values, spk_id: state.data.spk_id, delivery_id: state.data.id });
                        message.info('Customer berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/customer');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        fetchData();
    }, [edit]);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        if (edit) {
            const data = await Fetch.get(`/customer/${props.match.params.id}`);
            setState({
                ...state, loading: false, data: data, customerType: data.type,
                pos: data.customer_address_id > 0 ? 'other' : 'ktp', mobile: data.mobile_phone, jobHasChild: data.customer_job2_id > 0,
                job: data.customer_job_id,
            });
            setTimeout(() => setFieldsValue({
                type: data.type, buy_date: moment(data.buy_date), buy_type: data.buy_type, identity_number: data.identity_number,
                country: data.country, kk_number: data.kk_number, owner_name: data.owner_name, gender: data.gender,
                birth_date: moment(data.birth_date),
                address: data.address, kelurahan: data.kelurahan, kecamatan: data.kecamatan, city: data.city,
                province: data.province, poscode: data.poscode, rt: data.rt, rw: data.rw,
                religion: data.religion, email: data.email, facebook: data.facebook, twitter: data.twitter,
                instagram: data.instagram, youtube: data.youtube, hobby: data.hobby,
                home_status: data.home_status,
                nothing: data.customer_address_id > 0 ? 'other' : 'ktp', other: data.customer_address,
                home_phone: data.home_phone, mobile_phone: data.mobile_phone, mobile_type: data.mobile_type,
                send_info: data.send_info,
                customer_job_id: data.customer_job_id, customer_expense_id: data.customer_expense_id,
                customer_job2_id: data.customer_job2_id,
                customer_education: data.customer_education,
                motor_brands: data.motor_brands, motor_types: data.motor_types, motor_purposes: data.motor_purposes,
                motor_users: data.motor_users,
                dealer_code: data.dealer_code, ro_bd_id: data.ro_bd_id, reference_id: data.reference_id,
                note: data.note,
            }), 1);
        } else {
            const any = await Fetch.get(`/customer?filter=(delivery_id,,eq,,${props.match.params.id})`)
            if (any.total > 0) {
                replace(props, `/customer/${any.data[0].id}/edit`)
            } else {
                const data = await Fetch.get(`/delivery/${props.match.params.id}`);
                setState({ ...state, loading: false, data: data });
                setFieldsValue({
                    buy_date: new moment(data.created_at),
                });
            }
        }
    }

    const customerTypeChange = v => {
        setState({ ...state, customerType: v.target.value })
        if (!edit) {
            setTimeout(() => setFieldsValue({
                owner_name: state.data.spk.stnk_name,
                address: state.data.spk.stnk_address,
                email: state.data.spk.email_address,
            }), 1);
        }
    }

    return (
        <Card title={<Title title={edit ? "Edit customer" : "Tambah customer"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_customer" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tipe Customer">
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Radio.Group onChange={customerTypeChange}>
                                    <Radio value="individu">Individu</Radio>
                                    <Radio value="company">Perusahaan</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Tanggal Pembelian">
                            {getFieldDecorator('buy_date', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker placeholder="Pilih tanggal" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {state.customerType && <Fragment>
                    <Divider />
                    {state.customerType === 'individu' && < Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Jenis Pembelian">
                                {getFieldDecorator('buy_type', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Select placeholder="Pilih jenis pembelian">
                                        {custBuyType.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="No. KTP / KITAS">
                                {getFieldDecorator('identity_number', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan no KTP / KITAS" />
                                )}
                            </Form.Item>
                            <Form.Item label="Kewarganegaraan">
                                {getFieldDecorator('country', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Radio.Group>
                                        <Radio value="wni">WNI</Radio>
                                        <Radio value="wna">WNA</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item label="No. KK">
                                {getFieldDecorator('kk_number', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan no KK" />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Nama pemilik">
                                {getFieldDecorator('owner_name', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan nama sesuai KTP" />
                                )}
                            </Form.Item>
                            <Form.Item label="Jenis Kelamin">
                                {getFieldDecorator('gender', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Radio.Group>
                                        <Radio value="L">Laki-laki</Radio>
                                        <Radio value="P">Perempuan</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item label="Tanggal Lahir">
                                {getFieldDecorator('birth_date', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <DatePicker placeholder="Pilih tanggal" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>}
                    {state.customerType === 'company' &&
                        < Row gutter={32}>
                            <Col span={12}>
                                <Form.Item label="No. TDP">
                                    {getFieldDecorator('identity_number', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan no TDP" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Tanggal NPWP">
                                    {getFieldDecorator('birth_date', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <DatePicker placeholder="Pilih tanggal" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Telepon kantor">
                                    {getFieldDecorator('home_phone', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan no telp kantor" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    <Divider />
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label={state.customerType === 'individu' ? "Alamat sesuai KTP" : "Alamat sesuai TDP"}>
                                {getFieldDecorator('address', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputTextArea placeholder="Masukkan alamat" />
                                )}
                            </Form.Item>
                            <Form.Item label={state.customerType === 'individu' ? "RT" : "RT sesuai TDP"}>
                                {getFieldDecorator('rt', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan rt" />
                                )}
                            </Form.Item>
                            <Form.Item label={state.customerType === 'individu' ? "RW" : "RW sesuai TDP"}>
                                {getFieldDecorator('rw', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan rw" />
                                )}
                            </Form.Item>
                            <Form.Item label={state.customerType === 'individu' ? "Kelurahan" : "Kelurahan sesuai TDP"}>
                                {getFieldDecorator('kelurahan', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan kelurahan" />
                                )}
                            </Form.Item>
                            <Form.Item label={state.customerType === 'individu' ? "Kecamatan" : "Kecamatan sesuai TDP"}>
                                {getFieldDecorator('kecamatan', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan kecamatan" />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={state.customerType === 'individu' ? "Kota / Kabupaten" : "Kabupaten sesuai TDP"}>
                                {getFieldDecorator('city', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan kota / kabupaten" />
                                )}
                            </Form.Item>
                            <Form.Item label={state.customerType === 'individu' ? "Provinsi" : "Provinsi sesuai TDP"}>
                                {getFieldDecorator('province', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan province" />
                                )}
                            </Form.Item>
                            <Form.Item label="Kode POS">
                                {getFieldDecorator('poscode')(
                                    <InputText placeholder="Masukkan kode pos" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {state.customerType === 'company' && <Fragment>
                        <Divider />
                        <Row gutter={32}>
                            <Col span={12}>
                                <Form.Item label="Nama penanggung jawab">
                                    {getFieldDecorator('owner_name', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan nama penganggung jawab" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Email">
                                    {getFieldDecorator('email')(
                                        <Input placeholder="Masukkan email" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Telepon HP">
                                    {getFieldDecorator('mobile_phone')(
                                        <InputText placeholder="Masukkan no telp HP" onChange={v => setState({ ...state, mobile: v })} />
                                    )}
                                </Form.Item>
                                {state.mobile !== '' && <Form.Item label="Type No HP">
                                    {getFieldDecorator('mobile_type', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Radio.Group>
                                            <Radio value="pra">Prabayar</Radio>
                                            <Radio value="pasca">Pasca Bayar</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>}
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Facebook">
                                    {getFieldDecorator('facebook')(
                                        <InputText placeholder="Masukkan facebook" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Twitter">
                                    {getFieldDecorator('twitter')(
                                        <InputText placeholder="Masukkan twitter" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Instagram">
                                    {getFieldDecorator('instagram')(
                                        <InputText placeholder="Masukkan instagram" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Youtube (@gmail.com)">
                                    {getFieldDecorator('youtube')(
                                        <InputText placeholder="Masukkan youtube" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Fragment>
                    }
                    {state.customerType === 'individu' && <Fragment>
                        <Divider />
                        <Row gutter={32}>
                            <Col span={12}>
                                <Form.Item label="Agama">
                                    {getFieldDecorator('religion', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Select placeholder="Pilih agama">
                                            {custReligion.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Alamat email">
                                    {getFieldDecorator('email')(
                                        <Input placeholder="Masukkan alamat email" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Facebook">
                                    {getFieldDecorator('facebook')(
                                        <InputText placeholder="Masukkan facebook" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Twitter">
                                    {getFieldDecorator('twitter')(
                                        <InputText placeholder="Masukkan twitter" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Instagram">
                                    {getFieldDecorator('instagram')(
                                        <InputText placeholder="Masukkan instagram" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Youtube (@gmail.com)">
                                    {getFieldDecorator('youtube')(
                                        <InputText placeholder="Masukkan youtube" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hobi">
                                    {getFieldDecorator('hobby')(
                                        <InputText placeholder="Masukkan hobi" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row></Fragment>}
                    <LabelHeader>Alamat surat menyurat / tinggal saat ini</LabelHeader>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Alamat tinggal">
                                {getFieldDecorator('nothing', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Radio.Group onChange={v => setState({ ...state, pos: v.target.value })}>
                                        <Radio value="ktp">{state.customerType === 'individu' ? "Sesuai KTP" : "Sesuai TDP"}</Radio>
                                        <Radio value="other">Alamat lainnya</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {state.customerType === 'individu' && <Form.Item label="Status rumah">
                                {getFieldDecorator('home_status', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Select placeholder="Pilih status rumah">
                                        {custHomeStatus.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>}
                        </Col>
                    </Row>
                    {state.pos && state.pos === 'other' &&
                        <Row gutter={32}>
                            <Col span={12}>
                                <Form.Item label="Alamat tinggal">
                                    {getFieldDecorator('other.address', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputTextArea placeholder="Masukkan alamat sesuai KTP" />
                                    )}
                                </Form.Item>
                                <Form.Item label="RT">
                                    {getFieldDecorator('other.rt', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan rt" />
                                    )}
                                </Form.Item>
                                <Form.Item label="RW">
                                    {getFieldDecorator('other.rw', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan rw" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Kelurahan">
                                    {getFieldDecorator('other.kelurahan', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan kelurahan" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Kecamatan">
                                    {getFieldDecorator('other.kecamatan', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan kecamatan" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Kota / Kabupaten">
                                    {getFieldDecorator('other.city', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan kota / kabupaten" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Provinsi">
                                    {getFieldDecorator('other.province', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan province" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Kode POS">
                                    {getFieldDecorator('other.poscode', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan kode pos" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    {state.customerType === 'individu' && <Fragment>
                        <Divider />
                        <Row gutter={32}>
                            <Col span={12}>
                                <Form.Item label="Perkerjaan">
                                    {getFieldDecorator('customer_job_id', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <MySelect url="/customerjob?filter=(customer_job.parent_id,,eq,,0)" valueIndex="id" dataIndex="name" placeholder="Pilih pekerjaan"
                                            onChangeAdv={v => {
                                                setState({ ...state, jobHasChild: v.has_child, job: v.id })
                                            }} />
                                    )}
                                </Form.Item>
                                {state.jobHasChild && <Form.Item label="Bidang">
                                    {getFieldDecorator('customer_job2_id', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <MySelect url={`/customerjob?filter=(customer_job.parent_id,,eq,,${state.job})`} valueIndex="id" dataIndex="name" placeholder="Pilih bidang" />
                                    )}
                                </Form.Item>}
                                <Form.Item label="Pengeluaran 1 bulan">
                                    {getFieldDecorator('customer_expense_id', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <MySelect url="/customerexpense" valueIndex="id" dataIndex="name" placeholder="Pilih pengeluaran" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Pendidikan terakhir">
                                    {getFieldDecorator('customer_education', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Select placeholder="Pilih pendidikan terakhir">
                                            {custEducation.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Telepon rumah">
                                    {getFieldDecorator('home_phone')(
                                        <InputText placeholder="Masukkan no telp rumah" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Telepon HP">
                                    {getFieldDecorator('mobile_phone')(
                                        <InputText placeholder="Masukkan no telp HP" onChange={v => setState({ ...state, mobile: v })} />
                                    )}
                                </Form.Item>
                                {state.mobile !== '' && <Form.Item label="Type No HP">
                                    {getFieldDecorator('mobile_type', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Radio.Group>
                                            <Radio value="pra">Prabayar</Radio>
                                            <Radio value="pasca">Pasca Bayar</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>}
                                <Form.Item label="Terima info Honda?">
                                    {getFieldDecorator('send_info', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Radio.Group>
                                            <Radio value={true}>Iya</Radio>
                                            <Radio value={false}>Tidak</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Motor sebelumnya">
                                    {getFieldDecorator('motor_brands', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Select placeholder="Pilih motor yang pernah dimiliki" mode="multiple">
                                            {custMotorBrand.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Tipe motor">
                                    {getFieldDecorator('motor_types', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Select placeholder="Pilih motor yang pernah dimiliki" mode="multiple">
                                            {custMotorType.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Kegunaan motor">
                                    {getFieldDecorator('motor_purposes', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Select placeholder="Pilih kegunaan motor" mode="multiple">
                                            {custPurpose.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Pengguna motor">
                                    {getFieldDecorator('motor_users', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Select placeholder="Pilih yang menggunakan motor" mode="multiple">
                                            {custUser.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Fragment>}
                    <Divider />
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Kode Dealer">
                                {getFieldDecorator('dealer_code', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan kode dealer" />
                                )}
                            </Form.Item>
                            <Form.Item label="RO BD ID">
                                {getFieldDecorator('ro_bd_id', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan RO BD ID" />
                                )}
                            </Form.Item>
                            <Form.Item label="Referensi ID">
                                {getFieldDecorator('reference_id', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputText placeholder="Masukkan reference ID" />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Catatan">
                                {getFieldDecorator('note', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputTextArea placeholder="Masukkan Catatan" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Fragment>}
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/customer')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card >
    );
}

export default Form.create({ name: 'create_customer' })(Add);