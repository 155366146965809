import React from 'react';
import { Card, Table, Button, message, Statistic, Row, Col, Divider, Typography } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch'
import { TextField } from '../../component/field'
import Permission, { P_MONEY_SETOR } from '../../permission'

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="check" onClick={props.cashClicked}>Buat setoran tunai</Button>
            <Button icon="reload" onClick={props.refresh} />
        </Button.Group>
    );
}

const List = props => {
    const [, tb] = useTable('/spkpayment', {
        filter: { 'payment_position_status': 'done', 'save_bank_id': 0, 'bank.cash': 'true', 'payment_type': 'offline' },
        order: { key: 'created_at', order: 'descend' }
    })
    const [state, setState] = React.useState({ data: [], loading: true, selectedRowKeys: [], saveLoading: false, refresh: true, create: false, total_selected: 0 })
    const ref = React.useRef({})

    React.useEffect(() => {
        if (state.refresh)
            Fetch.get(`/spkpayment/totalcash`).then(v => setState({
                ...state, loading: false, total: v.cash, refresh: false
            }));
    }, [state.refresh])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/spk/${record.spk_id}?tab=payment`, '_blank')
            }
        }
    }

    const rowSelection = {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            for (let i = 0; i < selectedRows.length; i++) {
                if (!ref.current.hasOwnProperty(selectedRows[i].id)) {
                    ref.current[selectedRows[i].id] = selectedRows[i].value;
                }
            }
            let total = 0
            for (let i = 0; i < selectedRowKeys.length; i++)
                if (ref.current.hasOwnProperty(selectedRowKeys[i]))
                    total += ref.current[selectedRowKeys[i]];

            setState({ ...state, selectedRowKeys: selectedRowKeys, total_selected: total })
        },
    };

    const cashClicked = () => {
        setState({ ...state, create: true })
    }

    const processCash = async () => {
        if (state.total_selected === 0) {
            message.error("Total tidak boleh 0")
            return
        }
        setState({ ...state, saveLoading: true })
        try {
            await Fetch.post('/savebank', { total: state.total_selected, monies: state.selectedRowKeys })
            message.info("Berhasil membuat setoran")
            tb.fetchData()
            setState({ ...state, refresh: true, create: false, selectedRowKeys: [] })
        } catch (e) {
            message.error(e)
            setState({ ...state, saveLoading: false })
        }
    }

    return <React.Fragment>
        {
            Permission.hasPermission(P_MONEY_SETOR) &&
            <Card title={<Title title={"Daftar cash"} actions={<Actions  {...props} refresh={tb.fetchData} cashClicked={cashClicked} />} />}>
                <Row>
                    <Col md={12}>
                        <div style={{ display: 'flex' }}>
                            <Statistic title="Total" value={state.total} decimalSeparator="," groupSeparator="." />
                        </div>
                    </Col>
                    <Col md={12}>
                        {state.create &&
                            <Card title="Buat setoran baru">
                                <TextField label="Total terpilih" labelSpan={10} valueSpan={14}>{formatMoney(state.total_selected)}</TextField>
                                <Divider />
                                <Button type="primary" onClick={processCash} loading={state.saveLoading}>Proses</Button>
                            </Card>
                        }
                    </Col>
                </Row><br />
                <Table {...tb.tableProps} onRow={onRow} rowSelection={state.create ? rowSelection : undefined}>
                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                        {...tb.columnProps({ key: 'spk_payment.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                    <Table.Column key="number" dataIndex="number" title="Nomor" />
                    <Table.Column key="spknumber" dataIndex="spk.number" title="No SPK"  {...tb.columnProps({ key: 'name', hint: 'Cari no SPK' })}
                        render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>} />
                    <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama SPK"  {...tb.columnProps({ key: 'name', hint: 'Cari nama SPK' })}
                        {...tb.sortProps('spk.buyer_name')} />
                    <Table.Column key="value" dataIndex="value" title="Jumlah" align="right" render={formatMoney} />
                    <Table.Column key="chasis" dataIndex="stock.chasis_number" title="No rangka"  {...tb.columnProps({ key: 'stock.chasis_number', hint: 'Cari no rangka' })} />
                    <Table.Column key="machine" dataIndex="stock.machine_number" title="No mesin"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                </Table>
            </Card >
        }
        {
            !Permission.hasPermission(P_MONEY_SETOR) && <Typography.Title>Anda tidak memiliki akses</Typography.Title>
        }
    </React.Fragment>
}

export default List;