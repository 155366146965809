import React, { Fragment } from 'react';
import { Card, Row, Col, Button, Table, Divider } from 'antd';
import Title from '../../component/title';
import { withRouter } from 'react-router-dom';
import { useDetail } from '../../component/detail'
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link';
import { formatDate, formatMoney } from '../../component/util'
import moment from 'moment'

const Act = props => {
    return (
        <Button.Group>
            <Button icon="reload" onClick={props.reload} />
        </Button.Group>
    );
}

const Action = withRouter(Act);

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/claim/intensiveleasing?filter=(date,,eq,,${props.match.params.date}),(id,,eq,,${props.match.params.id})`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const date = moment(props.match.params.date, "YYYY-MM")

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/subsidileasing/${record.id}/edit`, '_blank')
            }
        }
    }

    return (
        <Card loading={loading} title={<Title title={"Detail insentif leasing"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Detail intensive lesing" url="subsidileasing" />} />} >
            {!loading &&
                <Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Bulan">{date.format('MMM - YYYY')}</TextField>
                            <TextField {...col} label="Nama leasing">{record.leasing.name}</TextField>
                            <TextField {...col} label="Total">{formatMoney(record.leasing.total)}</TextField>
                        </Col>
                    </Row>
                    <Divider />
                    <Table dataSource={record.data} pagination={false} rowKey='id' scroll={{ x: true }} size='small' bordered onRow={onRow}>
                        <Table.Column key="unit_date" dataIndex="unit_date" title="Tanggal Unit" render={formatDate} />
                        <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin" />
                        <Table.Column key="motor_name" dataIndex="motor.name" title="Motor" />
                        <Table.Column key="color_name" dataIndex="color.name" title="Warna" />
                        <Table.Column key="stnk_name" dataIndex="spk.stnk_name" title="Nama STNK" render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} />
                        <Table.Column key="intensive_leasing" dataIndex="intensive_leasing" align="right" title="Insentif" render={formatMoney} />
                    </Table>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;