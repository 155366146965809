import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, message, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch';
import { GlobalStore } from '../../store/store';
import { push } from '../../component/link';

const Actions = props => {
    const [, reducer] = React.useContext(GlobalStore);
    const url = `/deliveryprint/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`;

    const authorize = async (token) => {
        try {
            await Fetch.post(`/delivery/confirm/${props.record.id}`, null, token)
            props.reload();
        } catch (err) {
            message.error('Error: ' + err)
        }
    }

    const onConfirm = async () => {
        reducer({
            type: 'OPEN_AUTHORIZE', payload: true, callback: async (token) => {
                try {
                    await Fetch.post(`/delivery/cancel/${props.record.id}`, null, token)
                    props.reload();
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        })
    }

    return (
        <Button.Group>
            {props.record && props.record.status !== 'new' && props.record.status !== 'cancel' &&
                <Button icon="printer" href={Fetch.getUrl(url)} target="_blank">Print</Button>}
            {props.record && props.record.status === 'new' &&
                <Button icon="check" onClick={() => reducer({ type: 'OPEN_AUTHORIZE', payload: true, callback: authorize })} >Confirm</Button>}
            {props.record && props.record.status !== 'cancel' &&
                <Popconfirm placement="bottomRight" title={"DO yang sudah di cancel tidak dapat dikembalikan lagi. Yakin cancel DO?"} onConfirm={onConfirm} okText="Iya" cancelText="Tidak">
                    <Button type="danger" icon="exclamation-circle" >Cancel DO</Button>
                </Popconfirm>}
            <Button icon="team" href={`/customer/${props.record.id}/add`} onClick={(e) => {
                e.preventDefault();
                push(props, `/customer/${props.record.id}/add`);
            }}>Customer</Button>
            <Button icon="reload" onClick={props.reload} />
        </Button.Group>
    );
}

Actions.propTypes = {
    dataId: PropTypes.number.isRequired,
    reload: PropTypes.func,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    showPrint: PropTypes.bool,
}

export default withRouter(Actions);