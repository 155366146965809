import React from 'react'
import { TextField } from '../../component/field'
import Fetch from '../../Fetch';
import { formatDate } from '../../component/util'
import { Row, Col, Tag, Divider, Typography } from 'antd';

const Ship = props => {
    const [data, setData] = React.useState(null);
    const record = props.record;

    React.useEffect(() => {
        if (record.driver_id > 0)
            Fetch.get(`/motorsent?filter=(ref_id,,eq,,${record.id})(ref_id,,eq,,${record.id})`).then(v => {
                const d = v.data;
                if (d.length === 1) {
                    setData(d[0])
                }
            })
    }, []);

    const label = (label, value) => {
        return <div style={{ paddingTop: 8, paddingBottom: 8 }}>
            <Row >
                <Col md={10} ><Typography.Text style={{ color: '#808080' }}>{label}</Typography.Text></Col>
                <Col md={14} ><Tag color={value ? 'green' : 'red'}>{value ? 'Iya' : 'Tidak'}</Tag></Col>
            </Row>
        </div>
    }

    if (data === null) return 'Belum ada data pengiriman'

    return <React.Fragment>
        {(record.status === 'sending' || record.status === 'done') &&
            <div>
                <Row>
                    <Col md={12}>
                        <TextField label="Tanggal kirim">{formatDate(data.created_at)}</TextField>
                        <TextField label="Driver">{data.driver ? data.driver.name : "-"}</TextField>
                        <TextField label="Status"><Tag color={data.status === 'done' ? 'green' : 'blue'}>{data.status}</Tag></TextField>
                    </Col>
                    <Col md={12}>
                        <TextField label="Terkirim">{formatDate(data.deliverd_time)}</TextField>
                        <TextField label="Lokasi kirim"><Tag onClick={() => {
                            window.open(`https://maps.google.com/?q=${data.lat},${data.lg}`, '_blank')
                        }}>{data.lat + ' , ' + data.lg}</Tag></TextField>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col md={12}>
                        <Typography.Text>Toolkit</Typography.Text>
                        <Divider />
                        {label('Toolkit', data.tool_toolkit)}
                        {label('Helm', data.tool_helm)}
                        {label('Gantungan kunci', data.tool_gantungan)}
                        {label('Spion', data.tool_spion)}
                        {label('Buku pedoman', data.tool_pedoman)}
                        {label('Buku servis', data.tool_servis)}
                        {label('Kartu apresiasi', data.tool_apresiasi)}
                    </Col>
                    <Col md={12}>
                        <Typography.Text>Checklist penjelasan</Typography.Text>
                        <Divider />
                        {label('Fitur motor', data.checklist_feature)}
                        {label('Body', data.checklist_body)}
                        {label('Garansi', data.checklist_garansi)}
                        {label('Ahass', data.checklist_ahass)}
                        {label('Jadwal gratis', data.checklist_jadwal)}
                        {label('Servis berkala', data.checklist_berkala)}
                        {label('Pedoman', data.checklist_pedoman)}
                        {label('Diskon servis prima', data.checklist_diskon)}
                    </Col>
                </Row>
            </div>
        }
    </React.Fragment>

}

export default Ship;