import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Tag, Divider, Button } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field'
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch';
import { LabelHeader } from '../../component/header';
import { MyLink } from '../../component/link';
import {
    paymentTypeToString, paymentTypeToColor,
    paymentOnlineTypeToString, paymentOnlineTypeToColor,
    paymentStatusToColor, paymentStatusToString,
} from '../../constant'

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="printer" href={Fetch.getUrl(`/moneycloseprint/${props.id}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" >Print</Button>
        </Button.Group>
    );
}

const Detail = props => {
    const [{ record, loading }] = useDetail(`/moneyclose/${props.match.params.id}`);
    const [state, setState] = React.useState({ data: [], loading: true, summary: [] })
    const col = { labelSpan: 8, valueSpan: 16 }

    React.useEffect(() => {
        if (record.id)
            Fetch.get(`/spkpayment?filter=(money_close_id,,eq,,${record.id})&order=created_at DESC&limit=10000`).then(v => {
                let summary = []
                let total = 0;
                for (let i = 0; i < v.data.length; i++) {
                    const val = v.data[i];
                    const bankId = val.payment_type === 'offline' ? val.bank_id : 1000000;
                    const index = summary.findIndex(v1 => v1.id === bankId)
                    if (index < 0) {
                        summary.push({ id: bankId, total: val.value, name: val.bank ? val.bank.name : '-' })
                    } else {
                        summary[index].total += val.value
                    }
                    total += val.value
                }
                summary.push({ id: 1000001, name: 'Total', total: total })
                setState({ ...state, loading: false, data: v.data, summary: summary });
            })
    }, [record])

    return (
        <Card loading={loading} title={<Title title={"Detail closing"} actions={<Actions id={record.id} />} />} >
            {!loading &&
                <Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Tanggal">{formatDate(record.created_at)}</TextField>
                            <TextField {...col} label="Nomor">{record.number}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Pembuat">{record.user.name}</TextField>
                            <TextField {...col} label="Total">{formatMoney(record.total)}</TextField>
                        </Col>
                    </Row>
                    <Divider />
                    <LabelHeader>Summary</LabelHeader>
                    <Row>
                        <Col md={12}>
                            <Table dataSource={state.summary} pagination={false} rowKey="id">
                                <Table.Column key="name" dataIndex="name" title="Nama" render={r => r === 'Total' ? <b>Total</b> : r} />
                                <Table.Column key="total" dataIndex="total" title="Total" align="right" render={formatMoney} />
                            </Table>
                        </Col>
                    </Row>
                    <LabelHeader>Pembayaran</LabelHeader>
                    <Table loading={state.loading} dataSource={state.data} rowKey="id" pagination={false} scroll={{ x: true }}>
                        <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate} />
                        <Table.Column key="number" dataIndex="number" title="Nomor" />
                        <Table.Column key="spknumber" dataIndex="spk.number" title="No SPK" render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>} />
                        <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama SPK" />
                        <Table.Column key="tipe" dataIndex="type" title="Tipe" render={t => <Tag color={paymentTypeToColor(t)}>{paymentTypeToString(t)}</Tag>} />
                        <Table.Column key="payment_type" dataIndex="payment_type" title="Tipe" render={t => <Tag color={paymentOnlineTypeToColor(t)}>{paymentOnlineTypeToString(t)}</Tag>} />
                        <Table.Column key="payment_status" dataIndex="payment_status" title="Status" render={t => <Tag color={paymentStatusToColor(t)}>{paymentStatusToString(t)}</Tag>} />
                        <Table.Column key="value" dataIndex="value" title="Jumlah" align="right" render={formatMoney} />
                        <Table.Column key="bank" dataIndex="bank.name" title="Bank" render={(t, r) => r.payment_type === 'xendit' ? '-' : t === undefined ? 'Cash' : t} />
                        <Table.Column key="card_number" dataIndex="card_number" title="No transaksi" />
                        <Table.Column key="chasis" dataIndex="stock.chasis_number" title="No rangka" />
                        <Table.Column key="machine" dataIndex="stock.machine_number" title="No mesin" />
                    </Table>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;