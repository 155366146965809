import React from 'react';
import { Card, Row, Col, Tag } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/stocklocation/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }

    return (
        <Card loading={loading} title={<Title title={"Detail master lokasi stock"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Master lokasi stock" url="stocklocation" />} />} >
            {!loading && <Row gutter={16}>
                <Col span={12}>
                    <TextField {...col} label="Nama">{record.name}</TextField>
                    <TextField {...col} label="Telepon">{record.phone}</TextField>
                    <TextField {...col} label="Dealer">{<Tag color="purple">{record.is_home ? 'Iya' : 'Tidak'}</Tag>}</TextField>
                    <TextField {...col} label="Alamat">{record.address}</TextField>
                    <TextField {...col} label="Catatan">{record.note}</TextField>
                </Col>
                <Col span={12}>
                </Col>
            </Row>}
        </Card >
    );
}

export default Detail;