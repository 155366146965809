import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Checkbox } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { InputText, InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/stocklocation/${props.match.params.id}`, values);
                        message.info('Master lokasi berhasil diedit');
                    } else {
                        await Fetch.post('/stocklocation', values);
                        message.info('Master lokasi berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/stocklocation');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/stocklocation/${props.match.params.id}`);
        setState({ ...state, loading: false });
        setFieldsValue({
            name: data.name,
            phone: data.phone,
            address: data.address,
            note: data.note,
            is_dealer: data.is_dealer,
        });
    }

    return (
        <Card title={<Title title={edit ? "Edit master lokasi stock" : "Tambah  master lokasi stock"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_stocklocation" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama lokasi">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama lokasi" />
                            )}
                        </Form.Item>
                        <Form.Item label="Telepon">
                            {getFieldDecorator('phone', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan telepon" type="phone" />
                            )}
                        </Form.Item>
                        <Form.Item label="Dealer">
                            {getFieldDecorator('is_home', { valuePropName: 'checked' })(
                                <Checkbox>Dealer</Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item label="Alamat">
                            {getFieldDecorator('address')(
                                <InputTextArea autosize placeholder="Masukkan alamat" />
                            )}
                        </Form.Item>
                        <Form.Item label="Catatan">
                            {getFieldDecorator('note')(
                                <InputTextArea autosize placeholder="Masukkan note" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/stocklocation')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_stocklocation' })(Add);