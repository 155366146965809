import React from 'react'
import { Card, Statistic, Row, Col, Divider, Tabs, Typography } from 'antd';
import Fetch from '../../Fetch';
import Chart from './chart';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import SalePerLeasing from './cart_sale_per_leasing';
import SharePerLeasing from './cart_share_per_leasing';
import CashCreditByTeam from './cart_cash_credit_per_team';
import CashPerTeam from './cart_cash_per_team'
import CreditPerTeam from './cart_credit_per_team'

const PageDashboard = (props) => {
    const [state, setState] = React.useState({ loading: true, data: {} })

    React.useEffect(() => {
        Fetch.get('/dashboard').then(v => {
            setState({ ...state, loading: false, data: v })
        })
    }, [])

    return (
        <Card title="Dashboard" loading={state.loading}>
            <Row gutter={8}>
                <Col span={6}>
                    <Statistic title="Prospek" value={state.data.prospek} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col span={6}>
                    <Statistic title="SPK" value={state.data.spk} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col span={6}>
                    <Statistic title="DO" value={state.data.delivery} decimalSeparator="," groupSeparator="." />
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={6}>
                    <Statistic title="Pending KTP" value={state.data.ktp} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col span={6}>
                    <Statistic title="Pending PO" value={state.data.po} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col span={6}>
                    <Statistic title="Pending Pembayaran" value={state.data.pending} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col span={6}>
                    <Statistic title="Pending Unit" value={state.data.unit} decimalSeparator="," groupSeparator="." />
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={6}>
                    <Statistic title="Nilai Stock" value={state.data.stock_value} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col span={6}>
                    <Statistic title="Pending Pembayaran" value={state.data.pending_payment} decimalSeparator="," groupSeparator="." />
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={24}>
                    <Typography.Text style={{ fontSize: 16 }}>Cash Kredit Share</Typography.Text>
                    <div style={{ height: 30 }} />
                    <ResponsiveContainer width='100%' height={250}>
                        <BarChart data={state.data.cashcredit}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="cash" fill="#8884d8" />
                            <Bar dataKey="credit" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={24}>
                    <Typography.Text style={{ fontSize: 16 }}>Sale per leasing</Typography.Text>
                    <div style={{ height: 30 }} />
                    <SalePerLeasing record={state.data.sale_per_leasing} />
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={24}>
                    <Typography.Text style={{ fontSize: 16 }}>Share per leasing</Typography.Text>
                    <div style={{ height: 30 }} />
                    <SharePerLeasing record={state.data.sale_per_leasing} />
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={24}>
                    <Typography.Text style={{ fontSize: 16 }}>Cash Credit per Team</Typography.Text>
                    <div style={{ height: 30 }} />
                    <CashCreditByTeam record={state.data.sale_cash_credit_per_team} />
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={24}>
                    <Typography.Text style={{ fontSize: 16 }}>Cash share per Team</Typography.Text>
                    <div style={{ height: 30 }} />
                    <CashPerTeam record={state.data.sale_cash_credit_per_team} />
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={24}>
                    <Typography.Text style={{ fontSize: 16 }}>Credit share per Team</Typography.Text>
                    <div style={{ height: 30 }} />
                    <CreditPerTeam record={state.data.sale_cash_credit_per_team} />
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={12}>
                    <Typography.Text style={{ fontSize: 16 }}>Prospek</Typography.Text>
                    <div style={{ height: 30 }} />
                    <Tabs tabPosition="bottom">
                        <Tabs.TabPane tab="Harian" key="1">
                            <Chart data={state.data.chart_prospek === undefined ? [] : state.data.chart_prospek.daily} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Mingguan" key="2">
                            <Chart data={state.data.chart_prospek === undefined ? [] : state.data.chart_prospek.weekly} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Bulanan" key="3">
                            <Chart data={state.data.chart_prospek === undefined ? [] : state.data.chart_prospek.monthly} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
                <Col span={12}>
                    <Typography.Text style={{ fontSize: 16 }}>SPK</Typography.Text>
                    <div style={{ height: 30 }} />
                    <Tabs tabPosition="bottom">
                        <Tabs.TabPane tab="Harian" key="1">
                            <Chart data={state.data.chart_spk === undefined ? [] : state.data.chart_spk.daily} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Mingguan" key="2">
                            <Chart data={state.data.chart_spk === undefined ? [] : state.data.chart_spk.weekly} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Bulanan" key="3">
                            <Chart data={state.data.chart_spk === undefined ? [] : state.data.chart_spk.monthly} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={12}>
                    <Typography.Text style={{ fontSize: 16 }}>DO</Typography.Text>
                    <div style={{ height: 30 }} />
                    <Tabs tabPosition="bottom">
                        <Tabs.TabPane tab="Harian" key="1">
                            <Chart data={state.data.chart_delivery === undefined ? [] : state.data.chart_delivery.daily} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Mingguan" key="2">
                            <Chart data={state.data.chart_delivery === undefined ? [] : state.data.chart_delivery.weekly} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Bulanan" key="3">
                            <Chart data={state.data.chart_delivery === undefined ? [] : state.data.chart_delivery.monthly} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
                <Col span={12}>
                </Col>
            </Row>
        </Card>
    );
}

export default PageDashboard;