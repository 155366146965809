import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Drawer, Statistic, Divider } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import Exporter from './export';
import { formatDate } from '../../component/util'
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [, tb] = useTable('/stock?order=stock.created_at desc', { filter: { on_stock: true } })
    const [state, setState] = React.useState({ drawerExportVisible: false, total: 0 })

    React.useEffect(() => {
        Fetch.get('/stock/value').then(r => {
            setState({ ...state, total: r.total });
        })
    }, [])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/stock/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/stock/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/stock/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Nilai Stock"} actions={<Actions  {...props} refresh={tb.fetchData} state={state} setState={setState} />} />}>
            <Statistic title="Nilai Stock" value={state.total} decimalSeparator="," groupSeparator="." />
            <Divider />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'stock.created_at', type: "startend" })} />
                <Table.Column key="sj_date" dataIndex="sj_date" title="Tanggal SJ" render={formatDate} />
                <Table.Column key="sj_number" dataIndex="sj_number" title="No SJ" />
                <Table.Column key="purchase.number" dataIndex="purchase.number" title="No SO" />
                <Table.Column key="purchase.date" dataIndex="purchase.date" title="Tanggal SO" render={formatDate} />
                <Table.Column key="year" dataIndex="year" title="Tahun"  {...tb.columnProps({ key: 'year', hint: 'Cari tahun' })} />
                <Table.Column key="motor.name" dataIndex="motor.name" title="Jenis SMH"  {...tb.columnProps({ key: 'motor.name', hint: 'Cari nama motor' })} />
                <Table.Column key="motor.code" dataIndex="motor.code" title="Tipe" />
                <Table.Column key="color.name" dataIndex="color.name" title="Warna" />
                <Table.Column key="machine_number" dataIndex="machine_number" title="No Mesin"  {...tb.columnProps({ key: 'machine_number', hint: 'Cari no mesin' })} />
                <Table.Column key="chasis_number" dataIndex="chasis_number" title="No Rangka"  {...tb.columnProps({ key: 'chasis_number', hint: 'Cari no rangka' })} />
                <Table.Column key="sender" dataIndex="sender" title="Pengirim"  {...tb.columnProps({ key: 'sender', hint: 'Cari pengirim' })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
            <Drawer visible={state.drawerExportVisible} title="Export stock" width={350} onClose={() => setState({ ...state, drawerExportVisible: false })}>
                <Exporter />
            </Drawer>
        </Card >
    )
}

export default List;