import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Drawer, Upload, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import Typography from 'antd/lib/typography/Typography';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/masterprice/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/masterprice/add`);
                }}>Tambah</Button>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="upload" onClick={props.importClick}>Import</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/masterprice', {}, { key: 't_' })
    const [state, setState] = React.useState({ modalVisible: false })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/masterprice/${record.id}`, '_blank')
            }
        }
    }

    const onChange = ({ file }) => {
        if (file.response) {
            if (file.status === "error") {
                message.error('Error : ' + file.response.error)
            } else {
                message.info("Import master harga berhasil")
                setState(s => { return { ...s, modalVisible: false } })
                tb.fetchData()
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/masterprice/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/masterprice/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            {/*<Menu.Item>
                <Popconfirm placement="left" title={`Yakin menghapus ${record.motor.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>*/}
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Master Harga"} actions={<Actions  {...props} refresh={tb.fetchData}
            exportClick={() => {
                const url = tb.genFilterUrl(`/masterprice/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
                window.open(Fetch.getUrl(url))
            }}
            importClick={() => {
                setState(s => { return { ...s, modalVisible: true } })
            }}
        />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="motor.name" dataIndex="motor.name" title="Motor" {...tb.columnProps({ key: 'motor.name', hint: 'Cari motor' })}
                    {...tb.sortProps('motor.name')} />
                <Table.Column key="color_id" dataIndex="color_id" title="Warna" render={(t, r) => t === 0 ? 'Semua' : r.color.name} />
                <Table.Column key="buy_price" dataIndex="buy_price" title="Harga Beli" render={t => t.toLocaleString('in-ID')} align="right" />
                <Table.Column key="net_buy_price" dataIndex="buy_price" title="Net Pembelian" render={t => t.toLocaleString('in-ID')} align="right" />
                <Table.Column key="buy_price1" dataIndex="buy_price" title="PPN Pembelian" render={(t, r) => parseInt(r.buy_price * 11 / 100, 10).toLocaleString('in-ID')} align="right" />
                <Table.Column key="buy_price2" dataIndex="buy_price" title="Harga Pool" render={(t, r) => parseInt(r.buy_price + (r.buy_price * 11 / 100), 10).toLocaleString('in-ID')} align="right" />
                <Table.Column key="bbn" dataIndex="bbn" title="BBN" render={t => t.toLocaleString('in-ID')} align="right" />
                <Table.Column key="bbn_ppn" dataIndex="bbn_ppn" title="BBN PPN" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                <Table.Column key="bbn_pph" dataIndex="bbn_pph" title="BBN PPh" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                <Table.ColumnGroup title="Tangerang">
                    <Table.Column key="sell_price" dataIndex="sell_price" title="Harga Penjualan" render={t => t.toLocaleString('in-ID')} align="right" />
                    <Table.Column key="sell_price1" dataIndex="sell_price" title="PPN Penjualan" render={(t, r) => parseInt(r.sell_price * 11 / 100, 10).toLocaleString('in-ID')} align="right" />
                    <Table.Column key="sell_price2" dataIndex="sell_price" title="Harga Jual + PPN" render={(t, r) => parseInt(r.sell_price + (r.sell_price * 11 / 100), 10).toLocaleString('in-ID')} align="right" />
                    <Table.Column key="otr" dataIndex="otr" title="OTR" render={t => t.toLocaleString('in-ID')} align="right" />
                </Table.ColumnGroup>
                <Table.ColumnGroup title="Jakarta">
                    <Table.Column key="sell_price_jkt" dataIndex="sell_price_jkt" title="Harga Penjualan " render={t => t.toLocaleString('in-ID')} align="right" />
                    <Table.Column key="sell_price1_jkt" dataIndex="sell_price_jkt" title="PPN Penjualan " render={(t, r) => parseInt(r.sell_price_jkt * 11 / 100, 10).toLocaleString('in-ID')} align="right" />
                    <Table.Column key="sell_price2_jkt" dataIndex="sell_price_jkt" title="Harga Jual + PPN" render={(t, r) => parseInt(r.sell_price_jkt + (r.sell_price_jkt * 11 / 100), 10).toLocaleString('in-ID')} align="right" />
                    <Table.Column key="otr_jkt" dataIndex="otr_jkt" title="OTR" render={t => t.toLocaleString('in-ID')} align="right" />
                </Table.ColumnGroup>
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
            <Drawer visible={state.modalVisible} onClose={() => { setState(s => { return { ...s, modalVisible: false } }) }} title="Import master harga" width={400}>
                <Typography>Untuk mendapatkan format import, silakan lakukan export terlebih dahulu. Gunakan file hasil export tersebut sebagai template</Typography><br />
                <Upload.Dragger multiple={false} name="file" action={Fetch.getUrl('/masterprice/import')} accept=".xlsx"
                    headers={{ 'authorization': `Bearer ${localStorage.getItem('token')}` }} onChange={onChange}
                    showUploadList={false}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Klik atau drop file disini</p>
                </Upload.Dragger>
            </Drawer>
        </Card >
    )
}

export default List;