import React from 'react';
import Fetch from '../../Fetch';
import { List, Typography } from 'antd';

const History = props => {
    const [state, setState] = React.useState({ loading: true, data: [] })

    React.useEffect(() => {
        Fetch.get(`/spkhistory?order=created_at asc&filter=(spk_id,,eq,,${props.spkid})`).then(v => {
            setState({ ...state, loading: false, data: v.data })
        })
    }, [])

    return (
        <div>
            <List dataSource={state.data} pagination={false} renderItem={item =>
                <List.Item key={item.id}>
                    <div>
                        <Typography.Text strong>{item.user.name}</Typography.Text>
                        <Typography.Text disabled style={{ fontSize: 12, display: 'block' }}>{(new Date(item.created_at)).toLocaleString('in-ID')}</Typography.Text>
                        <Typography.Text>{item.data}</Typography.Text>
                    </div>
                </List.Item>
            } />
        </div>
    )
}

export default History;