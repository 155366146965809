import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CreditPerTeam = props => {
    const colors = ['#98FB98', '#8B4513', '#008000', '#FF1493', '#FFA500', '#F08080', '#D2691E', '#6B8E23', '#FF6347', '#DC143C', '#E9967A', '#FFB6C1', '#1E90FF']
    const desc = props.record[0].data;
    const data = props.record.map(v => {
        const dd = { 'name': v.name }
        let total = 0;
        for (let i = 0; i < v.data.length; i++) {
            total += v.data[i].credit;
        }
        for (let i = 0; i < v.data.length; i++) {
            dd[v.data[i].team] = total > 0 ? parseInt(v.data[i].credit * 100 / total) : 0;
        }
        return dd;
    })
    let cur = 0
    return <ResponsiveContainer width='100%' height={250}>
        <BarChart data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {desc.map(v => {
                if (cur >= colors.length) cur = 0;
                return <Bar key={v.team} dataKey={v.team} fill={colors[cur++]} />
            })}
        </BarChart>
    </ResponsiveContainer>
}

export default CreditPerTeam;