import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Statistic, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { formatDate, formatMoney, getDefaultCurMonth, filterDate } from '../../component/util'
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/sale', { filter: { 'sale.created_at': getDefaultCurMonth() }, order: { key: 'created_at', order: 'descend' } },
        {
            key: 't_', formatter: (d) => {
                for (let i = 0; i < d.length; i++) {
                    const a = d[i]
                    a.buy_ppn = parseInt(a.buy_price * 0.1)
                    a.buy_pool = a.buy_price + a.buy_ppn
                    a.sell_ppn = parseInt(a.sell_price * 0.1)
                    a.sell_pool = a.sell_price + a.sell_ppn
                    a.ppn_diff = a.sell_ppn - a.buy_ppn
                    a.gross_margin_unit = a.sell_pool - a.buy_pool
                    a.gross_margin_no_pph = parseInt(a.gross_margin_unit / 1.025)
                    a.net_margin_unit = a.gross_margin_no_pph - a.ppn_diff
                    a.subsidi_leasing_no_pph = parseInt(a.subsidi_leasing / 1.025)
                    a.subsidi_leasing_net = parseInt(a.subsidi_leasing_no_pph / 1.1)
                    a.intensive_leasing_gross = parseInt(a.intensive_leasing_pph ? (a.intensive_leasing * 1.025) : a.intensive_leasing) || 0
                    a.intensive_leasing_net = parseInt(a.intensive_leasing / 1.1) || 0
                    a.subsidi_wms_gross = parseInt(a.subsidi_wms_pph ? (a.subsidi_wms * 1.025) : a.subsidi_wms) || 0
                    a.subsidi_wms_net = parseInt(a.subsidi_wms / 1.1) || 0
                    a.subsidi_ahm_gross = parseInt(a.subsidi_ahm_pph ? (a.subsidi_ahm * 1.025) : a.subsidi_ahm) || 0
                    a.subsidi_ahm_net = parseInt(a.subsidi_ahm / 1.1) || 0
                    a.bbn_no_pph = parseInt(a.bbn / 1.025)
                    a.gross_bbn = a.bbn - a.bbn_no_pph
                    a.net_bbn = parseInt(a.gross_bbn / 1.1)
                    a.total_pendapatan_unit = a.net_margin_unit + a.net_bbn +
                        a.subsidi_leasing_net + a.intensive_leasing_net + a.subsidi_wms_net + a.subsidi_ahm_net
                    a.pendapatan_unit = a.total_pendapatan_unit - a.discount
                    d[i] = a
                }
                return d;
            }
        })
    const [state, setState] = React.useState({ total: 0, cash: 0, credit: 0 })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/sale/${record.id}/edit`, '_blank')
            }
        }
    }

    React.useEffect(() => {
        if (filter['sale.created_at']) {
            const f = filter['sale.created_at'].split(' ');
            Fetch.get(`/sale/total?from=${f[0]}T00:00:00%2B07&to=${f[1]}T23:59:59%2B07`).then(v => setState({ ...state, ...v }));
        }
    }, [filter['sale.created_at']])

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/sale/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Penjualan"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            let err = filterDate(filter, 'sale.created_at')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/sale/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <div style={{ display: 'flex' }}>
                <Statistic title="Margin" value={state.total} decimalSeparator="," groupSeparator="." />
            </div><br />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'sale.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                <Table.Column key="motor.name" dataIndex="motor.name" title="Tipe SMH"  {...tb.columnProps({ key: 'motor.name', hint: 'Cari motor' })}
                    {...tb.sortProps('motor.name')} />
                <Table.Column key="stock.machine_number" dataIndex="stock.machine_number" title="SMH Terjual"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                <Table.ColumnGroup title="Pendapatan Unit">
                    <Table.Column key="buy_price" dataIndex="buy_price" title="Harga beli" align="right" render={formatMoney} />
                    <Table.Column key="buy_ppn" dataIndex="buy_ppn" title="PPN Beli" align="right" render={formatMoney} />
                    <Table.Column key="buy_pool" dataIndex="buy_pool" title="Harga Beli Pool" align="right" render={formatMoney} />
                    <Table.Column key="sell_price" dataIndex="sell_price" title="Harga Jual" align="right" render={formatMoney} />
                    <Table.Column key="sell_ppn" dataIndex="sell_ppn" title="PPN Jual" align="right" render={formatMoney} />
                    <Table.Column key="sell_pool" dataIndex="sell_pool" title="Harga Jual Pool" align="right" render={formatMoney} />
                    <Table.Column key="ppn_diff" dataIndex="ppn_diff" title="Selisih PPN" align="right" render={formatMoney} />
                    <Table.Column key="gross_margin_unit" dataIndex="gross_margin_unit" title="Gross Margin Unit" align="right" render={formatMoney} />
                    <Table.Column key="gross_margin_no_pph" dataIndex="gross_margin_no_pph" title="Gross Margin Unit - PPH" align="right" render={formatMoney} />
                    <Table.Column key="net_margin_unit" dataIndex="net_margin_unit" title="Net Margin Unit" align="right" render={formatMoney} />
                </Table.ColumnGroup>
                <Table.ColumnGroup title="Pendapatan BBN">
                    <Table.Column key="bbn" dataIndex="bbn" title="BBN Wahana" align="right" render={formatMoney} />
                    <Table.Column key="bbn_no_pph" dataIndex="bbn_no_pph" title="BBN Wahana - PPH" align="right" render={formatMoney} />
                    <Table.Column key="stnk_price" dataIndex="stnk_price" title="Biaya Biro Jasa" align="right" render={formatMoney} />
                    <Table.Column key="gross_bbn" dataIndex="gross_bbn" title="Gross Pendapatan BBN" align="right" render={formatMoney} />
                    <Table.Column key="net_bbn" dataIndex="net_bbn" title="Net Pendapatan BBN" align="right" render={formatMoney} />
                </Table.ColumnGroup>
                <Table.ColumnGroup title="Subsidi Leasing">
                    <Table.Column key="subsidi_leasing" dataIndex="subsidi_leasing" title="Gross Subsidi Leasing" align="right" render={formatMoney} />
                    <Table.Column key="subsidi_leasing_no_pph" dataIndex="subsidi_leasing_no_pph" title="Pencairan Subsidi Leasing" align="right" render={formatMoney} />
                    <Table.Column key="subsidi_leasing_net" dataIndex="subsidi_leasing_net" title="Net Subsidi Leasing" align="right" render={formatMoney} />
                </Table.ColumnGroup>
                <Table.ColumnGroup title="Insentif Leasing">
                    <Table.Column key="intensive_leasing_gross" dataIndex="intensive_leasing_gross" title="Gross Insentif Leasing" align="right" render={formatMoney} />
                    <Table.Column key="intensive_leasing" dataIndex="intensive_leasing" title="Pencairan Insentif Leasing" align="right" render={formatMoney} />
                    <Table.Column key="intensive_leasing_net" dataIndex="intensive_leasing_net" title="Net Insentif Leasing" align="right" render={formatMoney} />
                </Table.ColumnGroup>
                <Table.ColumnGroup title="Insentif Subsidi WMS">
                    <Table.Column key="subsidi_wms_gross" dataIndex="subsidi_wms_gross" title="Gross Subsidi WMS" align="right" render={formatMoney} />
                    <Table.Column key="subsidi_wms" dataIndex="subsidi_wms" title="Pencairan Subsidi WMS" align="right" render={formatMoney} />
                    <Table.Column key="subsidi_wms_net" dataIndex="subsidi_wms_net" title="Net Subsidi WMS" align="right" render={formatMoney} />
                </Table.ColumnGroup>
                <Table.ColumnGroup title="Insentif Subsidi AHM">
                    <Table.Column key="subsidi_ahm_gross" dataIndex="subsidi_ahm_gross" title="Gross Subsidi AHM" align="right" render={formatMoney} />
                    <Table.Column key="subsidi_ahm" dataIndex="subsidi_ahm" title="Pencairan Subsidi AHM" align="right" render={formatMoney} />
                    <Table.Column key="subsidi_ahm_net" dataIndex="subsidi_ahm_net" title="Net Subsidi AHM" align="right" render={formatMoney} />
                </Table.ColumnGroup>
                <Table.ColumnGroup title="Pendapatan Unit">
                    <Table.Column key="total_pendapatan_unit" dataIndex="total_pendapatan_unit" title="Total Pendapatan Unit" align="right" render={formatMoney} />
                    <Table.Column key="discount" dataIndex="discount" title="Diskon" align="right" render={formatMoney} />
                    <Table.Column key="pendapatan_unit" dataIndex="pendapatan_unit" title="Pendapatan Unit" align="right" render={formatMoney} />
                </Table.ColumnGroup>
                <Table.ColumnGroup title="Pendingan DP">
                    <Table.Column key="do" dataIndex="delivery.number" title="No DO" />
                    <Table.Column key="do_date" dataIndex="delivery.created_at" title="Tanggal DO" render={formatDate} />
                    <Table.Column key="leasing" dataIndex="leasing.name" title="Leasing" render={(t, r) => r.spk.cash ? 'CASH' : t} />
                    <Table.Column key="kwitansi_number" dataIndex="kwitansi_number" title="No Kwitansi" />
                    <Table.Column key="dp" dataIndex="dp" title="DP" align="right" render={(t, r) => r.spk.cash ? '-' : formatMoney(t)} />
                    <Table.Column key="dp_date" dataIndex="dp_date" title="Tanggal DP" render={(t, r) => r.spk.cash ? '-' : formatDate(t)} />
                    <Table.Column key="otr_residual" dataIndex="spk.payment_residual" title="Sisa OTR" align="right" render={formatMoney} />
                    <Table.Column key="payment_date" dataIndex="payment_date" title="Tanggal Pelunasan" render={(t, r) => r.spk.cash ? formatDate(t) : '-'} />
                </Table.ColumnGroup>
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;