import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Row, Col, Statistic } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="container" href={`/subsidiahminvoice`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/subsidiahminvoice`);
                }}>Tagihan</Button>
                <Button icon="dollar" href={`/subsidiahmreceipt`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/subsidiahmreceipt`);
                }}>Pembayaran</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/claim?subsidiahm=true', { order: { key: 'created_at', order: 'descend' }, filter: { 'claim.faktur_date::notnull': '' } })
    const [state, setState] = React.useState({ loading: true, data: {} })
    const url = tb.genFilterUrl(`/claim/summaryahm`, filter)

    React.useEffect(() => {
        Fetch.get(url).then(v => {
            setState(s => { return { ...s, loading: false, data: { ...v, diff: v.invoice - v.paid }, refresh: false } })
        })
    }, [url])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/claim/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/subsidiahm/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/subsidiahm/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Subsidi AHM"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            const url = tb.genFilterUrl(`/claim/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Row gutter={8}>
                <Col md={6}>
                    <Statistic title="Total subsidi" value={state.loading ? 'loading...' : state.data.total || 0} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col md={6}>
                    <Statistic title="Tertagih" value={state.loading ? 'loading...' : state.data.invoice || 0} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col md={6}>
                    <Statistic title="Terbayar" value={state.loading ? 'loading...' : state.data.paid || 0} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col md={6}>
                    <Statistic title="Selisih" value={state.loading ? 'loading...' : state.data.diff || 0} decimalSeparator="," groupSeparator="." />
                </Col>
            </Row><br />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="faktur_date" dataIndex="faktur_date" title="Tanggal Faktur" render={formatDate}
                    {...tb.columnProps({ key: 'claim.faktur_date', type: 'startend' })} {...tb.sortProps('created_at')} />
                <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })}
                    render={(t, r) => <MyLink to={`/stock/${r.stock_id}`}>{t}</MyLink>} />
                <Table.Column key="motor_name" dataIndex="motor.name" title="Motor"  {...tb.columnProps({ key: 'motor.name', hint: 'Cari motor' })} {...tb.sortProps('motor.name')} />
                <Table.Column key="color_name" dataIndex="color.name" title="Warna" />
                <Table.Column key="stnk_name" dataIndex="spk.stnk_name" title="Nama STNK"  {...tb.columnProps({ key: 'spk.stnk_name', hint: 'Cari nama stnk' })}
                    render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} {...tb.sortProps('spk.stnk_name')} />
                <Table.Column key="subsidi_ahm_invoice.number" dataIndex="subsidi_ahm_invoice.number" title="No Tagihan"
                    {...tb.columnProps({ key: 'subsidi_ahm_invoice.number', hint: 'Cari no tagihan' })}
                    render={(t, r) => <MyLink to={`/subsidiahminvoice/${r.subsidi_ahm_invoice_id}`}>{t}</MyLink>} />
                <Table.Column key="subsidi_ahm_receipt.number" dataIndex="subsidi_ahm_receipt.number" title="No Pembayaran"
                    {...tb.columnProps({ key: 'subsidi_ahm_receipt.number', hint: 'Cari no pembayaran' })}
                    render={(t, r) => <MyLink to={`/subsidiahmreceipt/${r.subsidi_ahm_receipt_id}`}>{t}</MyLink>} />
                <Table.Column key="subsidi_ahm" dataIndex="subsidi_ahm" align="right" title="Subsidi" render={formatMoney} />
                <Table.Column key="subsidi_ahm_paid" dataIndex="subsidi_ahm_paid" align="right" title="Terbayar" render={formatMoney} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;