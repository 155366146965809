import React from 'react';
import { Card, Row, Col } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/masterprice/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }

    const renderValue = v => <div style={{ width: '100%', textAlign: 'right', paddingRight: 50 }}>
        {v.toLocaleString('in-ID')}
    </div>

    return (
        <Card loading={loading} title={<Title title={"Detail master harga"}
            actions={<Action dataId={record.id || '1'} reload={fetchData} title="Master harga" url="masterprice" />} />} >
            {!loading && <Row gutter={16}>
                <Col span={12}>
                    <TextField {...col} label="Nama">{record.motor.name}</TextField>
                    <TextField {...col} label="Harga Beli">{renderValue(record.buy_price)}</TextField>
                    <TextField {...col} label="PPN Beli">{renderValue(parseInt(record.buy_price * 11 / 100))}</TextField>
                    <TextField {...col} label="PPN + Harga beli">{renderValue(parseInt((record.buy_price * 11 / 100) + record.buy_price))}</TextField>
                </Col>
                <Col span={12}>
                    <TextField {...col} label="Harga jual">{renderValue(record.sell_price)}</TextField>
                    <TextField {...col} label="PPN jual">{renderValue(parseInt(record.sell_price * 11 / 100))}</TextField>
                    <TextField {...col} label="PPN + Harga jual">{renderValue(parseInt((record.sell_price * 11 / 100) + record.sell_price))}</TextField>
                    <TextField {...col} label="BBN">{renderValue(record.bbn)}</TextField>
                    <TextField {...col} label="OTR">{renderValue(record.otr)}</TextField>
                </Col>
            </Row>}
        </Card >
    );
}

export default Detail;