import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import { prospekStatusToString, prospekStatusToColor, prospekStatus } from '../../constant'
import { formatDate, getDefaultCurMonth, filterDate } from '../../component/util'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/prospek/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/prospek/add`);
                }}>Tambah</Button>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/prospek', { filter: { 'spk.created_at': getDefaultCurMonth() }, order: { key: 'created_at', order: 'descend' } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/prospek/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/prospek/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/prospek/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Prospek"} actions={<Actions  {...props} refresh={tb.fetchData}
            exportClick={() => {
                let err = filterDate(filter, 'spk.created_at')
                if (err !== '') {
                    message.error(err)
                    return;
                }
                const url = tb.genFilterUrl(`/prospek/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
                window.open(Fetch.getUrl(url))
            }} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'spk.created_at', type: 'startend' })}  {...tb.sortProps('created_at')} />
                <Table.Column key="team" dataIndex="team" title="Team" render={t => t === null ? '' : <MyLink to={`/team/${t.id}`}>{t.name}</MyLink>}
                    {...tb.columnProps({ key: 'user_id', type: 'selecturl', hint: "Cari team", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="user" dataIndex="user" title="Marketing" render={t => <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>}
                    {...tb.columnProps({ key: 'user_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="prospek_number" dataIndex="prospek_number" title="Nomor" {...tb.columnProps({ key: 'prospek_number', hint: 'Cari nama motor' })} />
                <Table.Column key="buyer_name" dataIndex="buyer_name" title="Nama" {...tb.columnProps({ key: 'buyer_name', hint: 'Cari nama' })}
                    {...tb.sortProps('buyer_name')} />
                <Table.Column key="buyer_phone" dataIndex="buyer_phone" title="Telepon" {...tb.columnProps({ key: 'buyer_phone', hint: 'Cari telepon' })} />
                <Table.Column key="buyer_whatsapp" dataIndex="buyer_whatsapp" title="Whatsapp" {...tb.columnProps({ key: 'buyer_whatsapp', hint: 'Cari whatsapp' })} />
                <Table.Column key="prospek_status" dataIndex="prospek_status" title="Status" {...tb.columnProps({ key: 'prospek_status', type: 'radio', options: prospekStatus, number: false })}
                    render={t => <Tag color={prospekStatusToColor(t)}>{prospekStatusToString(t)}</Tag>} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;