import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Tabs } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import Fetch from '../../Fetch';
import { LabelHeader } from '../../component/header';
import { useTab } from '../../component/tab';
import ImageWidget from './picture';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/bpkbreceipt/${props.match.params.id}`);
    const [state, setState] = React.useState({ data: [], loading: true })
    const col = { labelSpan: 8, valueSpan: 16 }
    const tab = useTab(props, 'tab', 'info')

    React.useEffect(() => {
        if (record.id)
            Fetch.get(`/bpkb?filter=(bpkb_receipt_id,,eq,,${record.id})&order=created_at DESC&limit=1000`).then(v => {
                setState({ ...state, loading: false, data: v.data });
            })
    }, [record])
    return (
        <Card loading={loading} title={<Title title={"Detail tanda terima BPKB"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Tanda terima tagihan" url="claimreceipt" />} />} >
            {!loading &&
                <Fragment>
                    <Tabs {...tab} >
                        <Tabs.TabPane tab="Info" key="info">
                            <Fragment>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <TextField {...col} label="Tanggal">{formatDate(record.created_at)}</TextField>
                                        <TextField {...col} label="Nomor">{record.number}</TextField>
                                        <TextField {...col} label="Leasing">{record.leasing.name}</TextField>
                                        <TextField {...col} label="Pembuat">{record.user.name}</TextField>
                                    </Col>
                                    <Col span={12}>
                                        <TextField {...col} label="Tanggal Diterima">{formatDate(record.sent_date)}</TextField>
                                        <TextField {...col} label="Penyerah">{record.over ? record.over.name : '-'}</TextField>
                                        <TextField {...col} label="Catatan">{record.note}</TextField>
                                    </Col>
                                </Row>
                                <LabelHeader>BPKB</LabelHeader>
                                <Table loading={state.loading} dataSource={state.data} rowKey="id" pagination={false} scroll={{ x: true }}>
                                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal DO" render={formatDate} />
                                    <Table.Column key="number" dataIndex="number" title="No BPKB" />
                                    <Table.Column key="name" dataIndex="spk.stnk_name" title="Nama STNK" />
                                    <Table.Column key="marketing" dataIndex="marketing.name" title="Marketing" />
                                    <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin" />
                                    <Table.Column key="police_number" dataIndex="police_number" title="No Polisi" />
                                    <Table.Column key="leasing" dataIndex="leasing.name" title="Leasing" render={(r, s) => s.leasing === null ? 'CASH' : r} />
                                    <Table.Column key="recieved" dataIndex="recieved" title="Tanggal Jadi" render={formatDate} />
                                    <Table.Column key="sent_consument" dataIndex="sent_consument" title="Tanggal Konsumen" render={formatDate} />
                                    <Table.Column key="note" dataIndex="note" title="Catatan" />
                                </Table>
                            </Fragment>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Gambar" key="image">
                            <ImageWidget record={record} reload={() => fetchData()} />
                        </Tabs.TabPane>

                    </Tabs>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;