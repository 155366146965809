import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { formatDate, filterDate } from '../../component/util'
import { MyLink } from '../../component/link';
import Fetch from '../../Fetch';
import { customerTypeToString, customerTypeToColor } from '../../constant'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/customer', {})

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/customer/${id}`);
                message.info("Berhasil menghapus leasing");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus leasing: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/customer/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/customer/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/customer/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <a href="void()"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</a>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Customer"} actions={<Actions  {...props} refresh={tb.fetchData}
            exportClick={() => {
                let err = filterDate(filter, 'customer.buy_date')
                if (err !== '') {
                    message.error(err)
                    return;
                }
                const url = tb.genFilterUrl(`/customer/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
                window.open(Fetch.getUrl(url))
            }} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="buy_date" dataIndex="buy_date" title="Tanggal Beli" render={formatDate}
                    {...tb.columnProps({ key: 'customer.buy_date', type: 'startend' })} {...tb.sortProps('buy_date')} {...tb.sortProps('customer.buy_date')} />
                <Table.Column key="type" dataIndex="type" title="Tipe"
                    render={t => <Tag color={customerTypeToColor(t)}>{customerTypeToString(t)}</Tag>} />
                <Table.Column key="spk_name" dataIndex="owner_name" title="Nama"  {...tb.columnProps({ key: 'spk.buyer_name', hint: 'Cari nama spk' })}
                    render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>} {...tb.sortProps('owner_name')} />
                <Table.Column key="deliery" dataIndex="delivery.number" title="No DO"  {...tb.columnProps({ key: 'deliery.number', hint: 'Cari do' })}
                    render={(t, r) => <MyLink to={`/delivery/${r.delivery_id}`}>{t}</MyLink>} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;