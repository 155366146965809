import React from 'react';
import { Card, Row, Col, Tabs, Divider, Form, Button, message } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link'
import { formatDate } from '../../component/util'
import { InputTextArea } from '../../component/input';
import Fetch from '../../Fetch'

const St = props => {
    const [state, setState] = React.useState({ saveLoading: false })
    const col = { labelSpan: 8, valueSpan: 16 }
    const record = props.record;
    const { getFieldDecorator, setFieldsValue } = props.form;

    React.useEffect(() => {
        setFieldsValue({ note: props.record.note })
    }, [])

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.put(`/crm/${props.record.id}`, { [props.note]: values.note });
                    message.info('CRM berhasil diedit');
                    setState({ ...state, saveLoading: false })
                    props.refresh();
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    return <div>
        <Row gutter={16}>
            <Col span={12}>
                <TextField {...col} label="Status">{record.status}</TextField>
                <TextField {...col} label="Tanggal Respon">{formatDate(record.date)}</TextField>
                <Form layout="horizontal" name="edit" onSubmit={formSummited}>
                    <Form.Item label="Catatan">
                        {getFieldDecorator('note')(
                            <InputTextArea placeholder="Masukkan catatan" rows={4} />
                        )}
                    </Form.Item>
                    <Button type="primary" htmlType="submit">Simpan catatan</Button>
                </Form>
            </Col>
        </Row>
    </div>
}

const Status = Form.create({ name: 'edit' })(St);

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/crm/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }

    return (
        <Card loading={loading} title={<Title title={"Detail CRM"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="BPKB" url="bpkb" showDelete={false} />} />} >
            {!loading && record &&
                <div>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Tanggal DO">{record ? <MyLink to={`/delivery/${record.delivery_id}`}>{formatDate(record.created_at)}</MyLink> : ''}</TextField>
                            <TextField {...col} label="No SPK">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.number}</MyLink> : ''}</TextField>
                            <TextField {...col} label="Motor">{record ? record.motor ? record.motor.name : '' : ''}</TextField>
                            <TextField {...col} label="Warna">{record ? record.color ? record.color.name : '' : ''}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Telepon">{record ? record.spk.buyer_phone ? record.spk.buyer_phone : '' : ''}</TextField>
                            <TextField {...col} label="Whatsapp">{record ? record.spk.buyer_whatsapp ? record.spk.buyer_whatsapp : '' : ''}</TextField>
                            <TextField {...col} label="STNK">{record ? <MyLink to={`/stnk/${record.stnk_id}`}>
                                {record.stnk.plate_number ? record.stnk.plate_number : 'Belum jadi'}</MyLink> : ''}</TextField>
                            <TextField {...col} label="BPKB">{record ? <MyLink to={`/bpkb/${record.bpkb_id}`}>
                                {record.bpkb.number ? record.bpkb.number : 'Belum jadi'}</MyLink> : ''}</TextField>
                        </Col>
                    </Row>
                    <Divider />
                    <Tabs>
                        <Tabs.TabPane tab="7 Hari" key="1">
                            <Status record={{ status: record.show_7_done ? 'Selesai' : 'Open', date: record.show_7_done_date, id: record.id, note: record.show_7_note }}
                                note="show_7_note" refresh={fetchData} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="STNK" key="2">
                            {record.show_stnk ?
                                <Status record={{ status: record.show_stnk_done ? 'Selesai' : 'Open', date: record.show_stnk_done_date, id: record.id, note: record.show_stnk_note }}
                                    note="show_stnk_note" refresh={fetchData} /> :
                                <div>STNK belum jadi</div>
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="BPKB" key="3">
                            {record.show_bpkb ?
                                <Status record={{ status: record.show_bpkb_done ? 'Selesai' : 'Open', date: record.show_bpkb_done_date, id: record.id, note: record.show_bpkb_note }}
                                    note="show_bpkb_note" refresh={fetchData} /> :
                                <div>BPKB belum jadi</div>
                            }
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="180 Hari" key="4">
                            {record.show_180 ?
                                <Status record={{ status: record.show_180_done ? 'Selesai' : 'Open', date: record.show_180_done_date, id: record.id, note: record.show_180_note }}
                                    note="show_180_note" refresh={fetchData} /> :
                                <div>Penjualan belum 180 hari</div>
                            }
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            }
        </Card >
    );
}

export default Detail;