import React from 'react';
import { Card, Row, Col, Tag, Button } from 'antd';
import Title from '../../component/title';
import { withRouter } from 'react-router-dom';
import { useDetail } from '../../component/detail'
import { TextField } from '../../component/field'
import { push, MyLink } from '../../component/link';
import { formatDate, formatMoney } from '../../component/util'
import { LabelHeader } from '../../component/header';
import DetailShare from '../subsidi/detail'

const Act = props => {
    return (
        <Button.Group>
            <Button icon="edit" href={`/${props.url}/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/${props.url}/${props.dataId}/edit`);
            }}>Edit</Button>
            <Button icon="reload" onClick={props.reload} />
        </Button.Group>
    );
}

const Action = withRouter(Act);

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/claim/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }

    return (
        <Card loading={loading} title={<Title title={"Detail subsidi WMS"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Subsidi WMS" url="subsidiwms" />} />} >
            {!loading &&
                <DetailShare record={record}>
                    <LabelHeader>Info Subsidi WMS</LabelHeader>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Tanggal Faktur">{formatDate(record.faktur_date)}</TextField>
                            <TextField {...col} label="Subsidi">{formatMoney(record.subsidi_wms)}</TextField>
                            <TextField {...col} label="Include PPN">{<Tag color='blue'>{record.subsidi_wms_ppn ? 'Iya' : 'Tidak'}</Tag>}</TextField>
                            <TextField {...col} label="Include PPh">{<Tag color='blue'>{record.subsidi_wms_pph ? 'Iya' : 'Tidak'}</Tag>}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="No Tagihan">{record.subsidi_wms_invoice != null ?
                                <MyLink to={`/subsidiwmsinvoice/${record.subsidi_wms_invoice_id}`}> {record.subsidi_wms_invoice.number} </MyLink> : '-'}</TextField>
                            <TextField {...col} label="No Pembayaran">{record.subsidi_wms_receipt != null ?
                                <MyLink to={`/subsidiwmsreceipt/${record.subsidi_wms_receipt_id}`}> {record.subsidi_wms_receipt.number} </MyLink> : '-'}</TextField>
                        </Col>
                    </Row>
                </DetailShare>
            }
        </Card >
    );
}

export default Detail;