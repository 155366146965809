import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { spkStatusToString, spkStatusToColor, surveyStatusToString, surveyStatusToColor, spkStatus, surveyStatus } from '../../constant'
import { formatDate, filterDate } from '../../component/util'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/spk', { filter: { indent: 't', 'spk_status::not': 'do' }, order: { key: 'indent_date', order: 'descend' } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/spk/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/spk/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Indent"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            let err = filterDate(filter, 'spk.spk_date')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/spk/exportindent?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="spk_date" dataIndex="spk_date" title="Tanggal SPK" render={formatDate}
                    {...tb.columnProps({ key: 'spk.spk_date', type: 'startend' })} {...tb.sortProps('spk.spk_date')} />
                <Table.Column key="indent_date" dataIndex="indent_date" title="Tanggal indent" render={formatDate}
                    {...tb.columnProps({ key: 'spk.indent_date', type: 'startend' })} {...tb.sortProps('indent_date')} {...tb.sortProps('indent_date')} />
                <Table.Column key="number" dataIndex="number" title="No SPK" />
                <Table.Column key="buyer_name" dataIndex="buyer_name" title="Nama" {...tb.columnProps({ key: 'buyer_name', hint: 'Cari nama' })} {...tb.sortProps('buyer_name')} />
                <Table.Column key="buyer_phone" dataIndex="buyer_phone" title="Telepon" />
                <Table.Column key="user" dataIndex="user" title="Marketing" render={t => <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>}
                    {...tb.columnProps({ key: 'user_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="motor" dataIndex="motor.name" title="Motor" {...tb.sortProps('motor.name')} />
                <Table.Column key="color" dataIndex="color.name" title="Warna" />
                <Table.Column key="cash" dataIndex="cash" title="Cash" render={t => <Tag color={t ? 'blue' : 'green'}>{t ? 'Cash' : 'Kredit'}</Tag>} />
                <Table.Column key="spk_status" dataIndex="spk_status" title="Status SPK"
                    render={t => <Tag color={spkStatusToColor(t)}>{spkStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'spk_status', type: 'radio', options: spkStatus })} />
                <Table.Column key="surveyor_status" dataIndex="surveyor_status" title="Status Surveyor"
                    render={t => <Tag color={surveyStatusToColor(t)}>{surveyStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'survey_status', type: 'radio', options: surveyStatus })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;