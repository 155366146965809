import React from 'react';
import { Card, Row, Col, Table, Divider } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import useTable from '../../component/table';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/leasinggroup/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const [, tb] = useTable('/leasing', {}, undefined, true)

    React.useEffect(() => {
        if (record.id) tb.setFilter({ 'leasing_group_id': record.id, })
    }, [record])


    return (
        <Card loading={loading} title={<Title title={"Detail group leasing"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Leasing" url="leasinggroup" showDelete={false} />} />} >
            {!loading &&
                <React.Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Nama">{record.name}</TextField>
                        </Col>
                        <Col span={12}>
                        </Col>
                    </Row>
                    <Divider />
                    <Table {...tb.tableProps}>
                        <Table.Column key="name" dataIndex="name" title="Nama" />
                        <Table.Column key="fullname" dataIndex="fullname" title="Nama lengkap" />
                    </Table>
                </React.Fragment>
            }
        </Card >
    );
}

export default Detail;