import React from 'react';
import { Card, Form, Row, Col, Divider, Button, Input, message, Select, Modal } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import { InputText, InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, colors: [] });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/prospek/${props.match.params.id}`, values);
                        message.info('Prospek berhasil diedit');
                    } else {
                        const c = await Fetch.post('/prospek/check', { buyer_name: values.buyer_name, buyer_phone: values.buyer_phone })
                        if (!c.status) {
                            Modal.confirm({
                                title: 'Prospek sudah ada',
                                content: <p>Prospek dengan nama dan no telp sudah ada, klik <a href={`/prospek/${c.id}`} target="_blank" rel="noopener noreferrer">disini</a> untuk detail. Yakin untuk tetap menambah?</p>,
                                async onOk() {
                                    await Fetch.post('/prospek', { ...values, status: 'new', cash: values.cash === 'cash' });
                                    message.info('Prospek berhasil ditambahkan');
                                    setState({ ...state, saveLoading: false })
                                    pop(props, '/prospek');
                                },
                                onCancel() {
                                    setState({ ...state, saveLoading: false })
                                },
                            });
                            return;
                        } else {
                            await Fetch.post('/prospek', { ...values, status: 'new', cash: values.cash === 'cash' });
                            message.info('Prospek berhasil ditambahkan');
                        }
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/prospek');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/prospek/${props.match.params.id}`);
        setFieldsValue({
            buyer_name: data.buyer_name,
            buyer_address: data.buyer_address,
            buyer_phone: data.buyer_phone,
            buyer_whatsapp: data.buyer_whatsapp,
            buyer_home_phone: data.buyer_home_phone,
            buyer_email: data.buyer_email,
            motor_id: data.motor_id,
            color_id: data.color_id,
            spk_source_id: data.spk_source_id,
            note: data.note,
        });
        Fetch.get(`/color?filter=(motor_id,,eq,,${data.motor_id})`).then(res => setState({ ...state, colors: res.data, motor: data.motor_id, loading: false }))

    }

    const onMotorChange = v => {
        setFieldsValue({ color_id: undefined })
        Fetch.get(`/color?filter=(motor_id,,eq,,${v})`).then(res => setState({ ...state, colors: res.data, motor: v }))
    }

    return (
        <Card title={<Title title={edit ? "Edit prospek" : "Tambah prospek"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_prospek" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama prospek">
                            {getFieldDecorator('buyer_name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama prospek" />
                            )}
                        </Form.Item>
                        <Form.Item label="Telepon HP">
                            {getFieldDecorator('buyer_phone', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan telepon HP" />
                            )}
                        </Form.Item>
                        <Form.Item label="No Whatapp">
                            {getFieldDecorator('buyer_whatsapp')(
                                <InputText placeholder="Masukkan No Whatapp" />
                            )}
                        </Form.Item>
                        <Form.Item label="Telepon Rumah">
                            {getFieldDecorator('buyer_home_phone')(
                                <InputText placeholder="Masukkan telepon rumah" />
                            )}
                        </Form.Item>
                        <Form.Item label="Email">
                            {getFieldDecorator('buyer_email')(
                                <Input placeholder="Masukkan email" />
                            )}
                        </Form.Item>
                        <Form.Item label="Sumber SPK">
                            {getFieldDecorator('spk_source_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect showSearch dataIndex="name" valueIndex="id" placeholder="Pilih motor" url="/spksource" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Cash/Kredit">
                            {getFieldDecorator('cash', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih cash atau kredit" onChange={v => {
                                    //calculate('cash')(v)
                                }}>
                                    <Select.Option key='cash' value='cash'>Cash</Select.Option>
                                    <Select.Option key='credit' value='credit'>Kredit</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Motor">
                            {getFieldDecorator('motor_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect showSearch dataIndex="name" valueIndex="id" placeholder="Pilih motor" url="/motor?start=0&limit=1000"
                                    onChange={onMotorChange} />
                            )}
                        </Form.Item>
                        {(edit || state.motor) && <Form.Item label="Color">
                            {getFieldDecorator('color_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Silakan pilih warna">
                                    {state.colors.map(v => <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>}
                        <Form.Item label="Alamat">
                            {getFieldDecorator('buyer_address', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputTextArea placeholder="Masukkan alamat" />
                            )}
                        </Form.Item>
                        <Form.Item label="Catatan">
                            {getFieldDecorator('note')(
                                <InputTextArea autosize placeholder="Masukkan catatan" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/prospek')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_prospek' })(Add);