import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, message, Popconfirm, Menu, Dropdown, Icon } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch';
import { pop, push } from '../../component/link';

const Actions = props => {
    const [loading, setLoading] = React.useState(false);
    const onConfirm = async () => {
        setLoading(true);
        try {
            await Fetch.del(`/${props.url}/${props.dataId}`);
            message.info(`${props.title} berhasil dihapus`);
            pop(props, `/${props.url}`);
        } catch (err) {
            message.error('Error: ' + err);
        }
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <span onClick={props.onUpdateStatus}><Icon type="pushpin" /><span style={{ marginLeft: 10 }}>Update status</span></span>
            </Menu.Item>
            <Menu.Item>
                <a href={`/prospek/${props.dataId}/spk`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/${props.url}/${props.dataId}/spk`);
                }}><Icon type="safety" /><span style={{ marginLeft: 10 }}>Proses SPK</span></a>
            </Menu.Item>
        </Menu>
    );
    return (
        <Button.Group>
            <Button icon="edit" href={`/${props.url}/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/${props.url}/${props.dataId}/edit`);
            }}>Edit</Button>
            <Popconfirm placement="bottomLeft" title={"Yakin menghapus?"} onConfirm={onConfirm} okText="Iya" cancelText="Tidak">
                <Button icon="delete" type="danger" loading={loading}>Hapus</Button>
            </Popconfirm>
            <Button icon="reload" onClick={props.reload} />
            {props.record.prospek_status !== 'spk' && <Dropdown overlay={menu} placement="bottomLeft">
                <Button icon="ellipsis" />
            </Dropdown>}
        </Button.Group>
    );
}

Actions.propTypes = {
    dataId: PropTypes.number.isRequired,
    reload: PropTypes.func,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default withRouter(Actions);