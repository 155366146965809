import React from 'react';
import { Card, Row, Col, Divider, Steps, Form, message, DatePicker, Button, InputNumber } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link'
import { formatDate } from '../../component/util'
import { InputText } from '../../component/input';
import Fetch from '../../Fetch';

const Status = props => {
    const { getFieldDecorator } = props.form;
    const [{ record, loading }, { fetchData }] = useDetail(`/stnk/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const step = [{ id: 0, text: 'Input KTP' }, { id: 1, text: 'Input Faktur' }, { id: 2, text: 'Masuk samsat' }, { id: 3, text: 'Jadi' }, { id: 4, text: 'Diterima konsumen' }]
    const [state, setState] = React.useState({ saveLoading: false });
    const formItemLayout = {
        labelCol: {
            md: { span: 4 },
        },
        wrapperCol: {
            md: { span: 8 },
        },
        colon: false, labelAlign: 'left'
    };
    const curStep = loading ? 0 : record.sent_consument !== null ? 5 : record.recieved !== null ? 4 : record.samsat_process !== null ? 3 :
        record.input_faktur_date !== null ? 2 : record.ktp_date === null ? 0 : 1;

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.put(`/stnk/${props.match.params.id}`, values);
                    message.info('STNK berhasil diedit');
                    fetchData()
                    setState({ ...state, saveLoading: false })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const cancelClicked = async () => {
        setState(s => { return { ...s, saveLoading: true } })
        await Fetch.put(`/stnk/${props.match.params.id}`, { status: "finish" });
        fetchData()
    }

    return (
        <Card loading={loading} title={<Title title={"Status STNK"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="STNK" url="stnk" showDelete={false} showEdit={false} />} />} >
            {!loading &&
                <React.Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Tanggal DO">{record ? <MyLink to={`/delivery/${record.delivery_id}`}>{formatDate(record.created_at)}</MyLink> : ''}</TextField>
                            <TextField {...col} label="No SPK">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.number}</MyLink> : ''}</TextField>
                            <TextField {...col} label="Nama STNK">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.stnk_name}</MyLink> : ''}</TextField>
                            <TextField {...col} label="Motor">{record ? record.motor ? record.motor.name : '' : ''}</TextField>
                            <TextField {...col} label="Warna">{record ? record.color ? record.color.name : '' : ''}</TextField>
                            <TextField {...col} label="No Mesin">{record ? <MyLink to={`/stock/${record.stock.id}`}>{record.stock.machine_number}</MyLink> : ''}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Tanggal KTP">{formatDate(record.ktp_date)}</TextField>
                            <TextField {...col} label="Tanggal Faktur">{formatDate(record.input_faktur_date)}</TextField>
                            <TextField {...col} label="Tanggal Samsat">{formatDate(record.samsat_process)}</TextField>
                            <TextField {...col} label="Tanggal Jadi">{formatDate(record.recieved)}</TextField>
                            <TextField {...col} label="Tanggal Konsumen">{formatDate(record.sent_consument)}</TextField>
                            <TextField {...col} label="No Polisi">{record.plate_number}</TextField>
                        </Col>
                    </Row>
                    <Divider />
                    <Steps current={curStep}>
                        {step.map(v => {
                            return <Steps.Step title={v.text} key={v.id}>
                            </Steps.Step>
                        })}
                    </Steps>
                    <Card style={{ marginTop: 16 }}>
                        <Form layout="horizontal" name="create_stnk" onSubmit={formSummited} {...formItemLayout}>
                            {curStep === 0 &&
                                <Form.Item label="Tanggal KTP">
                                    {getFieldDecorator('ktp_date', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <DatePicker placeholder="Masukkan tanggal KTP" format="DD-MM-YYYY" />
                                    )}
                                </Form.Item>
                            }
                            {curStep === 1 &&
                                <Form.Item label="Tanggal Input Faktur">
                                    {getFieldDecorator('input_faktur_date', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <DatePicker placeholder="Masukkan tanggal input faktur" format="DD-MM-YYYY" />
                                    )}
                                </Form.Item>
                            }
                            {curStep === 2 &&
                                <Form.Item label="Tanggal Samsat">
                                    {getFieldDecorator('samsat_process', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <DatePicker placeholder="Masukkan tanggal ke samsat" format="DD-MM-YYYY" />
                                    )}
                                </Form.Item>
                            }
                            {curStep === 3 &&
                                <React.Fragment>
                                    <Form.Item label="Tanggal Jadi">
                                        {getFieldDecorator('recieved', {
                                            rules: [{ required: true, message: 'Harus diisi' }]
                                        })(
                                            <DatePicker placeholder="Masukkan tanggal jadi" format="DD-MM-YYYY" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Nomor STNK">
                                        {getFieldDecorator('stnk_number', {
                                            rules: [{ required: true, message: 'Harus diisi' }]
                                        })(
                                            <InputText placeholder="Masukkan nomor STNK" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Nomor polisi">
                                        {getFieldDecorator('plate_number', {
                                            rules: [{ required: true, message: 'Harus diisi' }]
                                        })(
                                            <InputText placeholder="Masukkan plat nomor" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="SRUT">
                                        {getFieldDecorator('srut', {
                                            rules: [{ required: true, message: 'Harus diisi' }]
                                        })(
                                            <InputText placeholder="Masukkan SRUT" />
                                        )}
                                    </Form.Item>
                                    <Divider />
                                    <Form.Item label="BBN">
                                        {getFieldDecorator('bbn', {
                                            rules: [{ required: true, message: 'Harus diisi' }]
                                        })(
                                            <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Biaya BBN" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Biro Jasa">
                                        {getFieldDecorator('biro', {
                                            rules: [{ required: true, message: 'Harus diisi' }]
                                        })(
                                            <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                parser={value => value.replace(/\$\s?|(\.*)/g, '')} placeholder="Biaya biro jasa" />
                                        )}
                                    </Form.Item>
                                </React.Fragment>
                            }
                            {curStep === 4 && <div>
                                {record.sent_consument === null && "STNK belum diambil / dikirim ke konsumen"}
                            </div>}
                            {curStep === 5 && <div>
                                {record.sent_consument !== null && "STNK telah selesai dan dikirim ke konsumen"}
                            </div>}
                            {curStep < 4 && <Divider />}
                            {curStep < 4 && <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>}
                            {curStep === 5 && <React.Fragment>
                                <Divider />
                                <Button type="primary" loading={state.saveLoading} onClick={cancelClicked}> Batal </Button>
                            </React.Fragment>}
                        </Form>
                    </Card>
                </React.Fragment>
            }
        </Card >
    );
}

export default Form.create({ name: 'create_stnk' })(Status);