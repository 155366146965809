import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Statistic, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { surveyStatusToString, surveyStatusToColor, surveyStatus } from '../../constant'
import { formatDate, filterDate } from '../../component/util'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="download" onClick={props.exportClick}>Export</Button>
            <Button icon="reload" onClick={props.refresh} />
        </Button.Group>
    );
}

const List = props => {
    const [{ filter, order, pagination }, tb] = useTable('/spk', { filter: { cash: 'f', surveyor_status: 'grant', po: 'f', spk_status: 'do' }, order: { key: 'spk_date', order: 'ascend' } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/spk/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/spk/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/spk/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar PO"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            let err = filterDate(filter, 'spk.spk_date')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/spk/exportpo?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Statistic title="Total pending PO" value={pagination.total} decimalSeparator="," groupSeparator="." /><br />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="spk_date" dataIndex="spk_date" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'spk.spk_date', type: 'startend' })} {...tb.sortProps('spk_date')} />
                <Table.Column key="number" dataIndex="number" title="No SPK" />
                <Table.Column key="po" dataIndex="po" title="PO?" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'sudah' : 'belum'}</Tag>}
                    {...tb.columnProps({ key: 'po', type: 'radio', options: [{ text: 'Sudah', value: 't' }, { text: 'Belum', value: 'f' }] })} />
                <Table.Column key="po_date" dataIndex="po_date" title="Tanggal PO" render={formatDate}
                    {...tb.columnProps({ key: 'spk.po_date', type: 'startend' })} />
                <Table.Column key="po_number" dataIndex="po_number" title="No PO" />
                <Table.Column key="machine_number" dataIndex="stock" title="No Mesin" render={r => r === null ? '-' : r.machine_number}
                    {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                <Table.Column key="buyer_name" dataIndex="buyer_name" title="Nama" {...tb.columnProps({ key: 'buyer_name', hint: 'Cari nama' })}
                    {...tb.sortProps('buyer_name')} />
                <Table.Column key="buyer_phone" dataIndex="buyer_phone" title="Telepon" />
                <Table.Column key="user" dataIndex="user" title="Marketing" render={t => t === null ? '-' : <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>}
                    {...tb.columnProps({ key: 'user_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="surveyor_status" dataIndex="surveyor_status" title="Status Surveyor"
                    render={t => <Tag color={surveyStatusToColor(t)}>{surveyStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'survey_status', type: 'radio', options: surveyStatus })} />
                <Table.Column key="leasing" dataIndex="leasing" title="Leasing"
                    render={t => t ? t.name : '-'}
                    {...tb.columnProps({ key: 'survey_status', type: 'radio', options: surveyStatus })} />
                <Table.Column key="suveyor" dataIndex="surveyor" title="Surveyor"
                    render={t => t ? t.name : '-'}
                    {...tb.columnProps({ key: 'survey_status', type: 'radio', options: surveyStatus })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;