import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CashCreditByTeam = props => {
    let dateName = []
    let data = []
    for (let i = 0; i < props.record.length; i++) {
        const v = props.record[i];
        dateName.push(v.name)
        for (let j = 0; j < v.data.length; j++) {
            const vv = v.data[j];
            data.push({ ...vv, name: v.name })
        }
    }

    const renderQuarterTick = (tickProps) => {
        const { x, y, payload } = tickProps;
        const { value, offset } = payload;
        return <text x={x + offset} y={y - 4} textAnchor="middle">{value}</text>;
    };

    return <ResponsiveContainer width='100%' height={250}>
        <BarChart data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="team" tickFormatter={(t) => ''} />
            <XAxis dataKey="name" axisLine={false} tickLine={false} interval={0} height={1} scale="band" xAxisId="quarter" tick={renderQuarterTick} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar key="cash" dataKey="cash" fill="#98FB98" />
            <Bar key="credit" dataKey="credit" fill="#8B4513" />
        </BarChart>
    </ResponsiveContainer>
}

export default CashCreditByTeam;