import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Tag } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import Fetch from '../../Fetch';
import { LabelHeader } from '../../component/header';
import { MyLink } from '../../component/link';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/claimreceipt/${props.match.params.id}`);
    const [state, setState] = React.useState({ data: [], loading: true })
    const col = { labelSpan: 8, valueSpan: 16 }

    React.useEffect(() => {
        if (record.id)
            Fetch.get(`/claim?filter=(claim_receipt_id,,eq,,${record.id})&order=created_at DESC&limit=1000`).then(v => {
                setState({ ...state, loading: false, data: v.data });
            })
    }, [record])

    const renderRow = (v, b) => {
        return <span>
            <Tag color={b ? 'green' : 'red'}>{b ? 'Lunas' : 'Belum'}</Tag>
            <span style={{ paddingLeft: 2 }}>{parseInt(v).toLocaleString('in-ID')}</span>
        </span>
    }

    return (
        <Card loading={loading} title={<Title title={"Detail tanda terima tagihan"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Tanda terima tagihan" url="claimreceipt" />} />} >
            {!loading &&
                <Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Tanggal">{formatDate(record.created_at)}</TextField>
                            <TextField {...col} label="Nomor">{record.number}</TextField>
                            <TextField {...col} label="Leasing">{record.leasing.name}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField {...col} label="Pembuat">{record.user.name}</TextField>
                            <TextField {...col} label="Catatan">{record.note}</TextField>
                        </Col>
                    </Row>
                    <LabelHeader>Tagihan</LabelHeader>
                    <Table loading={state.loading} dataSource={state.data} rowKey="id" pagination={false} scroll={{ x: true }}>
                        <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate} />
                        <Table.Column key="po_number" dataIndex="spk.po_number" title="NO PO" />
                        <Table.Column key="po_date" dataIndex="spk.po_date" title="Tanggal PO" render={formatDate} />
                        <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"
                            render={(t, r) => <MyLink to={`/stock/${r.stock_id}`}>{t}</MyLink>} />
                        <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama pembeli"
                            render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} />
                        <Table.Column key="marketing" dataIndex="marketing.name" title="Nama marketing" render={(t, r) => r.marketing ? <MyLink to={`/user/${r.marketing.id}`}>{t}</MyLink> : '-'} />
                        <Table.Column key="otr" dataIndex="spk.otr_price" title="Otr" align="right" render={r => parseInt(r).toLocaleString('in-ID')} />
                        <Table.Column key="unit" dataIndex="unit" title="Unit" align="right" render={(t, r) => renderRow(t, r.unit_done)} />
                        <Table.Column key="subsidi_leasing1" dataIndex="subsidi_leasing2" align="right" title="Subsidi Leasing 1" render={(t, r) => renderRow(t, r.subsidi_leasing1_done)} />
                        <Table.Column key="subsidi_leasing2" dataIndex="subsidi_leasing2" align="right" title="Subsidi Leasing 2" render={(t, r) => renderRow(t, r.subsidi_leasing2_done)} />
                        <Table.Column key="subsidi_ahm" dataIndex="subsidi_ahm" title="Subsidi AHM" align="right" render={(t, r) => renderRow(t, r.subsidi_ahm_done)} />
                        <Table.Column key="intensive_leasing" dataIndex="intensive_leasing" align="right" title="Intensif Leasing" render={(t, r) => renderRow(t, r.intensive_leasing_done)} />
                        <Table.Column key="motor" dataIndex="motor.name" title="Motor" />
                        <Table.Column key="warna" dataIndex="color.name" title="Warna" />
                        <Table.Column key="note" dataIndex="note" title="Catatan" />
                    </Table>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;