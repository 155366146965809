import React, { Fragment } from 'react';
import { Card, Row, Col, Tag, Divider } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import { MyLink } from '../../component/link'
import { stockStatusToString, stockStatusToColor } from '../../constant'
import Fetch from '../../Fetch';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/stock/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }

    return (
        <Card loading={loading} title={<Title title={"Detail stock"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Stock" url="stock" />} />} >
            {!loading && <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField {...col} label="No SO">{record.purchase ? record.purchase.number : ''}</TextField>
                        <TextField {...col} label="Motor">{record.motor.name}</TextField>
                        <TextField {...col} label="Warna">{record.color.name}</TextField>
                        <TextField {...col} label="No mesin">{record.machine_number}</TextField>
                        <TextField {...col} label="No rangka">{record.chasis_number}</TextField>
                        <TextField {...col} label="Tahun">{record.year}</TextField>
                        <TextField {...col} label="">{record.fullname}</TextField>

                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="Pengirim">{record.sender}</TextField>
                        <TextField {...col} label="No pengiriman">{<MyLink to={`/stocksj/${record.id}`}>{record.sj_number}</MyLink>}</TextField>
                        <TextField {...col} label="Tanggal pengiriman">{formatDate(record.sj_date)}</TextField>
                        <TextField {...col} label="Tersedia">{<Tag color={record.on_stock ? 'green' : 'red'}>{record.on_stock ? 'Tersedia' : 'Tidak'}</Tag>}</TextField>
                        <TextField {...col} label="Catatan">{record.note}</TextField>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField {...col} label="Helm"><Tag color={record.helm ? 'green' : 'red'}>{record.helm ? 'Iya' : 'Tidak'}</Tag></TextField>
                        <TextField {...col} label="Buku service"><Tag color={record.service_book ? 'green' : 'red'}>{record.service_book ? 'Iya' : 'Tidak'}</Tag></TextField>
                        <TextField {...col} label="Spion"><Tag color={record.mirror ? 'green' : 'red'}>{record.mirror ? 'Iya' : 'Tidak'}</Tag></TextField>
                        <TextField {...col} label="Toolkit"><Tag color={record.toolkit ? 'green' : 'red'}>{record.toolkit ? 'Iya' : 'Tidak'}</Tag></TextField>
                        <TextField {...col} label="Aki"><Tag color={record.accu ? 'green' : 'red'}>{record.accu ? 'Iya' : 'Tidak'}</Tag></TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="Aki"><Tag color={stockStatusToColor(record.stock_status)}>{stockStatusToString(record.stock_status)}</Tag></TextField>
                        <TextField {...col} label="memo">{record.memo}</TextField>
                        {record.sign &&
                            <TextField {...col} label="TTD"><Tag onClick={() => {
                                window.open(Fetch.getUrl('/public/imageuploads/' + record.sign), '_blank')
                            }}>Klik disini</Tag></TextField>}
                    </Col>
                </Row>
            </Fragment>}
        </Card >
    );
}

export default Detail;