import React from 'react';
import { Card, Row, Col, Divider, Table } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link'
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/biropayment/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const [state, setState] = React.useState({ data: [], loading: true })

    React.useEffect(() => {
        if (record.id)
            Fetch.get(`/biro?filter=(biro_payment_id,,eq,,${record.id})&order=created_at DESC&limit=1000`).then(v => {
                setState({ ...state, loading: false, data: v.data });
            })
    }, [record])

    return (
        <Card loading={loading} title={<Title title={"Detail Pembayaran Biro Jasa"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Biro" url="biropayment" showDelete={false} />} />} >
            {!loading && <div>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField {...col} label="Tanggal">{formatDate(record.date)}</TextField>
                        <TextField {...col} label="Nomor">{record.number}</TextField>
                        <TextField {...col} label="Nomor Giro">{record.giro_number}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="Total">{formatMoney(record.value)}</TextField>
                        <TextField {...col} label="Catatan">{record.note}</TextField>
                    </Col>
                </Row>
                <Divider />
                <Table loading={state.loading} dataSource={state.data} rowKey="id" pagination={false} scroll={{ x: true }}>
                    <Table.Column key="date" dataIndex="date" title="Tanggal" render={formatDate} />
                    <Table.Column key="number" dataIndex="number" title="Nomor tagihan" />
                    <Table.Column key="value" dataIndex="value" title="Total" render={formatMoney} />
                    <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                        return <MyLink to={`/biro/${r.id}`}>Detail</MyLink>
                    }} />
                </Table>
            </div>
            }
        </Card >
    );
}

export default Detail;
