import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import { formatDate, filterDate } from '../../component/util'
import Fetch from '../../Fetch'
import ExportDateDialog from '../../component/exportdatedialog';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="check" onClick={props.doneClick}>BPKB Jadi</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/bpkb', { order: { key: 'created_at', order: 'descend' } })
    const [exp, setExp] = React.useState({ visible: false })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/bpkb/${record.id}`, "_blank")
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/bpkb/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/bpkb/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    const doneClick = () => {
        push(props, '/bpkb/add')
    }

    const onExport = (s, e) => {
        const url = tb.genFilterUrl(`/bpkb/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
        window.open(Fetch.getUrl(url))
    }

    return (
        <React.Fragment>
            <Card title={<Title title={"Daftar BPKB"} actions={<Actions  {...props} refresh={tb.fetchData} doneClick={doneClick} exportClick={() => {
                let err = filterDate(filter, 'bpkb.created_at')
                if (err !== '') {
                    message.error(err)
                    return;
                }
                const url = tb.genFilterUrl(`/bpkb/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
                window.open(Fetch.getUrl(url))
            }} />} />}>
                <Table {...tb.tableProps} onRow={onRow}>
                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal DO" render={formatDate}
                        {...tb.columnProps({ key: 'bpkb.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                    <Table.Column key="number" dataIndex="number" title="No BPKB"  {...tb.columnProps({ key: 'number', hint: 'Cari nomor' })} />
                    <Table.Column key="name" dataIndex="spk.stnk_name" title="Nama STNK"  {...tb.columnProps({ key: 'spk.stnk_name', hint: 'Cari nama STNK' })}
                        render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>}{...tb.sortProps('spk.stnk_name')} />
                    <Table.Column key="marketing" dataIndex="marketing.name" title="Marketing"
                        render={(t, r) => r.marketing == null ? '-' : <MyLink to={`/user/${r.marketing_id}`}>{t}</MyLink>}
                        {...tb.columnProps({ key: 'bpkb.marketing_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                    <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })}
                        render={(t, r) => <MyLink to={`/stock/${r.stock_id}`}>{t}</MyLink>} />
                    <Table.Column key="chasis_number" dataIndex="stock.chasis_number" title="No Rangka"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                    <Table.Column key="plate_number" dataIndex="plate_number" title="No Polisi"  {...tb.columnProps({ key: 'police_number', hint: 'Cari no polisi' })} />
                    <Table.Column key="leasing" dataIndex="leasing.name" title="Leasing" render={(r, s) => s.leasing === null ? 'CASH' : r} {...tb.sortProps('leasing.name')} />
                    <Table.Column key="recieved" dataIndex="recieved" title="Tanggal Jadi" render={formatDate} />
                    <Table.Column key="sent_consument" dataIndex="sent_consument" title="Tanggal Konsumen" render={formatDate} />
                    <Table.Column key="faktur_number" dataIndex="faktur_number" title="No Faktur" />
                    <Table.Column key="note" dataIndex="note" title="Catatan" />

                    <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                        return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                    }} fixed="right" />
                </Table>
            </Card >
            <ExportDateDialog visible={exp.visible} onCancel={() => setExp({ ...exp, visible: false })} onExport={onExport} />
        </React.Fragment>
    )
}

export default List;