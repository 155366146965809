import React, { Fragment } from 'react'
import { Row, Col, Tag } from 'antd';
import { LabelHeader } from '../../component/header'
import { TextField } from '../../component/field'
import { spkStatusToString, spkStatusToColor, surveyStatusToString, surveyStatusToColor } from '../../constant'
import { MyLink } from '../../component/link';
import { formatDate } from '../../component/util'

const Info = props => {
    const { record } = props;
    const col = { labelSpan: 8, valueSpan: 16 }

    const renderValue = v => <div style={{ width: '100%', textAlign: 'right', paddingRight: 50 }}>
        {v.toLocaleString('in-ID')}
    </div>

    return (
        <div>
            <LabelHeader>Info pembeli</LabelHeader>
            <Row gutter={16}>
                <Col span={12}>
                    <TextField {...col} label="Tanggal SPK">{formatDate(record.spk_date)}</TextField>
                    <TextField {...col} label="No SPK">{record.number}</TextField>
                    <TextField {...col} label="Status SPK">{<Tag color={spkStatusToColor(record.spk_status)}>
                        {spkStatusToString(record.spk_status)}</Tag>}</TextField>
                    {!record.cash && <TextField {...col} label="Status Surveyor">{<Tag color={surveyStatusToColor(record.surveyor_status)}>
                        {surveyStatusToString(record.surveyor_status)}</Tag>}</TextField>}
                    <TextField {...col} label="Nama pembeli">{record.buyer_name}</TextField>
                    <TextField {...col} label="Telepon HP">{record.buyer_phone}</TextField>
                    <TextField {...col} label="No Whatsapp">{record.buyer_whatsapp}</TextField>
                    <TextField {...col} label="Telepon Rumah">{record.buyer_home_phone}</TextField>
                    <TextField {...col} label="Email pembeli">{record.buyer_email}</TextField>
                    <TextField {...col} label="Alamat pembeli">{record.buyer_address}</TextField>
                </Col>
                <Col span={12}>
                    <TextField {...col} label="Region">{record.region !== null ? record.region.name : 'Tangerang'}</TextField>
                    <TextField {...col} label="NO KTP">{record.no_ktp}</TextField>
                    <TextField {...col} label="Nama STNK">{record.stnk_name}</TextField>
                    <TextField {...col} label="Alamat STNK">{record.stnk_address}</TextField>
                    <TextField {...col} label="Motor">{record.motor === null ? '-' : record.motor.name}</TextField>
                    <TextField {...col} label="Warna">{record.color ? record.color.name : '-'}</TextField>
                    <TextField {...col} label="Marketing">{record.user === null ? '-' : <MyLink to={`/user/${record.user.id}`}>{record.user.name}</MyLink>}</TextField>
                    <TextField {...col} label="Surveyor">{record.surveyor === null ? '-' : <MyLink to={`/user/${record.surveyor.id}`}>{record.surveyor.name}</MyLink>}</TextField>
                    {!record.cash && <TextField {...col} label="Open Surveyor">{<Tag color={record.open_surveyor ? 'green' : 'red'}>
                        {record.open_surveyor ? 'Iya' : 'Tidak'}</Tag>}</TextField>}
                    {!record.cash && <TextField {...col} label="Nama surveyor">{record.surveyor ? <MyLink to={`/user/${record.surveyor.id}`}>{record.surveyor.name}</MyLink> : '-'}</TextField>}
                </Col>
            </Row>
            <div style={{ marginTop: 30 }} />
            <LabelHeader>Info harga</LabelHeader>
            <Row gutter={16}>
                <Col span={12}>
                    <TextField {...col} label="Cash/Kredit">{<Tag color={record.cash ? 'blue' : 'green'}>{record.cash ? 'Cash' : 'Kredit'}</Tag>}</TextField>
                    <TextField {...col} label="OTR">{renderValue(record.otr_price)}</TextField>
                    {!record.cash && <TextField {...col} label="DP">{renderValue(record.dp)}</TextField>}
                    <TextField {...col} label="Diskon">{renderValue(record.discount)}</TextField>
                </Col>
                <Col span={12}>
                    <TextField {...col} label="Pelanggaran">{renderValue(record.violation)}</TextField>
                    <TextField {...col} label="Progresif">{renderValue(record.progressive)}</TextField>
                    <TextField {...col} label="Total">{renderValue(props.total)}</TextField>
                </Col>
            </Row>
            {!record.cash &&
                <Fragment>
                    <LabelHeader>Info leasing</LabelHeader>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="Leasing">{record.leasing == null ? record.leasing_id : record.leasing.name}</TextField>
                            <TextField {...col} label="Tenor">{renderValue(record.tenor)}</TextField>
                            <TextField {...col} label="Cicilan">{renderValue(record.installment)}</TextField>
                        </Col>
                        <Col span={12}>
                        </Col>
                    </Row>
                </Fragment>
            }
            {!record.cash && record.po &&
                <Fragment>
                    <LabelHeader>Info PO</LabelHeader>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...col} label="PO">{record.po_number}</TextField>
                            <TextField {...col} label="Tanggal PO">{formatDate(record.po_date)}</TextField>
                        </Col>
                        <Col span={12}>
                        </Col>
                    </Row>
                </Fragment>
            }
        </div>
    )
}

export default Info;