import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch';
import { push } from '../../component/link';

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="edit" href={`/${props.url}/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/${props.url}/${props.dataId}/edit`);
            }}>Edit</Button>
            <Button icon="printer" href={Fetch.getUrl(`/stocksentprint/${props.dataId}/pdf?access_token=${localStorage.getItem('token')}`)}
                target="_blank">Print</Button>
            <Button icon="swap" href={`/stocksent/${props.dataId}/take`} onClick={(e) => {
                e.preventDefault()
                push(props, `/stocksent/${props.dataId}/take`)
            }}>Tarik</Button>
            <Button icon="reload" onClick={props.reload} />
        </Button.Group>
    );
}

Actions.propTypes = {
    dataId: PropTypes.number.isRequired,
    reload: PropTypes.func,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    showPrint: PropTypes.bool,
}

export default withRouter(Actions);