import React from 'react';
import { Card, Row, Col, Tag, Tabs, Drawer, Form, Button, message, DatePicker } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link';
import { formatDate } from '../../component/util'
import { LabelHeader } from '../../component/header';
import { useTab } from '../../component/tab';
import Picture from '../spk/picture'
import { InputText } from '../../component/input';
import moment from 'moment'
import Fetch from '../../Fetch';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/claim/${props.match.params.id}`);
    const { getFieldDecorator } = props.form;
    const col = { labelSpan: 8, valueSpan: 16 }
    const tab = useTab(props, 'tab', 'info')
    const [state, setState] = React.useState({ drawerPOVisible: false })

    const mrow = (v, l) => {
        return <Row>
            <Col span={16}><div style={{ textAlign: 'right', paddingRight: 10 }}>{v.toLocaleString('in-ID')}</div></Col>
            <Col span={8}>{<Tag color={l ? 'green' : 'red'}>{l ? 'Lunas' : 'Belum'}</Tag>}</Col>
        </Row>
    }

    const onPoClicked = () => {
        setState({ ...state, drawerPOVisible: true })
    }

    const formPOSubmited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.put(`/spk/${record.spk_id}`, { po: values.po_date !== null, po_number: values.po_number, po_date: values.po_date });
                    message.info('Status PO berhasil di update');
                    fetchData();
                    setState({ ...state, saveLoading: false, drawerPOVisible: false })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    return (
        <Card loading={loading} title={<Title title={"Detail tagihan"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Tagihan" url="claim" onPoClicked={onPoClicked} />} />} >
            {!loading &&
                <Tabs  {...tab}>
                    <Tabs.TabPane tab="Info" key="info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Tanggal DO">{formatDate(record.created_at)}</TextField>
                                <TextField {...col} label="No DO">{<MyLink to={`/delivery/${record.delivery.id}`}>{record.delivery.number}</MyLink>}</TextField>
                                <TextField {...col} label="Nama pembeli">{<MyLink to={`/spk/${record.spk.id}`}>{record.spk.buyer_name}</MyLink>}</TextField>
                                <TextField {...col} label="Marketing">{record.marketing ? <MyLink to={`/user/${record.marketing.id}`}>{record.marketing.name}</MyLink> : '-'}</TextField>
                                <TextField {...col} label="Motor">{record.motor.name}</TextField>
                                <TextField {...col} label="Warna">{record.color ? record.color.name : '-'}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="No PO">{record.spk.po_number}</TextField>
                                <TextField {...col} label="Tanggal PO">{formatDate(record.spk.po_date)}</TextField>
                                <TextField {...col} label="Leasing">{record.leasing.name}</TextField>
                                <TextField {...col} label="OTR">{record.spk.otr_price.toLocaleString('in-ID')}</TextField>
                                <TextField {...col} label="Alamat">{record.leasing_address}</TextField>
                                <TextField {...col} label="Catatan">{record.note}</TextField>
                            </Col>
                        </Row>
                        <LabelHeader>Info pembayaran</LabelHeader>
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="DP PO"><div style={{ textAlign: 'right', paddingRight: 10 }}>{record.dp_po.toLocaleString('in-ID')}</div></TextField>
                                <TextField {...col} label="DP"><div style={{ textAlign: 'right', paddingRight: 10 }}>{record.dp.toLocaleString('in-ID')}</div></TextField>
                                <TextField {...col} label="Unit">{mrow(record.unit, record.unit_done)}</TextField>
                                <TextField {...col} label="Tanggal Unit">{formatDate(record.unit_date)}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Subsdi Leasing 1">{mrow(record.subsidi_leasing1, record.subsidi_leasing1_done)}</TextField>
                                <TextField {...col} label="Subsidi 1 PPh"><Tag color={record.subsidi_leasing1_pph ? 'green' : 'red'}>
                                    {record.subsidi_leasing1_pph ? 'Iya' : 'Tidak'}</Tag></TextField>
                                <TextField {...col} label="Subsdi Leasing 2">{mrow(record.subsidi_leasing2, record.subsidi_leasing2_done)}</TextField>
                                <TextField {...col} label="Subsidi 2 PPh"><Tag color={record.subsidi_leasing2_pph ? 'green' : 'red'}>
                                    {record.subsidi_leasing2_pph ? 'Iya' : 'Tidak'}</Tag></TextField>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Gambar" key="picture">
                        <Picture spkid={record.spk_id} />
                    </Tabs.TabPane>
                </Tabs>
            }
            {!loading && <Drawer visible={state.drawerPOVisible} title="Update PO" width={350}
                onClose={() => setState({ ...state, drawerPOVisible: false })}>
                {state.drawerPOVisible && <Form name="update_status" onSubmit={formPOSubmited}>
                    <Form.Item label="Tanggal PO">
                        {getFieldDecorator('po_date', {
                            initialValue: record.spk.po_date ? moment(record.spk.po_date) : undefined,
                        })(
                            <DatePicker placeholder="Masukkan tanggal PO" format="DD-MM-YYYY" />
                        )}
                    </Form.Item>
                    <Form.Item label="No PO">
                        {getFieldDecorator('po_number', {
                            initialValue: record.spk.po_number,
                            rules: [{ required: true, message: 'Wajib diisi' }],
                        })(
                            <InputText placeholder="Masukkan no PO" />
                        )}
                    </Form.Item>
                    <Button icon="pushpin" type="primary" htmlType="submit" loading={state.saveLoading}>Update PO</Button>
                </Form>}
            </Drawer>}
        </Card >
    );
}

export default Form.create({ name: 'update_status' })(Detail);