import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { InputText } from '../../component/input';
import MySelect from '../../component/select';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/customerjob/${props.match.params.id}`, values);
                        message.info('Pekerjaan berhasil diedit');
                    } else {
                        await Fetch.post('/customerjob', values);
                        message.info('Pekerjaan berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/customerjob');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/customerjob/${props.match.params.id}`);
        setState({ ...state, loading: false });
        setFieldsValue({
            name: data.name,
            parent_id: data.parent_id === 0 ? undefined : data.parent_id,
        });
    }

    return (
        <Card title={<Title title={edit ? "Edit Pekerjaan" : "Tambah Pekerjaan"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_customerjob" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama pekerjaan">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama perkerjaan" />
                            )}
                        </Form.Item>
                        <Form.Item label="Parent">
                            {getFieldDecorator('parent_id')(
                                <MySelect url="/customerjob" valueIndex="id" dataIndex="name" allowClear />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/customerjob')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading} > Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_customerjob' })(Add);