import React from 'react';
import { Card, Form, Drawer, Divider, Button, message, Table, Popconfirm, Tag } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import MySelect from '../../component/select';
import { formatDate } from '../../component/util'
import { MyLink } from '../../component/link';
import { InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [, tbClaim] = useTable('/claim', {}, { 'key': '_t' }, true)
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, drawerClaimVisible: false, claims: [], removed: [], added: [] });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/claimreceipt/${props.match.params.id}`, { ...values, claims: state.claims.map(v => v.id) });
                        message.info('Tanda terima tagihan berhasil diedit');
                    } else {
                        await Fetch.post('/claimreceipt', { ...values, claims: state.claims.map(v => v.id) });
                        message.info('Tanda terima tagihan berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/claimreceipt');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/claimreceipt/${props.match.params.id}`);
        const claims = await Fetch.get(`/claim?filter=(claim_receipt_id,,eq,,${props.match.params.id})`)
        setFieldsValue({
            leasing_id: data.leasing_id,
            note: data.note,
        });
        setState({
            ...state, loading: false, claims: claims.data, leasing_id: data.leasing_id,
        });
    }

    const onRowClaim = (record) => {
        return {
            onClick: () => {
                const ind = state.claims.findIndex(v => v.id === record.id)
                if (ind < 0) {
                    setState({ ...state, drawerPlaceVisible: false, claims: [...state.claims, record] })
                }
                else message.error('Tagihan sudah masuk ')
            }
        }
    }
    const onConfirm = (record) => {
        return () => {
            setState({ ...state, claims: state.claims.filter(v => v.id !== record.id) })
        }
    }

    const renderRow = (v, b) => {
        return <span>
            <Tag color={b ? 'green' : 'red'}>{b ? 'Lunas' : 'Belum'}</Tag>
            <span style={{ paddingLeft: 2 }}>{parseInt(v).toLocaleString('in-ID')}</span>
        </span>
    }

    return (
        <Card title={<Title title={edit ? "Edit tanda terima tagihan " : "Tambah tanda terima tagihan"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_stocksend" onSubmit={formSummited} {...formItemLayout}>
                <Form.Item label="Leasing">
                    {getFieldDecorator('leasing_id', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <MySelect valueIndex="id" dataIndex="name" url='/leasing?order=name asc&limit=1000' showSearch onChange={v => setState({ ...state, leasing_id: v })}
                            disabled={edit} />
                    )}
                </Form.Item>
                <Form.Item label="Catatan">
                    {getFieldDecorator('note')(
                        <InputTextArea autosize placeholder="Masukkan catatan" />
                    )}
                </Form.Item>
                <Divider />
                <LabelHeader actions={<Button icon="plus" type="primary" onClick={() => {
                    tbClaim.setFilter({ 'claim.leasing_id': state.leasing_id, 'claim.claim_receipt_id': 0, 'spk.po_number::not': '' })
                    setState({ ...state, drawerClaimVisible: true });
                }} disabled={!state.leasing_id}>Tambah tagihan</Button>}>
                    Tagihan terpilih</LabelHeader>
                <Table rowKey="id" dataSource={state.claims} scroll={{ x: true }} pagination={false}>
                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate} />
                    <Table.Column key="po_number" dataIndex="spk.po_number" title="NO PO" />
                    <Table.Column key="po_date" dataIndex="spk.po_date" title="Tanggal PO" render={formatDate} />
                    <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"
                        render={(t, r) => <MyLink to={`/motor/${r.motor.id}`}>{t}</MyLink>} />
                    <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama pembeli"
                        render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} />
                    <Table.Column key="marketing" dataIndex="marketing.name" title="Nama marketing" render={(t, r) => <MyLink to={`/user/${r.marketing.id}`}>{t}</MyLink>} />
                    <Table.Column key="otr" dataIndex="spk.otr_price" title="Otr" align="right" render={r => parseInt(r).toLocaleString('in-ID')} />
                    <Table.Column key="unit" dataIndex="unit" title="Unit" align="right" render={(t, r) => renderRow(t, r.unit_done)} />
                    <Table.Column key="subsidi_leasing1" dataIndex="subsidi_leasing2" align="right" title="Subsidi Leasing 1" render={(t, r) => renderRow(t, r.subsidi_leasing1_done)} />
                    <Table.Column key="subsidi_leasing2" dataIndex="subsidi_leasing2" align="right" title="Subsidi Leasing 2" render={(t, r) => renderRow(t, r.subsidi_leasing2_done)} />
                    <Table.Column key="subsidi_ahm" dataIndex="subsidi_ahm" title="Subsidi AHM" align="right" render={(t, r) => renderRow(t, r.subsidi_ahm_done)} />
                    <Table.Column key="intensive_leasing" dataIndex="intensive_leasing" align="right" title="Intensif Leasing" render={(t, r) => renderRow(t, r.intensive_leasing_done)} />
                    <Table.Column key="motor" dataIndex="motor.name" title="Motor" />
                    <Table.Column key="warna" dataIndex="color.name" title="Warna" />
                    <Table.Column key="id" dataIndex="id" title="aksi" render={(t, record) =>
                        <Popconfirm placement="bottomRight" title={`Yakin menghapus?`} onConfirm={onConfirm(record)} okText="Iya" cancelText="Tidak">
                            <span className="span-click">hapus</span>
                        </Popconfirm>
                    } width={80} fixed="right" />
                </Table>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/claimreceipt')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
            <Drawer title="Pilih tagihan" visible={state.drawerClaimVisible} width={600} onClose={() => setState({ ...state, drawerClaimVisible: false })}>
                <Table {...tbClaim.tableProps} onRow={onRowClaim}>
                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate} {...tbClaim.sortProps('created_at')} />
                    <Table.Column key="po_number" dataIndex="spk.po_number" title="NO PO" {...tbClaim.columnProps({ key: 'spk.po_number', hint: 'Cari no PO' })} />
                    <Table.Column key="po_date" dataIndex="spk.po_date" title="Tanggal PO" render={formatDate} />
                    <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin" {...tbClaim.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                    <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama pembeli" {...tbClaim.columnProps({ key: 'spk.buyer_name', hint: 'Cari nama pembeli' })} />
                    <Table.Column key="stnk_name" dataIndex="spk.stnk_name" title="Nama STNK"  {...tbClaim.columnProps({ key: 'spk.stnk_name', hint: 'Cari nama stnk' })} />
                    <Table.Column key="marketing" dataIndex="marketing.name" title="Nama marketing" />
                    <Table.Column key="otr" dataIndex="spk.otr_price" title="Otr" align="right" render={r => parseInt(r).toLocaleString('in-ID')} />
                    <Table.Column key="unit" dataIndex="unit" title="Unit" align="right" render={(t, r) => renderRow(t, r.unit_done)} />
                    <Table.Column key="subsidi_leasing1" dataIndex="subsidi_leasing2" align="right" title="Subsidi Leasing 1" render={(t, r) => renderRow(t, r.subsidi_leasing1_done)} />
                    <Table.Column key="subsidi_leasing2" dataIndex="subsidi_leasing2" align="right" title="Subsidi Leasing 2" render={(t, r) => renderRow(t, r.subsidi_leasing2_done)} />
                    <Table.Column key="motor" dataIndex="motor.name" title="Motor" />
                    <Table.Column key="warna" dataIndex="color.name" title="Warna" />
                </Table>
            </Drawer>
        </Card>
    );
}

export default Form.create({ name: 'create_stocksend' })(Add);