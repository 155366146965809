import React from 'react';
import { Card, Table, Button, Tag, Statistic, Divider, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { formatDate, filterDate } from '../../component/util'
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/spk?order=spk_date desc', { filter: { payment_residual: 0, spk_status: 'do' } })
    const [state, setState] = React.useState({ drawerExportVisible: false, total: 0 })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/spk/${record.id}`, '_blank')
            }
        }
    }

    React.useEffect(() => {
        Fetch.get('/spk/pending').then(r => {
            setState({ ...state, total: r.total });
        })
    }, [])

    return (
        <Card title={<Title title={"Daftar pendingan pembayaran"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            let err = filterDate(filter, 'spk.spk_date')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/spk/exportpending?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Statistic title="Total pendingan pembayaran" value={state.total} decimalSeparator="," groupSeparator="." />
            <Divider />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="spk_date" dataIndex="spk_date" title="Tanggal SPK" render={formatDate}
                    {...tb.columnProps({ key: 'spk.spk_date', type: 'startend' })} {...tb.sortProps('spk.spk_date')} />
                <Table.Column key="payment_residual2" dataIndex="payment_residual" title="Status"
                    {...tb.columnProps({ key: 'payment_residual', type: 'radio', options: [{ text: 'Belum', value: 0 }] })}
                    render={t => <Tag color={t ? 'red' : 'green'}>{t > 0 ? 'Belum' : 'Lunas'}</Tag>} />
                <Table.Column key="number" dataIndex="number" title="No SPK" render={(t, r) => <MyLink to={`/spk/${r.id}`}>{t}</MyLink>} />
                <Table.Column key="buyer_name" dataIndex="buyer_name" title="Nama" {...tb.columnProps({ key: 'buyer_name', hint: 'Cari nama' })}
                    {...tb.sortProps('buyer_name')} />
                <Table.Column key="buyer_phone" dataIndex="buyer_phone" title="Telepon" />
                <Table.Column key="machine" dataIndex="stock" title="No Mesin" render={r => r === null ? '-' : r.machine_number}
                    {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                <Table.Column key="user" dataIndex="user" title="Marketing" render={t => t === null ? '-' : <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>} />
                <Table.Column key="cash" dataIndex="cash" title="Cash" render={t => <Tag color={t ? 'blue' : 'green'}>{t ? 'Cash' : 'Kredit'}</Tag>} />
                <Table.Column key="total" dataIndex="total" title="Total" align="right" render={r => parseInt(r).toLocaleString('in-ID')} />
                <Table.Column key="payment_recieved" dataIndex="payment_recieved" title="Diterima" align="right" render={r => parseInt(r).toLocaleString('in-ID')} />
                <Table.Column key="payment_residual" dataIndex="payment_residual" title="Kekurangan" align="right" render={r => parseInt(r).toLocaleString('in-ID')} />
            </Table>
        </Card >
    )
}

export default List;