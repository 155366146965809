import React from 'react';
import { Card, Form, Row, Col, Divider, Button, Input, message, Select, Transfer, InputNumber, Typography, Tag } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { LabelHeader } from '../../component/header';
import { InputText, InputTextArea } from '../../component/input';
import MySelect from '../../component/select';
import { P_SPK_INSERT, P_SPK_READ, P_SPK_UPDATE, P_STOCK_READ, P_STNK_READ, P_BPKB_READ, P_PAYMENT_WRITE, P_PAYMENT_READ, P_DELIVERY_DRIVER, P_MONEY_SETOR, P_CASHIER, P_SPK_ADMIN, P_DELIVERY_READ, P_PAYMENT_ADMIN } from '../../permission';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, selectedPermission: [], type: undefined });
    const [per, setPer] = React.useState({ permissionSource: [] })
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };
    const templates = [
        { text: 'Marketing', data: [P_SPK_INSERT, P_SPK_READ, P_SPK_UPDATE, P_STOCK_READ, P_STNK_READ, P_BPKB_READ, P_PAYMENT_WRITE, P_PAYMENT_READ] },
        { text: 'Driver', data: [P_STOCK_READ, P_STNK_READ, P_BPKB_READ, P_PAYMENT_WRITE, P_PAYMENT_READ, P_DELIVERY_DRIVER] },
        { text: 'Kasir', data: [P_MONEY_SETOR, P_CASHIER, P_PAYMENT_READ, P_SPK_ADMIN, P_STOCK_READ, P_DELIVERY_READ, P_PAYMENT_ADMIN] }
    ]

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/user/${props.match.params.id}`, { ...values, permissions: state.selectedPermission });
                        message.info('User berhasil diedit');
                    } else {
                        await Fetch.post('/user', { ...values, permissions: state.selectedPermission });
                        message.info('User berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/user');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
        fetchPermission();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/user/${props.match.params.id}`);
        setState({ ...state, loading: false, selectedPermission: data.user_permissions === null ? [] : data.user_permissions.map(v => v.permission), type: data.type });
        setFieldsValue({
            name: data.name,
            username: data.username,
            address: data.address,
            type: data.type,
            phone: data.phone,
            email: data.email,
            leasing_id: data.leasing_id,
            ahm_id_sales_person: data.ahm_id_sales_person,
            ahm_name: data.ahm_name,
            target_prospek: data.target_prospek,
            target_sale: data.target_sale,
            target_spk: data.target_spk,
        });
    }

    const fetchPermission = async () => {
        const { data } = await Fetch.get(`/permission`)
        setPer({ ...per, permissionSource: data.map(v => ({ ...v, key: v.id })) })
    }

    const onTemplateClick = (v) => {
        setState({ ...state, selectedPermission: v })
    }

    return (
        <Card title={<Title title={edit ? "Edit user" : "Tambah user"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_user" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nama" />
                            )}
                        </Form.Item>
                        <Form.Item label="Username">
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input placeholder="Masukkan username" readOnly={edit} autoComplete="false" />
                            )}
                        </Form.Item>
                        {!edit && <Form.Item label="Password">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.Password placeholder="Masukkan password" autoComplete="false" />
                            )}
                        </Form.Item>}
                        <Form.Item label="Tipe">
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih tipe" onChange={v => {
                                    setState({ ...state, type: v })
                                }}>
                                    <Select.Option key='normal' value='normal'>Normal</Select.Option>
                                    <Select.Option key='surveyor' value='surveyor'>Surveyor</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        {state.type === 'surveyor' && <Form.Item label="Leasing">
                            {getFieldDecorator('leasing_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url="/leasing?order=name asc" dataIndex="name" valueIndex="id" showSearch />
                            )}
                        </Form.Item>}
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Telepon">
                            {getFieldDecorator('phone')(
                                <Input placeholder="Masukkan telepon" />
                            )}
                        </Form.Item>
                        <Form.Item label="Email">
                            {getFieldDecorator('email')(
                                <Input placeholder="Masukkan email" />
                            )}
                        </Form.Item>
                        <Form.Item label="Alamat">
                            {getFieldDecorator('address')(
                                <InputTextArea autosize placeholder="Masukkan alamat" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={32}>
                    <Col span={12}>
                        <LabelHeader>Hak akses</LabelHeader>
                        <Transfer style={{ width: '100%' }} dataSource={per.permissionSource} showSearch
                            filterOption={(i, o) => o.name.toUpperCase().indexOf(i.toUpperCase()) >= 0}
                            targetKeys={state.selectedPermission}
                            onChange={keys => setState({ ...state, selectedPermission: keys })}
                            render={item => item.name} />
                        <Row style={{ marginTop: 16 }}>
                            <Col md={6}>
                                <Typography.Text>Template</Typography.Text>
                            </Col>
                            <Col md={18}>
                                {templates.map(v => <Tag onClick={() => onTemplateClick(v.data)} color="blue">{v.text}</Tag>)}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <LabelHeader>Target</LabelHeader>
                        <Form.Item label="Target Prospek">
                            {getFieldDecorator('target_prospek')(
                                <InputNumber placeholder="Masukkan target prospek" />
                            )}
                        </Form.Item>
                        <Form.Item label="Target SPK">
                            {getFieldDecorator('target_spk')(
                                <InputNumber placeholder="Masukkan target spk" />
                            )}
                        </Form.Item>
                        <Form.Item label="Target Penjualan">
                            {getFieldDecorator('target_sale')(
                                <InputNumber placeholder="Masukkan target penjualan" />
                            )}
                        </Form.Item>

                    </Col>
                </Row>
                <Divider />
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="AHM Sales ID">
                            {getFieldDecorator('ahm_id_sales_person')(
                                <InputText placeholder="Masukkan AHM sales ID" />
                            )}
                        </Form.Item>
                        <Form.Item label="Nama AHM">
                            {getFieldDecorator('ahm_name')(
                                <InputText placeholder="Masukkan nama AHM" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/user')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_user' })(Add);