import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Row, Col, Statistic, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import { formatDate, filterDate } from '../../component/util'
import Fetch from '../../Fetch';
import { stockStatus, stockStatusToString, stockStatusToColor } from '../../constant'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/stock/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/stock/add`);
                }}>Tambah</Button>
                <Button icon="upload" href={`/stock/import`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/stock/import`);
                }}>Import</Button>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, pagination }, tb] = useTable('/stock', { filter: { on_stock: true }, order: { key: 'created_at', order: 'descend' } })
    const [state, setState] = React.useState({ drawerExportVisible: false, loading: true, data: {} })
    const url = tb.genFilterUrl(`/stock/summary`, filter)

    React.useEffect(() => {
        Fetch.get(url).then(v => {
            setState(s => { return { ...s, loading: false, data: v } })
        })
    }, [url])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/stock/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/stock/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/stock/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Stock"} actions={<Actions  {...props} refresh={tb.fetchData} state={state} setState={setState} exportClick={() => {
            let err = filterDate(filter, 'stock.created_at')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/stock/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Row gutter={8}>
                <Col md={6}>
                    <Statistic title="Stock Tersedia" value={state.loading ? 'loading...' : state.data.total || 0} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col md={6}>
                    <Statistic title="Filtered" value={state.loading ? 'loading...' : pagination.total} decimalSeparator="," groupSeparator="." />
                </Col>
            </Row><br />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'stock.created_at', type: "startend" })} {...tb.sortProps('created_at')} />
                <Table.Column key="on_stock" dataIndex="on_stock" title="On stock" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Stock' : 'Laku'}</Tag>}
                    {...tb.columnProps({
                        key: 'on_stock', type: 'radio', options: [
                            { text: 'On Stock', value: 't' }, { text: 'Terjual', value: 'f' }
                        ], number: false
                    })} />
                <Table.Column key="stock_status" dataIndex="stock_status" title="Status" render={t => <Tag color={stockStatusToColor(t)}>{stockStatusToString(t)}</Tag>}
                    {...tb.columnProps({
                        key: 'stock_status', type: 'radio', options: stockStatus, number: false
                    })} />
                <Table.Column key="purchase.number" dataIndex="purchase.number" title="No SO" {...tb.sortProps('purchase.number')} />
                <Table.Column key="purchase.date" dataIndex="purchase.date" title="Tanggal SO" render={formatDate} {...tb.sortProps('purchase.date')} />
                <Table.Column key="year" dataIndex="year" title="Tahun"  {...tb.columnProps({ key: 'year', hint: 'Cari tahun' })} {...tb.sortProps('year')} />
                <Table.Column key="rfs" dataIndex="rfs" title="RFS" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                <Table.Column key="motor.name" dataIndex="motor.name" title="Jenis SMH"  {...tb.columnProps({ key: 'motor.name', hint: 'Cari nama motor' })} {...tb.sortProps('motor.name')} />
                <Table.Column key="motor.code" dataIndex="motor.code" title="Tipe" {...tb.sortProps('motor.code')} />
                <Table.Column key="color.name" dataIndex="color.name" title="Warna" />
                <Table.Column key="machine_number" dataIndex="machine_number" title="No Mesin"  {...tb.columnProps({ key: 'machine_number', hint: 'Cari no mesin' })} />
                <Table.Column key="chasis_number" dataIndex="chasis_number" title="No Rangka"  {...tb.columnProps({ key: 'chasis_number', hint: 'Cari no rangka' })} />
                <Table.Column key="sender" dataIndex="sender" title="Pengirim"  {...tb.columnProps({ key: 'sender', hint: 'Cari pengirim' })} />
                <Table.Column key="stocklocation" dataIndex="stock_location" title="Lokasi" render={(t, r) => {
                    return t === null ? 'DEALER' : r.stock_sent == null ? t.name : `${t.name} - ${r.stock_sent.number}`;
                }} />
                <Table.Column key="service_book" dataIndex="service_book" title="Buku servis" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                <Table.Column key="helm" dataIndex="helm" title="Helm" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                <Table.Column key="accu" dataIndex="accu" title="Aki" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                <Table.Column key="mirror" dataIndex="mirror" title="Spion" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                <Table.Column key="toolkit" dataIndex="toolkit" title="Toolkit" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;