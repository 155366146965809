import React, { Fragment } from 'react';
import { Card, Table, Tag } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link'
import useTable from '../../component/table';
import { LabelHeader } from '../../component/header';
import { formatDate } from '../../component/util';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/stocksent/${props.match.params.id}`);
    const [, tb] = useTable(`/stocksentdata`, {}, { key: 't_' }, true)
    const col = { labelSpan: 8, valueSpan: 16 }

    React.useEffect(() => {
        if (record.id)
            tb.setFilter({ 'stock_sent_data.stock_sent_id': record.id })
    }, [record])

    return (
        <Card loading={loading} title={<Title title={"Detail pengiriman stock"}
            actions={<Action showPrint dataId={record.id || parseInt(1)} reload={fetchData} title="Kirim stock" url="stocksent" />} />} >
            {!loading &&
                <Fragment>
                    <TextField {...col} label="Tanggal">{formatDate(record.created_at)}</TextField>
                    <TextField {...col} label="Asal">{record.from ? <MyLink to={`/stocklocation/${record.from.id}`}>{record.from.name}</MyLink> : ''}</TextField>
                    <TextField {...col} label="Tujuan">{record.to ? <MyLink to={`/stocklocation/${record.to.id}`}>{record.to.name}</MyLink> : ''}</TextField>
                    <div style={{ marginTop: 30 }} />
                    <LabelHeader>Motor yang dikirim</LabelHeader>
                    <Table {...tb.tableProps}>
                        <Table.Column key="motor" dataIndex="motor.name" title="Motor" />
                        <Table.Column key="color" dataIndex="color.name" title="Warna" />
                        <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No mesin" />
                        <Table.Column key="chasis_number" dataIndex="stock.chasis_number" title="No rangka" />
                        <Table.Column key="on_stock" dataIndex="stock.on_stock" title="On Stock" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Stock' : 'Laku'}</Tag>} />
                    </Table>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;