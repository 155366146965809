import React from 'react';
import { Card, Table, Button, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { formatDate } from '../../component/util'
import Fetch from '../../Fetch';
import { GlobalStore } from '../../store/store'

const List = props => {
    const [, tb] = useTable('/notification?order=created_at desc', {})
    const [{ global }, dispatch] = React.useContext(GlobalStore);

    const open = (record) => {
        switch (record.typ) {
            case 'prospek':
                window.open(`/prospek/${record.ref_id}`, '_blank')
                break;
            case 'spk':
                window.open(`/spk/${record.ref_id}`, '_blank')
                break;
            default:
                break;
        }
        Fetch.put(`/notification/${record.id}`, {}).then(() => {
            Fetch.get(`/notification/count`).then(r => dispatch({ type: 'SET_NOTIFICATION', payload: r.count }))
        })
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                open(record)
            }
        }
    }

    const setOpenAll = () => {
        Fetch.post(`/notification/all`, {}).then(() => {
            Fetch.get(`/notification/count`).then(r => dispatch({ type: 'SET_NOTIFICATION', payload: r.count }))
            tb.fetchData()
        })
    }

    const actions = <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Button.Group>
            {global.notification > 0 && <Button icon="check" onClick={() => setOpenAll()} >Tandai dibaca semua</Button>}
            <Button icon="reload" onClick={() => tb.fetchData()} />
        </Button.Group>
    </div>;

    return (
        <Card title={<Title title={"Daftar notifikasi"} actions={actions} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={t => formatDate(t, false)} />
                <Table.Column key="title" dataIndex="title" title="Judul"  {...tb.columnProps({ key: 'title', hint: 'Cari judul' })} />
                <Table.Column key="body" dataIndex="body" title="Isi"  {...tb.columnProps({ key: 'body', hint: 'Cari isi' })} />
                <Table.Column key="opened" dataIndex="opened" title="" render={(t, r) => !t ? <Tag color='green' onClick={() => open(r)}>Baru</Tag> : ''} width={40} />
            </Table>
        </Card >
    )
}

export default List;