import React from 'react';
import { Table, Tag, Button } from 'antd';
import useTable from '../../component/table';
import { MyLink, } from '../../component/link';
import { formatDate } from '../../component/util';
import Fetch from '../../Fetch'

const List = props => {
    const [, tb] = useTable(`/crm?${props.filter}`, {})

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/crm/${record.id}`, "_blank")
            }
        }
    }

    return (
        <React.Fragment>
            <div style={{ paddingBottom: 16, textAlign: 'right' }}>
                <Button icon="download" onClick={() => {
                    const url = tb.genFilterUrl(`/crm/export?${props.filter}&limit=-1&start=0&access_token=${localStorage.getItem('token')}`, undefined)
                    window.open(Fetch.getUrl(url))
                }}>Export</Button>
            </div>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="delivery.created_at" dataIndex="delivery.created_at" title="Tanggal DO" render={formatDate} {...tb.sortProps('delivery.created_at')} />
                <Table.Column key="spk.buyer_name" dataIndex="spk.buyer_name" title="Pembeli" {...tb.sortProps('spk.buyer_name')} />
                <Table.Column key="spk.buyer_phone" dataIndex="spk.buyer_phone" title="Telepon" />
                <Table.Column key="spk.buyer_whatsapp" dataIndex="spk.buyer_whatsapp" title="Whatsapp" />
                <Table.Column key="motor.name" dataIndex="motor.name" title="Motor" {...tb.sortProps('motor.name')} />
                <Table.Column key="color.name" dataIndex="color.name" title="Warna" />
                <Table.Column key="status" dataIndex={props.statusIndex} title="Status"
                    render={r => <Tag color={r ? 'green' : 'red'}>{r ? 'Selesai' : 'Belum'}</Tag>} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <MyLink to={`/crm/${r.id}`}>Detail</MyLink>
                }} />
            </Table>
        </React.Fragment>
    )
}

export default List;