import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import DefaultRoute from './DefaultRoute';
import PageLogin from './page/login';
import PageProfile from './page/profile/profile';
import PageDashboard from './page/dashboard/dashboard';
import PageBank from './page/bank/list';
import PageBankAdd from './page/bank/add';
import PageSPKSource from './page/spksource/list';
import PageSPKSourceAdd from './page/spksource/add';
import PageLeasing from './page/leasing/list';
import PageLeasingAdd from './page/leasing/add';
import PageLeasingDetail from './page/leasing/detail';
import PageLeasingGroup from './page/leasing/group'
import PageLeasingGroupAdd from './page/leasing/groupadd'
import PageLeasingGroupDetail from './page/leasing/groupdetail'
import PageMasterPrice from './page/masterprice/list';
import PageMasterPriceAdd from './page/masterprice/add';
import PageMasterPriceDetail from './page/masterprice/detail'
import PageMotor from './page/motor/list'
import PageMotorAdd from './page/motor/add'
import PageMotorDetail from './page/motor/detail'
import PageUser from './page/user/list'
import PageUserAdd from './page/user/add'
import PageUserDetail from './page/user/detail'
import PageTeam from './page/team/list'
import PageTeamAdd from './page/team/add'
import PageTeamDetail from './page/team/detail'
import PageStockLocation from './page/stocklocation/list'
import PageStockLocationAdd from './page/stocklocation/add'
import PageStockLocationDetail from './page/stocklocation/detail'
import PageStock from './page/stock/list'
import PageStockAdd from './page/stock/add'
import PageStockDetail from './page/stock/detail'
import PageStockImport from './page/stock/import'
import PageStockSend from './page/stocksend/list'
import PageStockSendAdd from './page/stocksend/add'
import PageStockSendDetail from './page/stocksend/detail'
import PageStockSJ from './page/stock/send'
import PageProspek from './page/prospek/list'
import PageProspekAdd from './page/prospek/add'
import PageProspekDetail from './page/prospek/detail'
import PageSPK from './page/spk/list'
import PageSPKAdd from './page/spk/add'
import PageSpkDetail from './page/spk/detail'
import PageDeliveryAdd from './page/delivery/add'
import PageDelivery from './page/delivery/list'
import PageDeliveryDetail from './page/delivery/detail'
import PageIndent from './page/indent/list'
import PagePO from './page/po/list'
import PageClaim from './page/claim/list'
import PageClaimEdit from './page/claim/edit'
import PageClaimDetail from './page/claim/detail'
import PageClaimReceipt from './page/claimreceipt/list'
import PageClaimReceiptDetail from './page/claimreceipt/detail'
import PageClaimReceiptAdd from './page/claimreceipt/add'
import PageClaimOutstanding from './page/claim/listoutstanding'
import PageSTNK from './page/stnk/list'
import PageSTNKStatus from './page/stnk/status'
import PageSTNKEdit from './page/stnk/edit'
import PageSTNKDetail from './page/stnk/detail'
import PageBPKB from './page/bpkb/list'
import PageBPKBAdd from './page/bpkb/add'
import PageBPKBEdit from './page/bpkb/edit'
import PageBPKBDetail from './page/bpkb/detail'
import PageBPKBReceipt from './page/bpkbreceipt/list'
import PageBPKBReceiptAdd from './page/bpkbreceipt/add'
import PageBPKBReceiptDetail from './page/bpkbreceipt/detail'
import PageSale from './page/sale/list'
import PageSaleEdit from './page/sale/edit'
import PageStockValue from './page/stock/valuelist'
import PageSpkPayment from './page/money/list'
import PageSpkPaymentCashierReady from './page/money/moneyatcashier'
import PageSpkPaymentNotReady from './page/money/moneynotready'
import PageMoneyClose from './page/money/closing'
import PageMoneyCloseDetail from './page/money/closingdetail'
import PageMoneyCash from './page/money/cash'
import PageMoneyCashList from './page/money/savecash'
import PageMoneyCashDetail from './page/money/savecashdetail'
import PageMoneyCashEdit from './page/money/savecashedit'
import PagePendingPayment from './page/pendingpayment/list'
import PagePerformance from './page/performance/home'
import PagePurchaseAdd from './page/purchase/add'
import PageNotification from './page/notification/list'
import PageCustomerList from './page/customer/list'
import PageCustomerEdit from './page/customer/edit'
import PageCustomerJob from './page/customerjob/list'
import PageCustomerJobAdd from './page/customerjob/add'
import PageCustomerExpense from './page/customerexpense/list'
import PageCustomerExpenseAdd from './page/customerexpense/add'
import PageCustomerDetail from './page/customer/detail'
import PageRegionList from './page/region/list'
import PageRegionEdit from './page/region/add'
import PageSubsidyList from './page/subsidy/list'
import PageSubsidyAdd from './page/subsidy/add'
import PageCrm from './page/crm/all'
import PageCrmDetail from './page/crm/detail'
import PageBiro from './page/biro/list'
import PageBiroAdd from './page/biro/add'
import PageBiroDetail from './page/biro/detail'
import PageBiroPayment from './page/biropayment/list'
import PageBiroPaymentAdd from './page/biropayment/add'
import PageBiroPaymentDetail from './page/biropayment/detail'
import PageLeasingIntensiveList from './page/leasingintensive/list'
import PageSubsidiWms from './page/subsidiwms/list'
import PageSubsidiWmsDetail from './page/subsidiwms/detail'
import PageSubsidiWmsEdit from './page/subsidiwms/edit'
import PageSubsidiWmsInvoice from './page/subsidiwms/invoice'
import PageSubsidiWmsInvoiceAdd from './page/subsidiwms/invoiceadd'
import PageSubsidiWmsInvoiceDetail from './page/subsidiwms/invoicedetail'
import PageSubsidiWmsReceipt from './page/subsidiwms/receipt'
import PageSubsidiWmsReceiptAdd from './page/subsidiwms/receiptadd'
import PageSubsidiWmsReceiptDetail from './page/subsidiwms/receiptdetail'
import PageSubsidiAhm from './page/subsidiahm/list'
import PageSubsidiAhmDetail from './page/subsidiahm/detail'
import PageSubsidiAhmEdit from './page/subsidiahm/edit'
import PageSubsidiAhmInvoice from './page/subsidiahm/invoice'
import PageSubsidiAhmInvoiceAdd from './page/subsidiahm/invoiceadd'
import PageSubsidiAhmInvoiceDetail from './page/subsidiahm/invoicedetail'
import PageSubsidiAhmReceipt from './page/subsidiahm/receipt'
import PageSubsidiAhmReceiptAdd from './page/subsidiahm/receiptadd'
import PageSubsidiAhmReceiptDetail from './page/subsidiahm/receiptdetail'
import PageSubsidiLeasing from './page/subsidileasing/list'
import PageSubsidiLeasingDetail from './page/subsidileasing/detail'
import PageSubsidiLeasingEdit from './page/subsidileasing/edit'
import PageSubsidiLeasingInvoice from './page/subsidileasing/invoice'
import PageSubsidiLeasingInvoiceAdd from './page/subsidileasing/invoiceadd'
import PageSubsidiLeasingInvoiceDetail from './page/subsidileasing/invoicedetail'
import PageSubsidiLeasingReceipt from './page/subsidileasing/receipt'
import PageSubsidiLeasingReceiptAdd from './page/subsidileasing/receiptadd'
import PageSubsidiLeasingReceiptDetail from './page/subsidileasing/receiptdetail'
import PageStamp from './page/stamp/stamp'

import LoginModal from './page/loginmodal'
import Permission, { P_DASHBAORD } from './permission'
import { StoreProvider } from './store/store';
import { Empty } from 'antd';

const CheckLogin = () => {
  const token = localStorage.getItem('token');
  if (token === null || token === "") {
    return <Redirect to="/login" />
  }
  if (Permission.hasPermission(P_DASHBAORD)) return <Redirect to="/dashboard" />
  return <Redirect to="/performance" />
}

const NotFound = () => {
  return <div style={{ marginTop: 100 }}>
    <Empty description={"Halaman tidak ditemukan"} />
  </div>
}

class App extends Component {
  render() {
    return (
      <StoreProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={CheckLogin} />
            <Route exact path="/login" component={PageLogin} />
            <DefaultRoute path="/dashboard" component={PageDashboard} />
            <DefaultRoute path="/profile" component={PageProfile} />
            <DefaultRoute exact path="/bank" component={PageBank} />
            <DefaultRoute exact path="/bank/add" component={PageBankAdd} />
            <DefaultRoute exact path="/bank/:id/edit" component={PageBankAdd} />
            <DefaultRoute exact path="/spksource" component={PageSPKSource} />
            <DefaultRoute exact path="/spksource/add" component={PageSPKSourceAdd} />
            <DefaultRoute exact path="/spksource/:id/edit" component={PageSPKSourceAdd} />
            <DefaultRoute exact path="/leasing" component={PageLeasing} />
            <DefaultRoute exact path="/leasing/add" component={PageLeasingAdd} />
            <DefaultRoute exact path="/leasing/:id/edit" component={PageLeasingAdd} />
            <DefaultRoute exact path="/leasing/:id" component={PageLeasingDetail} />
            <DefaultRoute exact path="/leasinggroup" component={PageLeasingGroup} />
            <DefaultRoute exact path="/leasinggroup/add" component={PageLeasingGroupAdd} />
            <DefaultRoute exact path="/leasinggroup/:id" component={PageLeasingGroupDetail} />
            <DefaultRoute exact path="/leasinggroup/:id/edit" component={PageLeasingGroupAdd} />
            <DefaultRoute exact path="/masterprice" component={PageMasterPrice} />
            <DefaultRoute exact path="/masterprice/add" component={PageMasterPriceAdd} />
            <DefaultRoute exact path="/masterprice/:id/edit" component={PageMasterPriceAdd} />
            <DefaultRoute exact path="/masterprice/:id" component={PageMasterPriceDetail} />
            <DefaultRoute exact path="/user" component={PageUser} />
            <DefaultRoute exact path="/user/add" component={PageUserAdd} />
            <DefaultRoute exact path="/user/:id/edit" component={PageUserAdd} />
            <DefaultRoute exact path="/user/:id" component={PageUserDetail} />
            <DefaultRoute exact path="/team" component={PageTeam} />
            <DefaultRoute exact path="/team/add" component={PageTeamAdd} />
            <DefaultRoute exact path="/team/:id/edit" component={PageTeamAdd} />
            <DefaultRoute exact path="/team/:id" component={PageTeamDetail} />
            <DefaultRoute exact path="/stocklocation" component={PageStockLocation} />
            <DefaultRoute exact path="/stocklocation/add" component={PageStockLocationAdd} />
            <DefaultRoute exact path="/stocklocation/:id/edit" component={PageStockLocationAdd} />
            <DefaultRoute exact path="/stocklocation/:id" component={PageStockLocationDetail} />
            <DefaultRoute exact path="/stock" component={PageStock} />
            <DefaultRoute exact path="/stock/add" component={PageStockAdd} />
            <DefaultRoute exact path="/stock/import" component={PageStockImport} />
            <DefaultRoute exact path="/stock/:id/edit" component={PageStockAdd} />
            <DefaultRoute exact path="/stock/:id" component={PageStockDetail} />
            <DefaultRoute exact path="/stocksj/:id" component={PageStockSJ} />
            <DefaultRoute exact path="/stocksent" component={PageStockSend} />
            <DefaultRoute exact path="/stocksent/add" component={PageStockSendAdd} />
            <DefaultRoute exact path="/stocksent/:id/edit" component={PageStockSendAdd} />
            <DefaultRoute exact path="/stocksent/:id/take" component={PageStockSendAdd} />
            <DefaultRoute exact path="/stocksent/:id" component={PageStockSendDetail} />
            <DefaultRoute exact path="/prospek" component={PageProspek} />
            <DefaultRoute exact path="/prospek/add" component={PageProspekAdd} />
            <DefaultRoute exact path="/prospek/:id/edit" component={PageProspekAdd} />
            <DefaultRoute exact path="/prospek/:id" component={PageProspekDetail} />
            <DefaultRoute exact path="/prospek/:id/spk" component={PageSPKAdd} />
            <DefaultRoute exact path="/spk" component={PageSPK} />
            <DefaultRoute exact path="/spk/add" component={PageSPKAdd} />
            <DefaultRoute exact path="/spk/:id/edit" component={PageSPKAdd} />
            <DefaultRoute exact path="/spk/:id" component={PageSpkDetail} />
            <DefaultRoute exact path="/motor" component={PageMotor} />
            <DefaultRoute exact path="/motor/add" component={PageMotorAdd} />
            <DefaultRoute exact path="/motor/:id" component={PageMotorDetail} />
            <DefaultRoute exact path="/motor/:id/edit" component={PageMotorAdd} />
            <DefaultRoute exact path="/spk/:id/delivery" component={PageDeliveryAdd} />
            <DefaultRoute exact path="/delivery" component={PageDelivery} />
            <DefaultRoute exact path="/delivery/:id" component={PageDeliveryDetail} />
            <DefaultRoute exact path="/indent" component={PageIndent} />
            <DefaultRoute exact path="/po" component={PagePO} />
            <DefaultRoute exact path="/claim" component={PageClaim} />
            <DefaultRoute exact path="/claim/:id/edit" component={PageClaimEdit} />
            <DefaultRoute exact path="/claim/:id" component={PageClaimDetail} />
            <DefaultRoute exact path="/claimoutstanding" component={PageClaimOutstanding} />
            <DefaultRoute exact path="/claimreceipt" component={PageClaimReceipt} />
            <DefaultRoute exact path="/claimreceipt/add" component={PageClaimReceiptAdd} />
            <DefaultRoute exact path="/claimreceipt/:id" component={PageClaimReceiptDetail} />
            <DefaultRoute exact path="/claimreceipt/:id/edit" component={PageClaimReceiptAdd} />
            <DefaultRoute exact path="/stnk" component={PageSTNK} />
            <DefaultRoute exact path="/stnk/:id/edit" component={PageSTNKEdit} />
            <DefaultRoute exact path="/stnk/:id/status" component={PageSTNKStatus} />
            <DefaultRoute exact path="/stnk/:id" component={PageSTNKDetail} />
            <DefaultRoute exact path="/bpkb" component={PageBPKB} />
            <DefaultRoute exact path="/bpkb/add" component={PageBPKBAdd} />
            <DefaultRoute exact path="/bpkb/:id/edit" component={PageBPKBEdit} />
            <DefaultRoute exact path="/bpkb/:id" component={PageBPKBDetail} />
            <DefaultRoute exact path="/bpkbreceipt" component={PageBPKBReceipt} />
            <DefaultRoute exact path="/bpkbreceipt/add" component={PageBPKBReceiptAdd} />
            <DefaultRoute exact path="/bpkbreceipt/:id" component={PageBPKBReceiptDetail} />
            <DefaultRoute exact path="/bpkbreceipt/:id/edit" component={PageBPKBReceiptAdd} />
            <DefaultRoute exact path="/sale" component={PageSale} />
            <DefaultRoute exact path="/sale/:id/edit" component={PageSaleEdit} />
            <DefaultRoute exact path="/stockvalue" component={PageStockValue} />
            <DefaultRoute exact path="/money" component={PageSpkPayment} />
            <DefaultRoute exact path="/moneycashierready" component={PageSpkPaymentCashierReady} />
            <DefaultRoute exact path="/moneynotready" component={PageSpkPaymentNotReady} />
            <DefaultRoute exact path="/moneyclose" component={PageMoneyClose} />
            <DefaultRoute exact path="/moneyclose/:id" component={PageMoneyCloseDetail} />
            <DefaultRoute exact path="/moneycash" component={PageMoneyCash} />
            <DefaultRoute exact path="/savecash" component={PageMoneyCashList} />
            <DefaultRoute exact path="/savecash/:id" component={PageMoneyCashDetail} />
            <DefaultRoute exact path="/savecash/:id/edit" component={PageMoneyCashEdit} />
            <DefaultRoute exact path="/pendingpayment" component={PagePendingPayment} />
            <DefaultRoute exact path="/performance" component={PagePerformance} />
            <DefaultRoute exact path="/purchase/add" component={PagePurchaseAdd} />
            <DefaultRoute exact path="/notification" component={PageNotification} />
            <DefaultRoute exact path="/customer" component={PageCustomerList} />
            <DefaultRoute exact path="/customer/:id/add" component={PageCustomerEdit} />
            <DefaultRoute exact path="/customer/:id/edit" component={PageCustomerEdit} />
            <DefaultRoute exact path="/customer/:id" component={PageCustomerDetail} />
            <DefaultRoute exact path="/customerjob" component={PageCustomerJob} />
            <DefaultRoute exact path="/customerjob/add" component={PageCustomerJobAdd} />
            <DefaultRoute exact path="/customerjob/:id/edit" component={PageCustomerJobAdd} />
            <DefaultRoute exact path="/customerexpense" component={PageCustomerExpense} />
            <DefaultRoute exact path="/customerexpense/add" component={PageCustomerExpenseAdd} />
            <DefaultRoute exact path="/customerexpense/:id/edit" component={PageCustomerExpenseAdd} />
            <DefaultRoute exact path="/region" component={PageRegionList} />
            <DefaultRoute exact path="/region/add" component={PageRegionEdit} />
            <DefaultRoute exact path="/region/:id/edit" component={PageRegionEdit} />
            <DefaultRoute exact path="/wms" component={PageSubsidyList} />
            <DefaultRoute exact path="/wms/add" component={PageSubsidyAdd} />
            <DefaultRoute exact path="/wms/:id/edit" component={PageSubsidyAdd} />
            <DefaultRoute exact path="/ahm" component={PageSubsidyList} />
            <DefaultRoute exact path="/ahm/add" component={PageSubsidyAdd} />
            <DefaultRoute exact path="/ahm/:id/edit" component={PageSubsidyAdd} />
            <DefaultRoute exact path="/crm" component={PageCrm} />
            <DefaultRoute exact path="/crm/:id" component={PageCrmDetail} />
            <DefaultRoute exact path="/biro" component={PageBiro} />
            <DefaultRoute exact path="/biro/add" component={PageBiroAdd} />
            <DefaultRoute exact path="/biro/:id" component={PageBiroDetail} />
            <DefaultRoute exact path="/biro/:id/edit" component={PageBiroAdd} />
            <DefaultRoute exact path="/biropayment" component={PageBiroPayment} />
            <DefaultRoute exact path="/biropayment/add" component={PageBiroPaymentAdd} />
            <DefaultRoute exact path="/biropayment/:id" component={PageBiroPaymentDetail} />
            <DefaultRoute exact path="/biropayment/:id/edit" component={PageBiroPaymentAdd} />
            <DefaultRoute exact path="/leasingintensive" component={PageLeasingIntensiveList} />
            <DefaultRoute exact path="/subsidiwms" component={PageSubsidiWms} />
            <DefaultRoute exact path="/subsidiwms/:id" component={PageSubsidiWmsDetail} />
            <DefaultRoute exact path="/subsidiwms/:id/edit" component={PageSubsidiWmsEdit} />
            <DefaultRoute exact path="/subsidiwmsinvoice" component={PageSubsidiWmsInvoice} />
            <DefaultRoute exact path="/subsidiwmsinvoice/add" component={PageSubsidiWmsInvoiceAdd} />
            <DefaultRoute exact path="/subsidiwmsinvoice/:id" component={PageSubsidiWmsInvoiceDetail} />
            <DefaultRoute exact path="/subsidiwmsinvoice/:id/edit" component={PageSubsidiWmsInvoiceAdd} />
            <DefaultRoute exact path="/subsidiwmsreceipt" component={PageSubsidiWmsReceipt} />
            <DefaultRoute exact path="/subsidiwmsreceipt/add" component={PageSubsidiWmsReceiptAdd} />
            <DefaultRoute exact path="/subsidiwmsreceipt/:id" component={PageSubsidiWmsReceiptDetail} />
            <DefaultRoute exact path="/subsidiwmsreceipt/:id/edit" component={PageSubsidiWmsReceiptAdd} />
            <DefaultRoute exact path="/subsidiahm" component={PageSubsidiAhm} />
            <DefaultRoute exact path="/subsidiahm/:id" component={PageSubsidiAhmDetail} />
            <DefaultRoute exact path="/subsidiahm/:id/edit" component={PageSubsidiAhmEdit} />
            <DefaultRoute exact path="/subsidiahminvoice" component={PageSubsidiAhmInvoice} />
            <DefaultRoute exact path="/subsidiahminvoice/add" component={PageSubsidiAhmInvoiceAdd} />
            <DefaultRoute exact path="/subsidiahminvoice/:id" component={PageSubsidiAhmInvoiceDetail} />
            <DefaultRoute exact path="/subsidiahminvoice/:id/edit" component={PageSubsidiAhmInvoiceAdd} />
            <DefaultRoute exact path="/subsidiahmreceipt" component={PageSubsidiAhmReceipt} />
            <DefaultRoute exact path="/subsidiahmreceipt/add" component={PageSubsidiAhmReceiptAdd} />
            <DefaultRoute exact path="/subsidiahmreceipt/:id" component={PageSubsidiAhmReceiptDetail} />
            <DefaultRoute exact path="/subsidiahmreceipt/:id/edit" component={PageSubsidiAhmReceiptAdd} />
            <DefaultRoute exact path="/subsidileasing" component={PageSubsidiLeasing} />
            <DefaultRoute exact path="/subsidileasing/:id/edit" component={PageSubsidiLeasingEdit} />
            <DefaultRoute exact path="/subsidileasing/:id/:date" component={PageSubsidiLeasingDetail} />
            <DefaultRoute exact path="/subsidileasinginvoice" component={PageSubsidiLeasingInvoice} />
            <DefaultRoute exact path="/subsidileasinginvoice/add" component={PageSubsidiLeasingInvoiceAdd} />
            <DefaultRoute exact path="/subsidileasinginvoice/:id" component={PageSubsidiLeasingInvoiceDetail} />
            <DefaultRoute exact path="/subsidileasinginvoice/:id/edit" component={PageSubsidiLeasingInvoiceAdd} />
            <DefaultRoute exact path="/subsidileasingreceipt" component={PageSubsidiLeasingReceipt} />
            <DefaultRoute exact path="/subsidileasingreceipt/add" component={PageSubsidiLeasingReceiptAdd} />
            <DefaultRoute exact path="/subsidileasingreceipt/:id" component={PageSubsidiLeasingReceiptDetail} />
            <DefaultRoute exact path="/subsidileasingreceipt/:id/edit" component={PageSubsidiLeasingReceiptAdd} />
            <DefaultRoute exact path="/stamp" component={PageStamp} />

            <DefaultRoute path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
        <LoginModal />
      </StoreProvider>
    );
  }
}

export default App;



