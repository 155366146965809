import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, DatePicker, Typography, Table, Input } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { InputText, InputTextArea } from '../../component/input';
import { formatMoney } from '../../component/util';
import moment from 'moment'

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [tableData, setTableData] = React.useState([])
    const [selected, setSelected] = React.useState([])
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, total: 0, search: '' });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    if (selected.length === 0) {
                        message.error("STNK tidak boleh kosong")
                        return;
                    }
                    setState({ ...state, saveLoading: true })
                    const val = { ...values, value: state.total, stnk: selected.map(v => v.id) };
                    if (edit) {
                        await Fetch.put(`/biro/${props.match.params.id}`, val);
                        message.info('Tagihan biro jasa berhasil diedit');
                    } else {
                        await Fetch.post('/biro', val);
                        message.info('Tagihan biro jasa berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/biro');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
        fetchTableData();

    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/biro/${props.match.params.id}`);
        setState({ ...state, loading: false, total: data.value });
        setFieldsValue({
            number: data.number,
            note: data.note,
            date: data.date ? moment(data.date) : undefined,
        });
        const sel = await Fetch.get(`/stnk?filter=(biro_id,,eq,,${data.id})`)
        setSelected(sel.data)
    }

    const fetchTableData = async () => {
        const data = await Fetch.get('/stnk?filter=(stnk.total,,>,,0)(stnk.status,,neq,,new)(biro_id,,eq,,0)&limit=1000')
        setTableData(data.data)
    }

    const onSearchChanged = v => {
        setState({ ...state, search: v.target.value.toUpperCase() })
    }

    return (
        <Card title={<Title title={edit ? "Edit biro jasa" : "Tambah biro jasa"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tanggal">
                            {getFieldDecorator('date', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker placeholder="Masukkan tanggal" format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                        <Form.Item label="Nomor">
                            {getFieldDecorator('number', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputText placeholder="Masukkan nomor" />
                            )}
                        </Form.Item>

                    </Col>
                    <Col span={12}>
                        <Form.Item label="Catatan">
                            {getFieldDecorator('note')(
                                <InputTextArea placeholder="Masukkan catatan" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={32}>
                    <Col span={12}>
                        <Typography.Text strong>Daftar STNK</Typography.Text>
                        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                        <Input placeholder="Cari no polisi / no mesin" onChange={onSearchChanged} allowClear />
                        <div style={{ marginTop: 10 }} />
                        <Table rowKey="id" dataSource={state.search === '' ? tableData : tableData.filter(v => {
                            console.log(v.plate_number.toUpperCase())
                            return v.plate_number.toUpperCase().includes(state.search) ||
                                v.stock.machine_number.toUpperCase().includes(state.search)
                        })}
                            size="small" pagination={false}
                            onRow={r => {
                                return {
                                    onDoubleClick: () => {
                                        const index = selected.findIndex(v => v.id === r.id)
                                        if (index < 0) {
                                            let total = r.total;
                                            for (let i = 0; i < selected.length; i++)
                                                total += selected[i].total;
                                            setState({ ...state, total: total })
                                            setSelected([...selected, r])
                                            setTableData(tableData.filter(v => v.id !== r.id))
                                        }
                                        else message.error("STNK sudah terpilih")
                                    }
                                }
                            }}>
                            <Table.Column key="plate_number" dataIndex="plate_number" title="No polisi" />
                            <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No mesin" />
                            <Table.Column key="total" dataIndex="total" title="Total" render={formatMoney} />
                        </Table>
                    </Col>
                    <Col span={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography.Text strong>STNK terpilih</Typography.Text>
                            <Typography.Text strong>{formatMoney(state.total)}</Typography.Text>
                        </div>
                        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                        <Table rowKey="id" dataSource={selected} size="small" pagination={false}
                            onRow={r => {
                                return {
                                    onDoubleClick: () => {
                                        setSelected(selected.filter(v => v.id !== r.id))
                                        setState({ ...state, total: state.total - r.total })
                                        const index = tableData.findIndex(v => v.id === r.id)
                                        if (index < 0) setTableData([...tableData, r])
                                    }
                                }
                            }}>
                            <Table.Column key="plate_number" dataIndex="plate_number" title="No polisi" />
                            <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No mesin" />
                            <Table.Column key="total" dataIndex="total" title="Total" render={formatMoney} />
                        </Table>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/bank')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading} > Simpan </Button>
                </Form.Item>
            </Form>
        </Card >
    );
}

export default Form.create({ name: 'create' })(Add);