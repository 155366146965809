import React from 'react';
import { Card, Row, Col, Tag, Tabs, Statistic, Table, Divider } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { useTab } from '../../component/tab';
import useTable from '../../component/table';
import { formatDate, getDefaultCurYear } from '../../component/util'
import { MyLink } from '../../component/link';
import { surveyStatusToString, surveyStatusToColor, surveyStatus } from '../../constant'

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/leasing/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const tab = useTab(props, 'tab', 'info')
    const [{ pagination }, tb] = useTable('/spkleasingsnapshot', {}, undefined, true)

    React.useEffect(() => {
        if (record.id) tb.setFilter({ 'spk_leasing_snapshot.leasing_id': record.id, 'spk_leasing_snapshot.created_at': getDefaultCurYear() })
    }, [record])

    const renderValue = v => <div style={{ width: '100%', textAlign: 'right', paddingRight: 50 }}>
        {v.toLocaleString('in-ID')}
    </div>

    return (
        <Card loading={loading} title={<Title title={"Detail leasing"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Leasing" url="leasing" />} />} >
            {!loading &&
                <Tabs {...tab}>
                    <Tabs.TabPane key="info" tab="Info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Nama">{record.name}</TextField>
                                <TextField {...col} label="Group">{record.leasing_group ? record.leasing_group.name : '-'}</TextField>
                                <TextField {...col} label="Nama lengkap">{record.fullname}</TextField>
                                <TextField {...col} label="Alamat">{record.address}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Kwitansi 1">{record.kwitansi1}</TextField>
                                <TextField {...col} label="Kwitansi 2">{record.kwitansi2}</TextField>
                                <TextField {...col} label="Catatan">{record.note}</TextField>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Subsidi 1">{renderValue(record.subsidi_leasing1)}</TextField>
                                <TextField {...col} label="Subsidi 1 PPh"><Tag color={record.subsidi_leasing1_pph ? 'green' : 'red'}>
                                    {record.subsidi_leasing1_pph ? 'Iya' : 'Tidak'}</Tag></TextField>
                                <TextField {...col} label="Subsidi 2">{renderValue(record.subsidi_leasing2)}</TextField>
                                <TextField {...col} label="Subsidi 2 PPh"><Tag color={record.subsidi_leasing2_pph ? 'green' : 'red'}>
                                    {record.subsidi_leasing2_pph ? 'Iya' : 'Tidak'}</Tag></TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Insentif">{renderValue(record.insentive_leasing)}</TextField>
                                <TextField {...col} label="Insentif PPh"><Tag color={record.insentive_leasing_pph ? 'green' : 'red'}>{record.insentive_leasing_pph ? 'Iya' : 'Tidak'}</Tag></TextField>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="history" tab="Sejarah SPK">
                        <Statistic title="Total penjualan" value={pagination.total} decimalSeparator="," groupSeparator="." /><br />
                        <Table {...tb.tableProps}>
                            <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                                {...tb.columnProps({ key: 'spk_leasing_snapshot.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                            <Table.Column key="number" dataIndex="spk.number" title="Nomor SPK"
                                render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>} />
                            <Table.Column key="spk.buyer_name" dataIndex="spk.buyer_name" title="Nama Pembeli" />
                            <Table.Column key="user" dataIndex="user.name" title="Nama User"
                                render={(t, r) => <MyLink to={`/user/${r.user_id}`}>{t}</MyLink>} />
                            <Table.Column key="surveyor_status" dataIndex="status" title="Status"
                                render={t => <Tag color={surveyStatusToColor(t)}>{surveyStatusToString(t)}</Tag>}
                                {...tb.columnProps({ key: 'survey_status', type: 'radio', options: surveyStatus })} />
                        </Table>
                    </Tabs.TabPane>
                </Tabs>
            }
        </Card >
    );
}

export default Detail;