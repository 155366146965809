import React from 'react';
import Fetch from '../../Fetch';
import { List, Typography, Button, Form, message, Avatar, Divider, Tag } from 'antd';
import { spkNoteTypeToString, spkNoteTypeToColor } from '../../constant'
import { InputTextArea } from '../../component/input';

const Comment = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [state, setState] = React.useState({ loading: true, data: [], saveLoading: false })

    React.useEffect(() => {
        Fetch.get(`/spknote?order=created_at asc&filter=(spk_id,,eq,,${props.spkid})`).then(v => {
            setState({ ...state, loading: false, data: v.data })
        })
    }, [])

    const fetchData = () => {
        setState({ ...state, loading: true })
        Fetch.get(`/spknote?order=created_at asc&filter=(spk_id,,eq,,${props.spkid})`).then(v => {
            setState({ ...state, loading: false, data: v.data })
        })
    }

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (props.spk) await Fetch.post('/spknote/spk', { ...values, spk_id: props.spkid });
                    else await Fetch.post('/prospeknote', { ...values, spk_id: props.spkid });
                    message.info('Komentar berhasil ditambahkan');
                    setState({ ...state, saveLoading: false })
                    setFieldsValue({ 'note': '' })
                    fetchData();
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    return (
        <div>
            <List loading={state.loading} dataSource={state.data} pagination={false} renderItem={item =>
                <List.Item key={item.id}>
                    <div style={{ display: "flex" }}>
                        <Avatar style={{ width: 50, height: 50 }} src={Fetch.getUrl(`/public/userimages/${item.user.photo === 'default.jpg' ? 'placeholder.png' : item.user.photo}`)} />
                        <div style={{ marginLeft: 20 }}>
                            <Typography.Text strong>{item.user.name}</Typography.Text>
                            <div style={{ display: 'flex' }}>
                                <Tag color={spkNoteTypeToColor(item.type)}>{spkNoteTypeToString(item.type)}</Tag>
                                <Typography.Text disabled style={{ fontSize: 12, display: 'block' }}>{(new Date(item.created_at)).toLocaleString('in-ID')}</Typography.Text>
                            </div>
                            <Typography.Text>{item.note}</Typography.Text>
                        </div>
                    </div>
                </List.Item>
            } />
            <Divider style={{ margin: '10px 0' }} />
            <Form layout="horizontal" name="add_comment" onSubmit={formSummited}>
                <Form.Item>
                    {getFieldDecorator('note', {
                        rules: [{ required: true, message: 'Please input your note!' }],
                    })(
                        <InputTextArea style={{ width: '100%' }} placeholder="Masukkan komentar baru" />
                    )}
                </Form.Item>
                <Button icon="save" type="primary" htmlType="submit" loading={state.saveLoading}>Simpan komentar</Button>
            </Form>
        </div>
    )
}

export default Form.create({ name: 'add_comment' })(Comment);