import React, { Fragment } from 'react';
import { Card, Row, Col, Tag, Tabs, Drawer, Form, Button, Select, message } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field'
import { prospekStatusToString, prospekStatusToColor, prospekStatus } from '../../constant'
import { useTab } from '../../component/tab';
import History from './history';
import Comment from './comment'
import Fetch from '../../Fetch';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/prospek/${props.match.params.id}`);
    const { getFieldDecorator } = props.form;
    const [state, setState] = React.useState({ drawerVisible: false, saveLoading: false })
    const col = { labelSpan: 6, valueSpan: 18 }
    const tab = useTab(props, 'tab', 'info')

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.post(`/prospek/status/${record.id}`, values);
                    message.info('Status prospek berhasil di update');
                    fetchData();
                    setState({ ...state, saveLoading: false, drawerVisible: false })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    return (
        <Card loading={loading} title={<Title title={"Detail prospek"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Leasing" url="prospek"
                onUpdateStatus={() => setState({ ...state, drawerVisible: true })} record={record} />} />} >
            {!loading &&
                <Fragment>
                    <Tabs {...tab}>
                        <Tabs.TabPane tab="Info" key="info">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <TextField {...col} label="Nama">{record.buyer_name}</TextField>
                                    <TextField {...col} label="Telepon HP">{record.buyer_phone}</TextField>
                                    <TextField {...col} label="No Whatsapp">{record.buyer_whatsapp}</TextField>
                                    <TextField {...col} label="Telepon Rumah">{record.buyer_home_phone}</TextField>
                                    <TextField {...col} label="Email">{record.buyer_email}</TextField>
                                    {/*<TextField {...col} label="Sumber SPK">{record.spk_source.name}</TextField>*/}
                                    <TextField {...col} label="Status">{<Tag color={prospekStatusToColor(record.prospek_status)}>{prospekStatusToString(record.prospek_status)}</Tag>}</TextField>
                                </Col>
                                <Col span={12}>
                                    <TextField {...col} label="Motor">{record.motor.name}</TextField>
                                    <TextField {...col} label="Warna">{record.color.name}</TextField>
                                    <TextField {...col} label="Alamat">{record.address}</TextField>
                                    <TextField {...col} label="Catatan">{record.note}</TextField>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Komentar" key="comment">
                            <Comment spkid={record.id} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Sejarah" key="history">
                            <History spkid={record.id} />
                        </Tabs.TabPane>
                    </Tabs>
                    <Drawer visible={state.drawerVisible} title="Update status prospek" width={350}
                        onClose={() => setState({ ...state, drawerVisible: false })}>
                        <Form name="update_status" onSubmit={formSummited}>
                            <Form.Item label="Status baru">
                                {getFieldDecorator('prospek_status', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(
                                    <Select placeholder="Pilih status baru">
                                        {prospekStatus.filter(v => v.value !== 'none' && v.value !== record.prospek_status && v.value !== 'spk').map(v =>
                                            <Select.Option key={v.value}>{v.text}</Select.Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                            <Button icon="pushpin" type="primary" htmlType="submit" loading={state.saveLoading}>Update status</Button>
                        </Form>
                    </Drawer>
                </Fragment>
            }
        </Card >
    );
}

export default Form.create({ name: 'update_status' })(Detail);