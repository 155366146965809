import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, message, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch';
import { pop, push } from '../../component/link';

const Actions = props => {
    const [loading, setLoading] = React.useState(false);
    const onConfirm = async () => {
        setLoading(true);
        try {
            await Fetch.del(`/${props.url}/${props.dataId}`);
            message.info(`${props.title} berhasil dihapus`);
            pop(props, `/${props.url}`);
        } catch (err) {
            message.error('Error: ' + err);
        }
    }
    return (
        <Button.Group>
            {props.showEdit && <Button icon="edit" href={`/${props.url}/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/${props.url}/${props.dataId}/edit`);
            }}>Edit</Button>}
            {props.showDelete && <Popconfirm placement="bottomLeft" title={"Yakin menghapus?"} onConfirm={onConfirm} okText="Iya" cancelText="Tidak">
                <Button icon="delete" type="danger" loading={loading}>Hapus</Button>
            </Popconfirm>}
            {props.showPrint && <Button icon="printer" onClick={props.printClicked}>Print</Button>}
            <Button icon="reload" onClick={props.reload} />
        </Button.Group>
    );
}

Actions.propTypes = {
    dataId: PropTypes.number.isRequired,
    reload: PropTypes.func,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    showPrint: PropTypes.bool,
    showDelete: PropTypes.bool,
    showEdit: PropTypes.bool,
}

Actions.defaultProps = {
    showDelete: true,
    showEdit: true,
}

export default withRouter(Actions);