import React from 'react';
import { Card, Row, Col, Tabs, Tag } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { MyLink } from '../../component/link'
import { formatDate } from '../../component/util'
import { useTab } from '../../component/tab';
import Picture from '../spk/picture'
import WigetBpkbPosition from './widget_bpkb_position'

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/bpkb/${props.match.params.id}`);
    const col = { labelSpan: 8, valueSpan: 16 }
    const tab = useTab(props, 'tab', 'info')

    return (
        <Card loading={loading} title={<Title title={"Detail BPKB"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="BPKB" url="bpkb" showDelete={false} />} />} >
            {!loading &&
                <Tabs  {...tab}>
                    <Tabs.TabPane tab="Info" key="info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Tanggal DO">{record ? <MyLink to={`/delivery/${record.delivery_id}`}>{formatDate(record.created_at)}</MyLink> : ''}</TextField>
                                <TextField {...col} label="No SPK">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.number}</MyLink> : ''}</TextField>
                                <TextField {...col} label="Motor">{record ? record.motor ? record.motor.name : '' : ''}</TextField>
                                <TextField {...col} label="Warna">{record ? record.color ? record.color.name : '' : ''}</TextField>
                                <TextField {...col} label="No Mesin">{record ? <MyLink to={`/stock/${record.stock.id}`}>{record.stock.machine_number}</MyLink> : ''}</TextField>
                                <TextField {...col} label="Nama STNK">{record ? record.spk ? record.spk.stnk_name : '' : ''}</TextField>
                                <TextField {...col} label="Leasing">{record ? record.leasing ? record.leasing.name : 'CASH' : 'CASH'}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="No Polisi">{record.plate_number}</TextField>
                                <TextField {...col} label="Nomor BPKB">{record.number}</TextField>
                                <TextField {...col} label="Nomor lokal">{record.local_number}</TextField>
                                <TextField {...col} label="Tanggal Jadi">{formatDate(record.recieved)}</TextField>
                                <TextField {...col} label="Tanggal Konsumen">{formatDate(record.sent_consument)}</TextField>
                                <TextField {...col} label="Tanda terima">{record.bpkb_receipt_id > 0 ?
                                    <MyLink to={`/bpkbreceipt/${record.bpkb_receipt_id}`}>{record.bpkb_receipt.number}</MyLink> : '-'}</TextField>
                                <TextField {...col} label="Status"><Tag color="blue">{record.status}</Tag></TextField>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Posisi" key="position">
                        <WigetBpkbPosition bpkbId={record.id} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Gambar" key="picture">
                        <Picture spkid={record.spk_id} type="bpkb" />
                    </Tabs.TabPane>
                </Tabs>
            }
        </Card >
    );
}

export default Detail;