import React from 'react';
import { Card, Form, Drawer, Divider, Button, message, Table, Popconfirm } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import MySelect from '../../component/select';
import { formatDate } from '../../component/util'
import { InputTextArea } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [, tb] = useTable('/bpkb?order=created_at desc', {}, { 'key': 'tbpkb_' }, true)
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, drawerBpkbVisible: false, bpkbs: [], });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/bpkbreceipt/${props.match.params.id}`, { ...values, bpkb: state.bpkbs.map(v => v.id) });
                        message.info('Tanda terima BPKB berhasil diedit');
                    } else {
                        await Fetch.post('/bpkbreceipt', { ...values, status: 'new', bpkb: state.bpkbs.map(v => v.id) });
                        message.info('Tanda terima BPKB berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/bpkbreceipt');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/bpkbreceipt/${props.match.params.id}`);
        const bpkbs = await Fetch.get(`/bpkb?filter=(bpkb_receipt_id,,eq,,${props.match.params.id})`)
        setFieldsValue({
            leasing_id: data.leasing_id,
            note: data.note,
        });
        setState({
            ...state, loading: false, bpkbs: bpkbs.data, leasing_id: data.leasing_id,
        });
    }

    const onRowClaim = (record) => {
        return {
            onClick: () => {
                const ind = state.bpkbs.findIndex(v => v.id === record.id)
                if (ind < 0) {
                    setState({ ...state, drawerPlaceVisible: false, bpkbs: [...state.bpkbs, record] })
                }
                else message.error('BPKB sudah masuk ')
            }
        }
    }
    const onConfirm = (record) => {
        return () => {
            setState({ ...state, bpkbs: state.bpkbs.filter(v => v.id !== record.id) })
        }
    }

    return (
        <Card title={<Title title={edit ? "Edit tanda terima BPKB " : "Tambah tanda terima BPKB"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_stocksend" onSubmit={formSummited} {...formItemLayout}>
                <Form.Item label="Leasing">
                    {getFieldDecorator('leasing_id', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <MySelect valueIndex="id" dataIndex="name" url='/leasing?order=name asc&limit=1000' showSearch onChange={v => setState({ ...state, leasing_id: v })}
                            disabled={edit} />
                    )}
                </Form.Item>
                <Form.Item label="Catatan">
                    {getFieldDecorator('note')(
                        <InputTextArea autosize placeholder="Masukkan catatan" />
                    )}
                </Form.Item>
                <Divider />
                <LabelHeader actions={<Button icon="plus" type="primary" onClick={() => {
                    tb.setFilter({ 'bpkb.leasing_id': state.leasing_id, 'bpkb_receipt_id': 0, 'bpkb.number': '' })
                    setState({ ...state, drawerBpkbVisible: true });
                }} disabled={!state.leasing_id}>Tambah BKPB</Button>}>
                    Bpkb terpilih</LabelHeader>
                <Table rowKey="id" dataSource={state.bpkbs} scroll={{ x: true }} pagination={false}>
                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal DO" render={formatDate} />
                    <Table.Column key="number" dataIndex="number" title="No BPKB" />
                    <Table.Column key="name" dataIndex="spk.stnk_name" title="Nama STNK" />
                    <Table.Column key="marketing" dataIndex="marketing.name" title="Marketing" />
                    <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin" />
                    <Table.Column key="police_number" dataIndex="police_number" title="No Polisi" />
                    <Table.Column key="leasing" dataIndex="leasing.name" title="Leasing" render={(r, s) => s.leasing === null ? 'CASH' : r} />
                    <Table.Column key="recieved" dataIndex="recieved" title="Tanggal Jadi" render={formatDate} />
                    <Table.Column key="sent_consument" dataIndex="sent_consument" title="Tanggal Konsumen" render={formatDate} />
                    <Table.Column key="note" dataIndex="note" title="Catatan" />
                    <Table.Column key="id" dataIndex="id" title="aksi" render={(t, record) =>
                        <Popconfirm placement="bottomRight" title={`Yakin menghapus?`} onConfirm={onConfirm(record)} okText="Iya" cancelText="Tidak">
                            <span className="span-click">hapus</span>
                        </Popconfirm>
                    } width={80} fixed="right" />
                </Table>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/bpkbreceipt')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
            <Drawer title="Pilih BPKB" visible={state.drawerBpkbVisible} width={600} onClose={() => setState({ ...state, drawerBpkbVisible: false })}>
                <Table {...tb.tableProps} onRow={onRowClaim}>
                    <Table.Column key="created_at" dataIndex="created_at" title="Tanggal DO" render={formatDate}
                        {...tb.columnProps({ key: 'bpkb.created_at', type: 'startend' })} />
                    <Table.Column key="number" dataIndex="number" title="No BPKB"  {...tb.columnProps({ key: 'number', hint: 'Cari nomor' })} />
                    <Table.Column key="name" dataIndex="spk.stnk_name" title="Nama STNK"  {...tb.columnProps({ key: 'spk.stnk_name', hint: 'Cari nama STNK' })} />
                    <Table.Column key="marketing" dataIndex="marketing.name" title="Marketing"  {...tb.columnProps({ key: 'marketing.name', hint: 'Cari nama marketing' })} />
                    <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                    <Table.Column key="plate_number" dataIndex="plate_number" title="No Polisi"  {...tb.columnProps({ key: 'plate_number', hint: 'Cari no polisi' })} />
                    <Table.Column key="leasing" dataIndex="leasing.name" title="Leasing" render={(r, s) => s.leasing === null ? 'CASH' : r} />
                    <Table.Column key="recieved" dataIndex="recieved" title="Tanggal Jadi" render={formatDate} />
                    <Table.Column key="sent_consument" dataIndex="sent_consument" title="Tanggal Konsumen" render={formatDate} />
                    <Table.Column key="note" dataIndex="note" title="Catatan" />
                </Table>
            </Drawer>
        </Card>
    );
}

export default Form.create({ name: 'create_stocksend' })(Add);