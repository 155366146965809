import React from 'react';
import { Card, Upload, Divider, Button, Icon, Table, Tag, Typography, message } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { LabelHeader } from '../../component/header';

const ImportStock = props => {
    const [state, setState] = React.useState({ loading: false, saveLoading: false, state: 'none', data: [] })

    const onChange = ({ file }) => {
        if (file.response && file.response.name) {
            Fetch.post(`/stock/readimport/${file.response.name}`).then(({ data }) => {
                let isOK = true;
                let msg = [];
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].motor_ok || !data[i].color_ok) {
                        isOK = false;
                        const dd = `${data[i].motor_type} - ${data[i].color_type}`;
                        if (msg.indexOf(dd) >= 0) continue;
                        msg.push(dd)
                    }
                }
                setState({ ...state, state: 'uploaded', data: data, name: file.response.name, isOK: isOK, message: msg })
            })
        }
    }

    const doImport = () => {
        setState({ ...state, saveLoading: true })
        Fetch.post(`/stock/doimport/${state.name}`).then(v => {
            message.info('Import berhasil');
            pop(props, '/stock')
        }).catch(err => {
            message.error(err)
            setState({ ...state, saveLoading: false })
        })
    }

    return (
        <Card title={<Title title={"Import stock"} {...props} />} loading={state.loading}>
            {state.state === 'none' &&
                <Upload.Dragger multiple={false} name="file" action={Fetch.getUrl('/stock/uploadimport')} accept=".xlsx"
                    headers={{ 'authorization': `Bearer ${localStorage.getItem('token')}` }} onChange={onChange}
                    showUploadList={false}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Klik atau drop file disini</p>
                </Upload.Dragger>
            }
            {state.state === 'uploaded' &&
                <div>
                    <LabelHeader>Stock yang akan diimport</LabelHeader>
                    <Table dataSource={state.data} pagination={false} rowKey="machine_number" scroll={{ x: true }}>
                        <Table.Column key="sj_number" dataIndex="sj_number" title="No SJ" />
                        <Table.Column key="sj_date" dataIndex="sj_date" title="Tanggal SJ" />
                        <Table.Column key="so_number" dataIndex="so_number" title="No SO" />
                        <Table.Column key="machine_number" dataIndex="machine_number" title="No mesin" />
                        <Table.Column key="chasis_number" dataIndex="chasis_number" title="No rangka" />
                        <Table.Column key="motor_type" dataIndex="motor_type" title="Tipe motor" />
                        <Table.Column key="color_type" dataIndex="color_type" title="Tipe warna" />
                        <Table.Column key="motor_ok" dataIndex="motor_ok" title="Motor OK" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'OK' : 'Tidak OK'}</Tag>} />
                        <Table.Column key="color_ok" dataIndex="color_ok" title="Color OK" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'OK' : 'Tidak OK'}</Tag>} />
                    </Table>
                </div>
            }
            {!state.isOK && state.state === 'uploaded' &&
                <div style={{ marginTop: 10 }}>
                    <Typography.Text style={{ color: 'red' }}>
                        Ada code motor atau code warna motor yang belum ada pada data master motor.<br />
                        Berikut kode motor atau warna yang tidak ada:<br />
                        <ul>
                            {state.message.map(v => <li key={v}>{v}</li>)}
                        </ul>
                        Silakan tambah motor atau warna yang belum ada terlebih dahulu dengan klik pada tombol "buka data motor"<br />
                        Jika sudah menambah silakan klik pada tombol "Cek ulang"<br /><br />
                    </Typography.Text>
                    <Button href="/motor" target="_blank" >Buka data motor</Button>
                    <Button style={{ marginLeft: 10 }} icon="reload" onClick={() => {
                        Fetch.post(`/stock/readimport/${state.name}`).then(({ data }) => {
                            let isOK = true;
                            for (let i = 0; i < data.length; i++) {
                                if (!data[i].motor_ok || !data[i].color_ok) {
                                    isOK = false;
                                    break;
                                }
                            }
                            setState({ ...state, state: 'uploaded', data: data, isOK: isOK })
                        })
                    }}>Cek Ulang</Button>
                </div>}
            <Divider />
            <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/stock')} >Cancel</Button>
            <Button icon="upload" type="primary" loading={state.saveLoading} disabled={!state.isOK} onClick={doImport}> Import </Button>
        </Card>
    );
}

export default ImportStock;