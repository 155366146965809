import React from 'react';
import { Button, DatePicker, Checkbox } from 'antd';
import Fetch from '../../Fetch';

const Exporter = props => {
    const [state, setState] = React.useState({ onlyStock: false })
    const f = state.onlyStock ? `(on_stock,,eq,,t)` : `(stock.created_at,,bw,,${state.date})`
    return (
        <div>
            <div style={{ marginBottom: 10 }}> Tanggal</div>
            <DatePicker.RangePicker disabled={state.onlyStock} format="DD-MM-YYYY" onChange={v => {
                setState({ ...state, date: `${v[0].format('YYYY-MM-DD')} ${v[1].format('YYYY-MM-DD')}` })
            }} />
            <Checkbox style={{ marginTop: 10 }} onChange={v => setState({ ...state, onlyStock: v.target.checked })}>
                Hanya semua yang stock tersedia
            </Checkbox>
            <div style={{ marginTop: 10 }}>
                <Button disabled={!state.date && !state.onlyStock} href={Fetch.getUrl(`/stock/export?limit=-1&filter=${f}&access_token=${localStorage.getItem('token')}`)}
                    target="_blank" type="primary" icon="download">Export</Button>
            </div>
        </div>
    )
}

export default Exporter;