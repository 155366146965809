import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" href={`/subsidiwmsinvoice/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/subsidiwmsinvoice/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/subsidiwmsinvoice', { order: { key: 'created_at', order: 'descend' } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/subsidiwmsinvoice/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/subsidiwmsinvoice/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/subsidiwmsinvoice/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Tagihan Subsidi WMS"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            const url = tb.genFilterUrl(`/claim/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="date" dataIndex="date" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'date', type: 'startend' })} {...tb.sortProps('date')} />
                <Table.Column key="number" dataIndex="number" title="Nomor"  {...tb.columnProps({ key: 'number', hint: 'Cari nomor' })} />
                <Table.Column key="total_invoice" dataIndex="total_invoice" align="right" title="Total Tertagih" render={formatMoney} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;