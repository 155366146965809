import React, { Fragment } from 'react'
import useTable from '../../component/table';
import { LabelHeader } from '../../component/header';
import { Table, Tag, Dropdown, Menu, Icon, Row, Col, Popconfirm, message, Drawer } from 'antd';
import { paymentTypeToString, paymentTypeToColor, paymentOnlineToString, paymentOnlineToColor, paymentStatusToString, paymentStatusToColor } from '../../constant'
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch';
import { TextField } from '../../component/field'
import { GlobalStore } from '../../store/store';
import { Button } from 'antd/lib/radio';
import XenditPay from './xenditmakepay'
import XenditDetail from './xenditdetail'

const Payment = props => {
    const [state, setState] = React.useState({ draweVisible: false, detailVisible: false })
    const [, reducer] = React.useContext(GlobalStore);
    const [tbState, tb] = useTable(`/spkpayment?order=created_at DESC`, { filter: { 'spk_payment.spk_id': props.record.id } })
    const col = { labelSpan: 8, valueSpan: 16 }

    const onConfirm = (id) => {
        return async () => {
            reducer({
                type: 'OPEN_AUTHORIZE', payload: true, callback: async (token) => {
                    try {
                        await Fetch.del(`/spkpayment/${id}`, null, token)
                        props.reload()
                    } catch (err) {
                        message.error('Error: ' + err);
                    }
                }
            })
        }
    }

    const detailClicked = (r) => {
        setState({ ...state, detailVisible: true, record: r })
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                setState({ ...state, detailVisible: true, record: record })
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            {record.payment_type === 'xendit' &&
                <Menu.Item onClick={() => detailClicked(record)}><Icon type="check" style={{ marginRight: 8 }} />detail</Menu.Item>}
            {(record.payment_type !== 'xendit' || (record.payment_type === 'xendit' && record.payment_status === 'success'))
                && <Menu.Item><a href={Fetch.getUrl(`/spkpaymentprint/${record.id}/pdf?access_token=${localStorage.getItem('token')}`)}
                    target="_blank" rel="noopener noreferrer">
                    <Icon type="printer" style={{ marginRight: 8 }} />print</a></Menu.Item>}
            <Menu.Item>
                <Popconfirm placement="left" title={`Yakin menghapus pembayaran?`} onConfirm={onConfirm(record.id)}
                    okText="Iya" cancelText="Tidak">
                    <a href="void()"><Icon type="delete" style={{ marginRight: 8 }} />hapus</a>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    const renderValue = v => <div style={{ width: '100%', textAlign: 'right', paddingRight: 50 }}>
        {v.toLocaleString('in-ID')}
    </div>

    const addPayment = () => {
        setState(s => { return { ...s, draweVisible: true } })
    }

    return (
        <Fragment>
            <Row gutter={12}>
                <Col span={12}>
                    <TextField {...col} label="Total">{renderValue(props.record.total)}</TextField>
                    <TextField {...col} label="Pembayaran diterima">{renderValue(props.record.payment_recieved)}</TextField>
                    <TextField {...col} label="Kekurangan">{renderValue(props.record.payment_residual)}</TextField>
                </Col>
            </Row>
            <LabelHeader actions={<Button onClick={addPayment}>Tambah Pembayaran</Button>}>Pembayaran</LabelHeader>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'spk_payment.created_at', type: 'startend' })} />
                <Table.Column key="number" dataIndex="number" title="Nomor" />
                <Table.Column key="tipe" dataIndex="type" title="Tipe"
                    render={t => <Tag color={paymentTypeToColor(t)}>{paymentTypeToString(t)}</Tag>} />
                <Table.Column key="payment_type" dataIndex="payment_type" title="Online?"
                    render={t => <Tag color={paymentOnlineToColor(t)}>{paymentOnlineToString(t)}</Tag>} />
                <Table.Column key="value" dataIndex="value" title="Jumlah" align="right" render={formatMoney} />
                <Table.Column key="bank" dataIndex="bank.name" title="Bank" render={t => t === undefined ? 'Cash' : t} />
                <Table.Column key="card_number" dataIndex="card_number" title="No transaksi" />
                <Table.Column key="user.name" dataIndex="user.name" title="Posisi" />
                <Table.Column key="payment_status" dataIndex="payment_status" title="Status"
                    render={(t, r) => r.payment_type === 'offline' ? '-' : <Tag color={paymentStatusToColor(t)}>{paymentStatusToString(t)}</Tag>} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
            <Drawer visible={state.draweVisible} title="Tambah pembayaran online" width={400}
                onClose={() => setState(s => { return { ...s, draweVisible: false } })}>
                <XenditPay onCancel={(v) => {
                    setState(s => { return { ...s, draweVisible: false } })
                    if (v === true) {
                        props.reload()
                    }
                }
                } record={props.record}
                    tandajadi={tbState.state.data.filter(v => v.type === 'tandajadi').length > 0} />
            </Drawer>
            <Drawer visible={state.detailVisible} title="Detail pembayaran Xendit" width={400}
                onClose={() => setState({ ...state, detailVisible: false })}>
                <XenditDetail record={state.record} wa={props.record.buyer_whatsapp} />
            </Drawer>
        </Fragment>
    )
}

export default Payment;