import React from 'react';
import { Card, Row, Col, Table, Tabs, Button } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field'
import { formatDate, formatMoney } from '../../component/util'
import Fetch from '../../Fetch';
import { LabelHeader } from '../../component/header';
import { MyLink, push } from '../../component/link';
import { useTab } from '../../component/tab';
import Picture from './savebankpicture';

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="edit" href={`/savecash/${props.id}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/savecash/${props.id}/edit`);
            }}>Edit</Button>
            <Button icon="printer" href={Fetch.getUrl(`/savebankprint/${props.id}/pdf?access_token=${localStorage.getItem('token')}`)} target="_blank" >Print</Button>
        </Button.Group>
    );
}

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/savebank/${props.match.params.id}`);
    const [state, setState] = React.useState({ data: [], loading: true, summary: [] })
    const tab = useTab(props, 'tab', 'info')
    const col = { labelSpan: 8, valueSpan: 16 }

    React.useEffect(() => {
        if (record.id)
            Fetch.get(`/spkpayment?filter=(save_bank_id,,eq,,${record.id})&order=created_at DESC&limit=10000`).then(v => {
                let summary = []
                let total = 0;
                for (let i = 0; i < v.data.length; i++) {
                    const val = v.data[i];
                    const bankId = val.payment_type === 'offline' ? val.bank_id : 1000000;
                    const index = summary.findIndex(v1 => v1.id === bankId)
                    if (index < 0) {
                        summary.push({ id: bankId, total: val.value, name: val.bank.name })
                    } else {
                        summary[index].total += val.value
                    }
                    total += val.value
                }
                summary.push({ id: 1000001, name: 'Total', total: total })
                setState({ ...state, loading: false, data: v.data, summary: summary });
            })
    }, [record])

    return (
        <Card loading={loading} title={<Title title={"Detail setoran"} actions={<Actions id={record.id} {...props} />} />} >
            {!loading &&
                <Tabs {...tab}>
                    <Tabs.TabPane tab="Info" key="info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Tanggal dibuat">{formatDate(record.created_at)}</TextField>
                                <TextField {...col} label="Nomor">{record.number}</TextField>
                                <TextField {...col} label="Tanggal setor">{formatDate(record.date)}</TextField>
                                <TextField {...col} label="No bukti setor">{record.resi === '' ? '-' : record.resi}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Pembuat">{record.user.name}</TextField>
                                <TextField {...col} label="Total">{formatMoney(record.total)}</TextField>
                            </Col>
                        </Row>
                        <LabelHeader>Pembayaran</LabelHeader>
                        <Table loading={state.loading} dataSource={state.data} rowKey="id" pagination={false} scroll={{ x: true }}>
                            <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate} />
                            <Table.Column key="number" dataIndex="number" title="Nomor" />
                            <Table.Column key="spknumber" dataIndex="spk.number" title="No SPK" render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>} />
                            <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama SPK" />
                            <Table.Column key="value" dataIndex="value" title="Jumlah" align="right" render={formatMoney} />
                            <Table.Column key="chasis" dataIndex="stock.chasis_number" title="No rangka" />
                            <Table.Column key="machine" dataIndex="stock.machine_number" title="No mesin" />
                        </Table>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Gambar" key="picture">
                        <Picture record={record} reload={fetchData} />
                    </Tabs.TabPane>
                </Tabs>
            }
        </Card >
    );
}

export default Detail;