import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, InputNumber, Checkbox, Select } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const isWms = props.match.path.includes('wms')
    const url = isWms ? 'wms' : 'ahm';
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, colors: [] });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/subsidy/${props.match.params.id}`, {
                            ...values, motor_id: state.motor.id,
                            color_id: values.color_id || 0, subsidy_type: isWms ? 'wms' : 'ahm'
                        });
                        message.info('Subsidi berhasil diedit');
                    } else {
                        await Fetch.post('/subsidy', { ...values, color_id: values.color_id || 0, subsidy_type: isWms ? 'wms' : 'ahm' });
                        message.info('Subsidi berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, `/${url}`);
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/subsidy/${props.match.params.id}`);
        setFieldsValue({
            motor_id: data.motor_id,
            color_id: data.color_id === 0 ? undefined : data.color_id,
            subsidy: data.subsidy,
            ppn: data.ppn,
            pph: data.pph
        });
        Fetch.get(`/color?filter=(motor_id,,eq,,${data.motor_id})`).then(res => {
            setState(s => {
                return { ...s, loading: false, motor: { id: data.motor_id }, colors: res.data }
            })

        });
    }

    const onMotorChange = v => {
        setFieldsValue({ color_id: undefined })
        Fetch.get(`/color?filter=(motor_id,,eq,,${v})`).then(res => setState({ ...state, colors: res.data, motor: v }))
    }

    return (
        <Card title={<Title title={edit ? "Edit subsidi" : "Tambah subsidi"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_masterprice" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={64}>
                    <Col span={12}>
                        <Form.Item label="Motor">
                            {getFieldDecorator('motor_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect dataIndex="name" valueIndex="id" placeHolder="Pilih motor" showSearch
                                    url="/motor?limit=1000" onChange={onMotorChange} />
                            )}
                        </Form.Item>
                        {(edit || state.motor) && <Form.Item label="Color">
                            {getFieldDecorator('color_id')(
                                <Select placeholder="Silakan pilih warna" allowClear>
                                    {state.colors.map(v => <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>}
                        <Form.Item label="Subsidi">
                            {getFieldDecorator('subsidy', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="PPN?">
                            {getFieldDecorator('ppn', { valuePropName: 'checked' })(
                                <Checkbox>PPN</Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item label="PPh">
                            {getFieldDecorator('pph', { valuePropName: 'checked' })(
                                <Checkbox>PPh</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, `/${url}`)} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading} >Simpan</Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_masterprice' })(Add);