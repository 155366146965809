const P_USER_WRITE = 1
const P_USER_READ = 2
const P_TEAM_WRITE = 10
const P_TEAM_READ = 11
const P_MOTOR_WRITE = 20
const P_MOTOR_READ = 21
const P_BANK_WRITE = 30
const P_BANK_READ = 31
const P_PAYMENT_WRITE = 40
const P_PAYMENT_READ = 41
const P_PAYMENT_ADMIN = 42
const P_PURCHASE_WRITE = 50
const P_PURCHASE_READ = 51
const P_LEASING_WRITE = 60
const P_LEASING_READ = 61
const P_STOK_LOCATION_WRITE = 70
const P_STOK_LOCATION_READ = 71
const P_MASTER_PRICE_WRITE = 80
const P_MASTER_PRICE_READ = 81
const P_STOCK_WRITE = 90
const P_STOCK_READ = 91
const P_STOCK_DEBT = 92
const P_STOCK_VALUE = 93
const P_SPK_SOURCE_WRITE = 100
const P_SPK_SOURCE_READ = 101
const P_SPK_INSERT = 110
const P_SPK_READ = 111
const P_SPK_UPDATE = 112
const P_SPK_DELETE = 113
const P_SPK_ADMIN = 114
const P_SPK_DO = 115
const P_DELIVERY_WRITE = 120
const P_DELIVERY_READ = 121
const P_DELIVERY_CONFIRMER = 122
const P_DELIVERY_DRIVER = 123
const P_CLAIM_WRITE = 130
const P_CLAIM_READ = 131
const P_SALE_WRITE = 140
const P_SALE_READ = 141
const P_STNK_WRITE = 150
const P_STNK_READ = 151
const P_BPKB_WRITE = 160
const P_BPKB_READ = 161
const P_BURUARU = 170
const P_DASHBAORD = 180
const P_CUSTOMER = 190
const P_REGION = 200
const P_CRM = 210
const P_CASHIER = 240
const P_MONEY_CLOSING = 241
const P_MONEY_SETOR = 242
const P_STAMP = 250
const P_ADMINISTRATOR = 9889

class permission {
    constructor() {
        this.perm = [];
    }
    setPermission(per) {
        this.perm = per;
    }
    hasPermission(check) {
        if (this.perm.indexOf(P_ADMINISTRATOR) >= 0) return true;
        return this.perm.indexOf(check) >= 0;
    }
    hasAll(arr) {
        if (this.perm.indexOf(P_ADMINISTRATOR) >= 0) return true;
        for (let i = 0; i < arr.length; i++) {
            if (this.perm.indexOf(arr[i]) < 0)
                return false;
        }
        return true;
    }
    hasOneOf(arr) {
        if (arr.length === 0) return true;
        if (this.perm.indexOf(P_ADMINISTRATOR) >= 0) return true;
        for (let i = 0; i < arr.length; i++) {
            if (this.perm.indexOf(arr[i]) >= 0)
                return true;
        }
        return false;
    }
}

const p = new permission();
const user = JSON.parse(localStorage.getItem('user'))
p.setPermission(user === null ? [] : user.user_permissions.map(v => v.permission));

export default p;

export {
    P_USER_WRITE,
    P_USER_READ,
    P_TEAM_WRITE,
    P_TEAM_READ,
    P_MOTOR_WRITE,
    P_MOTOR_READ,
    P_BANK_WRITE,
    P_BANK_READ,
    P_PAYMENT_WRITE,
    P_PAYMENT_READ,
    P_PAYMENT_ADMIN,
    P_PURCHASE_WRITE,
    P_PURCHASE_READ,
    P_LEASING_WRITE,
    P_LEASING_READ,
    P_STOK_LOCATION_WRITE,
    P_STOK_LOCATION_READ,
    P_MASTER_PRICE_WRITE,
    P_MASTER_PRICE_READ,
    P_STOCK_WRITE,
    P_STOCK_READ,
    P_STOCK_DEBT,
    P_STOCK_VALUE,
    P_SPK_SOURCE_WRITE,
    P_SPK_SOURCE_READ,
    P_SPK_INSERT,
    P_SPK_READ,
    P_SPK_UPDATE,
    P_SPK_DELETE,
    P_SPK_ADMIN,
    P_SPK_DO,
    P_DELIVERY_WRITE,
    P_DELIVERY_READ,
    P_DELIVERY_CONFIRMER,
    P_DELIVERY_DRIVER,
    P_CLAIM_WRITE,
    P_CLAIM_READ,
    P_SALE_WRITE,
    P_SALE_READ,
    P_STNK_WRITE,
    P_STNK_READ,
    P_BPKB_WRITE,
    P_BPKB_READ,
    P_BURUARU,
    P_DASHBAORD,
    P_CUSTOMER,
    P_REGION,
    P_CRM,
    P_CASHIER,
    P_MONEY_CLOSING,
    P_MONEY_SETOR,
    P_STAMP,
    P_ADMINISTRATOR,
}