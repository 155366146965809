import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { formatDate, formatMoney, filterDate } from '../../component/util'
import { stnkStatus, stnkStatusToString, stnkStatusToColor } from '../../constant'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/stnk', { order: { key: 'created_at', order: 'descend' } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/stnk/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/stnk/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/stnk/${record.id}/status`}><Icon type="check" style={{ marginRight: 8 }} />Status</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/stnk/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar STNK"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            let err = filterDate(filter, 'stnk.created_at')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/stnk/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal DO" render={formatDate}
                    {...tb.columnProps({ key: 'stnk.created_at', type: 'startend' })}  {...tb.sortProps('created_at')} />
                <Table.Column key="name" dataIndex="spk.stnk_name" title="Nama STNK"  {...tb.columnProps({ key: 'spk.stnk_name', hint: 'Cari nama STNK' })}
                    render={(t, s) => <MyLink to={`/spk/${s.spk.id}`}>{t}</MyLink>} {...tb.sortProps('spk.stnk_name')} />
                <Table.Column key="marketing" dataIndex="marketing.name" title="Marketing" render={(t, s) => s.marketing == null ? '-' : <MyLink to={`/user/${s.marketing.id}`}>{t}</MyLink>}
                    {...tb.columnProps({ key: 'stnk.marketing_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })}
                    render={(t, s) => <MyLink to={`/stock/${s.stock.id}`}>{t}</MyLink>} />
                <Table.Column key="stnk_number" dataIndex="stnk_number" title="Stnk Number"  {...tb.columnProps({ key: 'stnk_number', hint: 'Cari no STNK' })} />
                <Table.Column key="srut" dataIndex="srut" title="SRUT"  {...tb.columnProps({ key: 'srut', hint: 'Cari SRUT' })} />
                <Table.Column key="plate_number" dataIndex="plate_number" title="No Polisi"  {...tb.columnProps({ key: 'plate_number', hint: 'Cari no polisi' })} />
                <Table.Column key="ktp_date" dataIndex="ktp_date" title="Tanggal KTP" render={formatDate} {...tb.sortProps('ktp_date')} />
                <Table.Column key="input_faktur_date" dataIndex="input_faktur_date" title="Tanggal Faktur" render={formatDate} {...tb.sortProps('input_faktur_date')} />
                <Table.Column key="samsat_process" dataIndex="samsat_process" title="Tanggal Samsat" render={formatDate} {...tb.sortProps('samsat_process')} />
                <Table.Column key="recieved" dataIndex="recieved" title="Tanggal Jadi" render={formatDate} {...tb.sortProps('recieved')} />
                <Table.Column key="sent_consument" dataIndex="sent_consument" title="Tanggal Konsumen" render={formatDate} {...tb.sortProps('sent_consument')} />
                <Table.Column key="user.name" dataIndex="user.name" title="Posisi" />
                <Table.Column key="bbn" dataIndex="bbn" title="BBN" align="right" render={t => formatMoney(t)} />
                <Table.Column key="biro" dataIndex="biro" title="Biro Jasa" align="right" render={t => formatMoney(t)} />
                <Table.Column key="status" dataIndex="status" title="Status" render={r => <Tag color={stnkStatusToColor(r)}>{stnkStatusToString(r)}</Tag>}
                    {...tb.columnProps({ key: 'stnk.status', options: stnkStatus, type: 'radio' })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;