import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Drawer, Upload, message, Popconfirm } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import Typography from 'antd/lib/typography/Typography';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/${props.url}/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/${props.url}/add`);
                }}>Tambah</Button>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="upload" onClick={props.importClick}>Import</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const isWms = props.match.path.includes('wms')
    const url = isWms ? 'wms' : 'ahm';
    const [{ filter, order }, tb] = useTable('/subsidy', { filter: { 'subsidy_type': props.match.path.includes('wms') ? 'wms' : 'ahm' } }, { key: 't_' })
    const [state, setState] = React.useState({ modalVisible: false })
    const title = isWms ? 'Daftar Subsidi WMS' : 'Daftar Subsidi AHM';

    React.useEffect(() => {
        tb.setFilter({ 'subsidy_type': props.match.path.includes('wms') ? 'wms' : 'ahm' })
    }, [props.match.path])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/${url}/${record.id}`, '_blank')
            }
        }
    }

    const onChange = ({ file }) => {
        if (file.response) {
            if (file.status === "error") {
                message.error('Error : ' + file.response.error)
            } else {
                message.info("Import berhasil")
                setState(s => { return { ...s, modalVisible: false } })
                tb.fetchData()
            }
        }
    }

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/subsidy/${id}`);
                message.info("Berhasil menghapus subsidi");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus subsidi: " + err);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/${url}/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/${url}/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <a href="void()"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</a>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={title} actions={<Actions  {...props} refresh={tb.fetchData} url={url}
            exportClick={() => {
                const url = tb.genFilterUrl(`/subsidy/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
                window.open(Fetch.getUrl(url))
            }}
            importClick={() => {
                setState(s => { return { ...s, modalVisible: true } })
            }}
        />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="motor.name" dataIndex="motor.name" title="Motor" {...tb.columnProps({ key: 'motor.name', hint: 'Cari motor' })} />
                <Table.Column key="color_id" dataIndex="color_id" title="Warna" render={(t, r) => t === 0 ? 'Semua' : t.color.name} />
                <Table.Column key="subsidy" dataIndex="subsidy" title="Subsidi" render={t => t.toLocaleString('in-ID')} align="right" />
                <Table.Column key="ppn" dataIndex="ppn" title="PPN" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} width={100} />
                <Table.Column key="pph" dataIndex="pph" title="PPh" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} width={100} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
            <Drawer visible={state.modalVisible} onClose={() => { setState(s => { return { ...s, modalVisible: false } }) }} title="Import subsidi" width={400}>
                <Typography>Untuk mendapatkan format import, silakan lakukan export terlebih dahulu. Gunakan file hasil export tersebut sebagai template</Typography><br />
                <Upload.Dragger multiple={false} name="file" action={Fetch.getUrl(`/subsidy/import/${isWms ? 'wms' : 'ahm'}`)} accept=".xlsx"
                    headers={{ 'authorization': `Bearer ${localStorage.getItem('token')}` }} onChange={onChange}
                    showUploadList={false}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Klik atau drop file disini</p>
                </Upload.Dragger>
            </Drawer>
        </Card >
    )
}

export default List;