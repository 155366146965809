import React from 'react';
import { Card, Form, Drawer, Divider, Button, message, Table, Popconfirm, DatePicker, Row, Col } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import { formatDate, formatMoney } from '../../component/util'
import { MyLink } from '../../component/link';
import { InputTextArea } from '../../component/input';
import moment from 'moment'
import MySelect from '../../component/select';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [, tbClaim] = useTable('/claim', { filter: { 'claim.intensive_leasing_invoice_id': 0, 'claim.faktur_date::notnull': '' } }, { 'key': '_t' }, true)
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, drawerClaimVisible: false, claims: [], removed: [], added: [], total: 0, leasing: 0 });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    if (state.claims.length === 0) {
                        message.error('Intensive tidak boleh kosong')
                        return
                    }
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/intensiveleasinginvoice/${props.match.params.id}`, { ...values, claims: state.claims.map(v => v.id), total_invoice: state.total });
                        message.info('Tagihan intensive leasing berhasil diedit');
                    } else {
                        await Fetch.post('/intensiveleasinginvoice', { ...values, claims: state.claims.map(v => v.id), total_invoice: state.total });
                        message.info('Tagihan intensive leasing berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/subsidileasinginvoice');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/intensiveleasinginvoice/${props.match.params.id}`);
        const claims = await Fetch.get(`/claim?filter=(intensive_leasing_invoice_id,,eq,,${props.match.params.id})`)
        setFieldsValue({
            note: data.note,
            date: moment(data.date),
            leasing_id: data.leasing_id,
        });
        setState({
            ...state, loading: false, claims: claims.data, leasing_id: data.leasing_id, total: data.total_invoice, leasing: data.leasing_id
        });
    }

    const onRowClaim = (record) => {
        return {
            onClick: () => {
                const ind = state.claims.findIndex(v => v.id === record.id)
                if (ind < 0) {
                    setState({ ...state, drawerPlaceVisible: false, claims: [...state.claims, record], total: state.total += record.intensive_leasing })
                }
                else message.error('Tagihan sudah masuk ')
            }
        }
    }
    const onConfirm = (record) => {
        return () => {
            setState({ ...state, claims: state.claims.filter(v => v.id !== record.id), total: state.total - record.intensive_leasing })
        }
    }

    const leasingChange = v => {
        setState({ ...state, leasing: v })
    }

    return (
        <Card title={<Title title={edit ? "Edit tanda terima tagihan " : "Tambah tagihan insentif leasing"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_stocksend" onSubmit={formSummited} {...formItemLayout}>
                <Row>
                    <Col md={12}>
                        <Form.Item label="Tanggal">
                            {getFieldDecorator('date', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker placeholder="Masukkan tanggal" format="DD-MM-YYYY" />
                            )}
                        </Form.Item>
                        <Form.Item label="Leasing">
                            {getFieldDecorator('leasing_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih leasing" url="/leasing" dataIndex="name" valueIndex="id" onChange={leasingChange} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={12} >
                        <Form.Item label="Catatan">
                            {getFieldDecorator('note')(
                                <InputTextArea autosize placeholder="Masukkan catatan" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <LabelHeader actions={<Button icon="plus" type="primary" disabled={state.leasing === 0} onClick={() => {
                    tbClaim.setFilter({ 'claim.intensive_leasing_invoice_id': 0, 'claim.faktur_date::notnull': '', 'spk.cash': false, 'claim.leasing_id': state.leasing })
                    setState({ ...state, drawerClaimVisible: true });
                }}>Tambah intensive item</Button>}>
                    Intensive leasing terpilih</LabelHeader>
                <Table rowKey="id" dataSource={state.claims} scroll={{ x: true }} pagination={false}>
                    <Table.Column key="faktur_date" dataIndex="faktur_date" title="Tanggal Faktur" render={formatDate} />
                    <Table.Column key="po_number" dataIndex="spk.po_number" title="NO PO" />
                    <Table.Column key="po_date" dataIndex="spk.po_date" title="Tanggal PO" render={formatDate} />
                    <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"
                        render={(t, r) => <MyLink to={`/motor/${r.motor.id}`}>{t}</MyLink>} />
                    <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama pembeli"
                        render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} />
                    <Table.Column key="intensive_leasing" dataIndex="intensive_leasing" align="right" title="Insentif" render={formatMoney} />
                    <Table.Column key="id" dataIndex="id" title="aksi" render={(t, record) =>
                        <Popconfirm placement="bottomRight" title={`Yakin menghapus?`} onConfirm={onConfirm(record)} okText="Iya" cancelText="Tidak">
                            <span className="span-click">hapus</span>
                        </Popconfirm>
                    } width={80} fixed="right" />
                </Table>
                <div style={{ marginTop: 20, fontSize: 16, fontWeight: 'bold' }}>
                    Total: {formatMoney(state.total)}
                </div>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/claimreceipt')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
            <Drawer title="Pilih tagihan" visible={state.drawerClaimVisible} width={600} onClose={() => setState({ ...state, drawerClaimVisible: false })}>
                <Table {...tbClaim.tableProps} onRow={onRowClaim}>
                    <Table.Column key="faktur_date" dataIndex="faktur_date" title="Tanggal Faktur" render={formatDate}
                        {...tbClaim.columnProps({ key: 'claim.faktur_date', type: 'startend' })} {...tbClaim.sortProps('created_at')} />
                    <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin"  {...tbClaim.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })}
                        render={(t, r) => <MyLink to={`/stock/${r.stock_id}`}>{t}</MyLink>} />
                    <Table.Column key="stnk_name" dataIndex="spk.stnk_name" title="Nama STNK"  {...tbClaim.columnProps({ key: 'spk.stnk_name', hint: 'Cari nama stnk' })}
                        render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} />
                    render={(t, r) => <MyLink to={`/stock/${r.stock_id}`}>{t}</MyLink>} />
                    <Table.Column key="intensive_leasing" dataIndex="intensive_leasing" align="right" title="Insentif" render={formatMoney} />
                </Table>
            </Drawer>
        </Card>
    );
}

export default Form.create({ name: 'create_stocksend' })(Add);