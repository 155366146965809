import React from 'react';
import { Card, Form, Divider, Button, message } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { InputText } from '../../component/input';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/motor/${props.match.params.id}`, values);
                        message.info('Motor berhasil diedit');
                    } else {
                        await Fetch.post('/motor', values);
                        message.info('Motor berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/motor');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/motor/${props.match.params.id}`);
        setState({ ...state, loading: false });
        setFieldsValue({
            name: data.name,
            code: data.code,
        });
    }

    return (
        <Card title={<Title title={edit ? "Edit motor" : "Tambah motor"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_motor" onSubmit={formSummited} {...formItemLayout}>
                <Form.Item label="Nama motor">
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <InputText placeholder="Masukkan nama motor" />
                    )}
                </Form.Item>
                <Form.Item label="Kode motor">
                    {getFieldDecorator('code', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <InputText placeholder="Masukkan kode motor" />
                    )}
                </Form.Item>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/motor')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading} >Simpan</Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_motor' })(Add);