import React from 'react';
import { Card, Form, Divider, Button, message, Table, Drawer, Popconfirm } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { TextField } from '../../component/field';
import useTable from '../../component/table';
import { InputText } from '../../component/input';

const TeamMember = props => {
    const openClick = () => {
        props.setState({ ...props.state, drawerVisible: true, drawerTitle: props.title })
    }
    const onConfirm = (id) => {
        return () => {
            if (props.title === 'Supervisor') {
                props.setState({ ...props.state, supervisors: props.state.supervisors.filter(v => v.id !== id) })
            } else if (props.title === 'Lead') {
                props.setState({ ...props.state, leads: props.state.leads.filter(v => v.id !== id) })
            } else if (props.title === 'Member') {
                props.setState({ ...props.state, members: props.state.members.filter(v => v.id !== id) })
            }
        }
    }
    return (
        <Table dataSource={props.dataSource} rowKey="id" pagination={{ hideOnSinglePage: true }}>
            <Table.Column key="username" dataIndex="username" title="Username" />
            <Table.Column key="name" dataIndex="name" title="Nama" />
            <Table.Column key="id" dataIndex="id" title={<span onClick={openClick} className="span-click">Tambah</span>} width={60}
                render={(v, record) =>
                    <Popconfirm placement="bottomRight" title={`Yakin menghapus?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                        <span className="span-click">hapus</span>
                    </Popconfirm>
                } />
        </Table>
    )
}

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({
        loading: edit, saveLoading: false, drawerVisible: false,
        leads: [], supervisors: [], members: []
    });
    const [, tb] = useTable('/user', {})
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    const vls = {
                        ...values, tsupervisors: state.supervisors.map(v => v.id), tleads: state.leads.map(v => v.id),
                        tmembers: state.members.map(v => v.id)
                    }
                    if (edit) {
                        await Fetch.put(`/team/${props.match.params.id}`, vls);
                        message.info('Team berhasil diedit');
                    } else {
                        await Fetch.post('/team', vls);
                        message.info('Team berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/team');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/team/${props.match.params.id}`);
        setFieldsValue({
            name: data.name,
        });
        setState({
            ...state, loading: false, supervisors: data.supervisors.map(v => v.user).filter(v => v !== null), leads: data.leads.map(v => v.user),
            members: data.members.filter(v => v.user !== null).map(v => v.user)
        });
    }

    const onRow = (record) => {
        return {
            onClick: () => {
                if (state.drawerTitle === 'Supervisor') {
                    const index = state.supervisors.findIndex(v => v.id === record.id)
                    if (index < 0) {
                        setState({ ...state, supervisors: [...state.supervisors, record] })
                    } else {
                        message.error('User sudah ada')
                    }
                } else if (state.drawerTitle === 'Lead') {
                    const index = state.leads.findIndex(v => v.id === record.id)
                    if (index < 0) {
                        setState({ ...state, leads: [...state.leads, record] })
                    } else {
                        message.error('User sudah ada')
                    }
                } else if (state.drawerTitle === 'Member') {
                    const index = state.members.findIndex(v => v.id === record.id)
                    if (index < 0) {
                        setState({ ...state, members: [...state.members, record] })
                    } else {
                        message.error('User sudah ada')
                    }
                }
            }
        }
    }

    return (
        <Card title={<Title title={edit ? "Edit team" : "Tambah team"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_team" onSubmit={formSummited} {...formItemLayout}>
                <Form.Item label="Nama">
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <InputText placeholder="Masukkan nama" />
                    )}
                </Form.Item>
                <TextField labelSpan={6} valueSpan={18} label="Supervisor"><TeamMember dataSource={state.supervisors} title="Supervisor" state={state} setState={setState} /></TextField>
                <div style={{ height: 20 }} />
                <TextField labelSpan={6} valueSpan={18} label="Lead"><TeamMember dataSource={state.leads} title="Lead" state={state} setState={setState} /></TextField>
                <div style={{ height: 20 }} />
                <TextField labelSpan={6} valueSpan={18} label="Member"><TeamMember dataSource={state.members} title="Member" state={state} setState={setState} /></TextField>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/team')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
            <Drawer visible={state.drawerVisible} title={state.drawerTitle} width={600} onClose={() => setState({ ...state, drawerVisible: false })}>
                <Table {...tb.tableProps} onRow={onRow}>
                    <Table.Column key="username" dataIndex="username" title="Username" {...tb.columnProps({ key: 'username', hint: 'Cari username' })} />
                    <Table.Column key="name" dataIndex="name" title="Name" {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                </Table>
            </Drawer>
        </Card>
    );
}

export default Form.create({ name: 'create_team' })(Add);