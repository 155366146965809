import React from 'react';
import { Card, Row, Col, Tag, Tabs, Statistic, Table } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { userTypeToString, userTypeToColor } from '../../constant'
import Fetch from '../../Fetch';
import { useTab } from '../../component/tab';
import useTable from '../../component/table'
import { formatDate, getDefaultCurYear } from '../../component/util'
import { MyLink } from '../../component/link';

const Detail = props => {
    const [state, setState] = React.useState({ permission: [] })
    const [{ record, loading }, { fetchData }] = useDetail(`/user/${props.match.params.id}`);
    const col = { labelSpan: 6, valueSpan: 18 }
    const tab = useTab(props, 'tab', 'info')
    const [{ filter, pagination }, tb] = useTable('/delivery', { order: { key: 'created_at', order: 'descend' } }, { key: 't_' }, true)

    React.useEffect(() => {
        Fetch.get('/permission').then(({ data }) => {
            setState({ ...state, permission: data })
        })
    }, [])

    React.useEffect(() => {
        if (record.id) tb.setFilter({ ...filter, 'delivery.marketing_id': record.id, 'delivery.created_at': getDefaultCurYear() })
    }, [record])

    const getPermissionName = t => {
        const f = state.permission.find(v => v.id === t) || { name: 'loading...' }
        return f.name;
    }

    return (
        <Card loading={loading} title={<Title title={"Detail User"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="User" url="user" />} />} >
            {!loading &&
                <Tabs {...tab}>
                    <Tabs.TabPane tab="Info" key="info">
                        <Row gutter={16}>
                            <Col span={12}>
                                <TextField {...col} label="Nama">{record.name}</TextField>
                                <TextField {...col} label="Username">{record.username}</TextField>
                                <TextField {...col} label="Phone">{record.phone}</TextField>
                                <TextField {...col} label="Email">{record.email}</TextField>
                                <TextField {...col} label="Tipe">{<Tag color={userTypeToColor(record.type)}>{userTypeToString(record.type)}</Tag>}</TextField>
                            </Col>
                            <Col span={12}>
                                <TextField {...col} label="Alamat">{record.address}</TextField>
                                <TextField {...col} label="Hak akses">{record.user_permissions === null ? '-' :
                                    record.user_permissions.map(v => <Tag key={v.id} color="purple">{getPermissionName(v.permission)}</Tag>)}</TextField>
                                <TextField {...col} label="AHM sales ID">{record.ahm_id_sales_person}</TextField>
                                <TextField {...col} label="Nama AHM">{record.ahm_name}</TextField>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Setting target" key="target">
                        <TextField {...col} label="Target Prospek">{record.target_prospek}</TextField>
                        <TextField {...col} label="Target SPK">{record.target_spk}</TextField>
                        <TextField {...col} label="Target Penjualan">{record.target_sale}</TextField>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Penjualan" key="sales">
                        <Statistic title="Total penjualan" value={pagination.total} decimalSeparator="," groupSeparator="." /><br />
                        <Table {...tb.tableProps}>
                            <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                                {...tb.columnProps({ key: 'delivery.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                            <Table.Column key="number" dataIndex="number" title="Nomor"
                                render={(t, r) => <MyLink to={`/delivery/${r.id}`}>{t}</MyLink>} />
                            <Table.Column key="spk.buyer_name" dataIndex="spk.buyer_name" title="Nama Pembeli"
                                render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>} />
                            <Table.Column key="spk.buyer_phone" dataIndex="spk.buyer_phone" title="Telepon" />
                            <Table.Column key="stock.chasis_number" dataIndex="stock.chasis_number" title="No rangka" />
                            <Table.Column key="stock.machine_number" dataIndex="stock.machine_number" title="No mesin" />
                            <Table.Column key="motor.name" dataIndex="motor.name" title="Motor" />
                            <Table.Column key="color.name" dataIndex="color.name" title="Warna" />
                        </Table>
                    </Tabs.TabPane>
                </Tabs>
            }
        </Card >
    );
}

export default Detail;