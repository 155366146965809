import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Tag } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from '../share/action';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import useTable from '../../component/table';

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/stock/${props.match.params.id}`);
    const [, tb] = useTable('/stock', { filter: { 'sj_number': '' } }, { 'key': '_t' }, true)
    const col = { labelSpan: 8, valueSpan: 16 }

    React.useEffect(() => {
        if (record)
            tb.setFilter({ 'sj_number': record.sj_number })
    }, [record])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/stock/${record.id}`, '_blank')
            }
        }
    }

    return (
        <Card loading={loading} title={<Title title={"Detail SJ"}
            actions={<Action dataId={record.id || parseInt(1)} reload={fetchData} title="Stock" url="stock" />} />} >
            {!loading && <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField {...col} label="Pengirim">{record.sender}</TextField>
                        <TextField {...col} label="No pengiriman">{record.sj_number}</TextField>
                        <TextField {...col} label="Tanggal pengiriman">{formatDate(record.sj_date)}</TextField>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row><br />
                <Table {...tb.tableProps} onRow={onRow}>
                    <Table.Column key="on_stock" dataIndex="on_stock" title="On stock" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Stock' : 'Laku'}</Tag>}
                        {...tb.columnProps({
                            key: 'on_stock', type: 'radio', options: [
                                { text: 'On Stock', value: 't' }, { text: 'Terjual', value: 'f' }
                            ], number: false
                        })} />
                    <Table.Column key="purchase.number" dataIndex="purchase.number" title="No SO" />
                    <Table.Column key="purchase.date" dataIndex="purchase.date" title="Tanggal SO" render={formatDate} />
                    <Table.Column key="year" dataIndex="year" title="Tahun"  {...tb.columnProps({ key: 'year', hint: 'Cari tahun' })} />
                    <Table.Column key="rfs" dataIndex="rfs" title="RFS" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                    <Table.Column key="motor.name" dataIndex="motor.name" title="Jenis SMH"  {...tb.columnProps({ key: 'motor.name', hint: 'Cari nama motor' })} />
                    <Table.Column key="motor.code" dataIndex="motor.code" title="Tipe" />
                    <Table.Column key="color.name" dataIndex="color.name" title="Warna" />
                    <Table.Column key="machine_number" dataIndex="machine_number" title="No Mesin"  {...tb.columnProps({ key: 'machine_number', hint: 'Cari no mesin' })} />
                    <Table.Column key="chasis_number" dataIndex="chasis_number" title="No Rangka"  {...tb.columnProps({ key: 'chasis_number', hint: 'Cari no rangka' })} />
                    <Table.Column key="sender" dataIndex="sender" title="Pengirim"  {...tb.columnProps({ key: 'sender', hint: 'Cari pengirim' })} />
                    <Table.Column key="stocklocation" dataIndex="stock_location" title="Lokasi" render={t => t === null ? 'DEALER' : t.name} />
                    <Table.Column key="service_book" dataIndex="service_book" title="Buku servis" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                    <Table.Column key="helm" dataIndex="helm" title="Aki" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                    <Table.Column key="accu" dataIndex="accu" title="Aku" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                    <Table.Column key="mirror" dataIndex="mirror" title="Spion" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                    <Table.Column key="toolkit" dataIndex="toolkit" title="Toolkit" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Iya' : 'Tidak'}</Tag>} />
                </Table>
            </Fragment>}
        </Card >
    );
}

export default Detail;