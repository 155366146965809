import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/team/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/team/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [, tb] = useTable('/team', {})

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/team/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/team/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/team/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Team"} actions={<Actions  {...props} refresh={tb.fetchData} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="name" dataIndex="name" title="Nama" />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;