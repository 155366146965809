import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Statistic, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import {
    paymentType, paymentTypeToString, paymentTypeToColor,
    paymentOnlineTypeToString, paymentOnlineTypeToColor, paymentOnlineType,
    paymentStatus, paymentStatusToColor, paymentStatusToString,
} from '../../constant'
import { formatDate, formatMoney, getDefaultCurMonth, filterDate } from '../../component/util'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="download" onClick={props.exportClick}>Export</Button>
            <Button icon="snippets" onClick={props.closingClick}>Closing</Button>
            <Button icon="snippets" onClick={props.saveClick}>Setoran</Button>
            <Button icon="reload" onClick={props.refresh} />
        </Button.Group>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/spkpayment', { filter: { 'spk_payment.created_at': getDefaultCurMonth() }, order: { key: 'created_at', order: 'descend' } })
    const [state, setState] = React.useState({})

    React.useEffect(() => {
        Fetch.get(`/spkpayment/summary`).then(v => setState({ ...state, ...v }));
    }, [])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/spk/${record.spk_id}?tab=payment`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/spk/${record.spk_id}?tab=payment`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Uang Masuk"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            let err = filterDate(filter, 'spk_payment.created_at')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/spkpayment/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} closingClick={() => push(props, '/moneyclose')} saveClick={() => push(props, '/savecash')} />} />}>
            <div style={{ display: 'flex' }}>
                <Statistic title="Total belum closing" value={state.unclosing} decimalSeparator="," groupSeparator="." />
                <div style={{ cursor: 'pointer' }} onClick={() => push(props, '/moneycashierready')}>
                    <Statistic title="Uang di kasir" value={state.ready} decimalSeparator="," groupSeparator="." style={{ marginLeft: 50 }} />
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => push(props, '/moneynotready')}>
                    <Statistic title="Uang tidak di kasir" value={state.not_ready} decimalSeparator="," groupSeparator="." style={{ marginLeft: 50 }} />
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => push(props, '/moneycash')}>
                    <Statistic title="Total cash di kasir" value={state.cash} decimalSeparator="," groupSeparator="." style={{ marginLeft: 50 }} />
                </div>
            </div><br />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'spk_payment.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                <Table.Column key="number" dataIndex="number" title="Nomor" />
                <Table.Column key="spknumber" dataIndex="spk.number" title="No SPK"  {...tb.columnProps({ key: 'spk.number', hint: 'Cari no SPK' })}
                    render={(t, r) => <MyLink to={`/spk/${r.spk_id}`}>{t}</MyLink>} />
                <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama SPK"  {...tb.columnProps({ key: 'spk.buyer_name', hint: 'Cari nama SPK' })}
                    {...tb.sortProps('spk.buyer_name')} />
                <Table.Column key="tipe" dataIndex="type" title="Tipe"
                    render={t => <Tag color={paymentTypeToColor(t)}>{paymentTypeToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'spk_payment.type', type: "radio", options: paymentType })} />
                <Table.Column key="payment_type" dataIndex="payment_type" title="Offline/Xendit" render={t => <Tag color={paymentOnlineTypeToColor(t)}>{paymentOnlineTypeToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'spk_payment.payment_type', type: "radio", options: paymentOnlineType })} />
                <Table.Column key="payment_status" dataIndex="payment_status" title="Status" render={t => <Tag color={paymentStatusToColor(t)}>{paymentStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'spk_payment.payment_status', type: "radio", options: paymentStatus })} />
                <Table.Column key="value" dataIndex="value" title="Jumlah" align="right" render={formatMoney} />
                <Table.Column key="bank" dataIndex="bank.name" title="Bank" render={(t, r) => r.payment_type === 'xendit' ? '-' : t === undefined ? 'Cash' : t}
                    {...tb.columnProps({ key: 'spk_payment.bank_id', type: "selecturl", url: '/bank', dataIndex: 'name', valueIndex: 'id' })} />
                <Table.Column key="card_number" dataIndex="card_number" title="No transaksi" />
                <Table.Column key="user.name" dataIndex="user" title="Posisi" render={t => <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>}
                    {...tb.columnProps({ key: 'spk_payment.user_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="chasis" dataIndex="stock.chasis_number" title="No rangka"  {...tb.columnProps({ key: 'stock.chasis_number', hint: 'Cari no rangka' })} />
                <Table.Column key="machine" dataIndex="stock.machine_number" title="No mesin"  {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;