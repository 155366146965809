import React from 'react';
import { Card, Upload, Icon, Button } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';

const StampPage = props => {

    const [state, setState] = React.useState({ loading: false, image: Fetch.getUrl(`/public/stamp/stamp.png`) });

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setState({ ...state, loading: true });
            return;
        }
        if (info.file.status === 'done') {
            setState({ loading: false, image: Fetch.getUrl(`/public/uploads/studentphotos/${info.file.response.filename}`) })
            props.onDone(info.file.response.filename);
        }
    }

    const uploadButton = (
        <div>
            <Icon type={state.loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return <Card title={<Title title={"Stempel dan Tanda Tangan"} />} >
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleChange}
            headers={{ Authorization: `Bearer ${localStorage.getItem('token')}` }}
            {...props}
        >
            {state.image !== '' ?
                <img src={state.image}
                    alt="avatar" width={185} height={170} /> : uploadButton}
        </Upload>
    </Card>
}

export default StampPage