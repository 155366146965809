import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, InputNumber, DatePicker } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop, MyLink } from '../../component/link';
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'
import { LabelHeader } from '../../component/header';
import moment from 'moment';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false });
    const col = { labelSpan: 8, valueSpan: 16 }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/claim/${props.match.params.id}`, values);
                        message.info('Intensif leasing berhasil diedit');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/subsidileasing');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/claim/${props.match.params.id}`);
        setState({ ...state, loading: false, record: data });
        setFieldsValue({
            intensive_leasing: data.intensive_leasing,
            intensive_leasing_date: moment(data.intensive_leasing_date),
        });
    }
    const { record } = state;

    return (
        <Card title={<Title title={edit ? "Edit insentif leasing" : "Tambah tagihan"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_claim" onSubmit={formSummited} {...formItemLayout}>
                <LabelHeader useTopMargin={false}>Info DO & SPK</LabelHeader>
                <Row gutter={32}>
                    <Col span={12}>
                        <TextField {...col} label="Tanggal DO">{record ? formatDate(record.created_at) : ''}</TextField>
                        <TextField {...col} label="No DO">{record ? <MyLink to={`/delivery/${record.do_id}`}>{record.delivery.number}</MyLink> : ''}</TextField>
                        <TextField {...col} label="Nama pembeli">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.buyer_name}</MyLink> : ''}</TextField>
                        <TextField {...col} label="Marketing">{record ? <MyLink to={`/spk/${record.marketing.id}`}>{record.marketing.name}</MyLink> : ''}</TextField>
                        <TextField {...col} label="Tanggal Faktur">{record ? formatDate(record.faktur_date) : ''}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="Motor">{record ? record.motor.name : ''}</TextField>
                        <TextField {...col} label="Warna">{record ? record.color.name : ''}</TextField>
                        <TextField {...col} label="Leasing">{record ? record.leasing.name : ''}</TextField>
                        <TextField {...col} label="OTR">{record ? record.spk.otr_price.toLocaleString('in-ID') : ''}</TextField>
                        <TextField {...col} label="Tanggal Unit">{record ? formatDate(record.unit_date) : ''}</TextField>
                    </Col>
                </Row>
                <LabelHeader>Intensive leasing</LabelHeader>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Insentif leasing">
                            {getFieldDecorator('intensive_leasing', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Tanggal tagih insentif">
                            {getFieldDecorator('intensive_leasing_date', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/claim')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_claim' })(Add);
