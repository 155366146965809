import React from 'react'
import { Drawer, Form, message, Select, Button } from 'antd'
import Fetch from '../../Fetch';
import { spkStatus } from '../../constant'
import { InputTextArea } from '../../component/input';
import MySelect from '../../component/select';

const UpdateStatus = (props) => {
    const [state, setState] = React.useState({ saveLoading: false, cur: undefined, open: undefined });
    const { getFieldDecorator } = props.form;

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    let d = { spk_status: values.spk_status };
                    if (!props.record.cash) {
                        if (d.spk_status === 'confirm') {
                            d.open_surveyor = values.open_surveyor === 1;
                            d.surveyor_id = values.open_surveyor === 1 ? 0 : values.surveyor_id;
                        }
                    }
                    await Fetch.post(`/spk/status/${props.record.id}`, d);
                    if (state.cur === 'need_fix')
                        await Fetch.post('/spknote/spk', { note: `Butuh perbaikan : ${values.note}`, spk_id: props.record.id });
                    message.info('Status spk berhasil di update');
                    props.fetchData();
                    setState({ ...state, saveLoading: false })
                    props.onClose();
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const onChange = (v) => {
        setState({ ...state, cur: v })
    }

    const onChangeOpen = (v) => {
        setState({ ...state, open: v })
    }

    return <Drawer visible={props.visible} title="Update status SPK" width={350}
        onClose={props.onClose}>
        {props.visible && <Form name="update_status" onSubmit={formSummited}>
            <Form.Item label="Status baru">
                {getFieldDecorator('spk_status', {
                    rules: [{ required: true, message: 'Please input your note!' }],
                })(
                    <Select placeholder="Pilih status SPK baru" onChange={onChange}>
                        {spkStatus.filter(v => v.value !== 'none' && v.value !== props.record.spk_status && v.value !== 'do').map(v =>
                            <Select.Option key={v.value}>{v.text}</Select.Option>
                        )}
                    </Select>

                )}
            </Form.Item>
            {state.cur === 'confirm' && !props.record.cash && <Form.Item label="Open surveyor">
                {getFieldDecorator('open_surveyor')(
                    <Select placeholder="Pilih open surveyor" onChange={onChangeOpen}>
                        <Select.Option key={0} value={0}>Surveyor dipilih manual</Select.Option>
                        <Select.Option key={1} value={1}>Open surveyor</Select.Option>
                    </Select>

                )}
            </Form.Item>}
            {state.cur === 'confirm' && !props.record.cash && state.open === 0 && <Form.Item label="Surveyor">
                {getFieldDecorator('surveyor_id')(
                    <MySelect url={`/user?filter=(leasing_id,,eq,,${props.record.leasing_id})`} dataIndex="name" valueIndex="id" />
                )}
            </Form.Item>}
            {state.cur === 'need_fix' && <Form.Item label="Catatan">
                {getFieldDecorator('note', {
                    rules: [{ required: true, message: 'Wajib diisi' }],
                })(
                    <InputTextArea placeholder="Masukkan catatan" />
                )}
            </Form.Item>}
            <Button icon="pushpin" type="primary" htmlType="submit" loading={state.saveLoading}>Update status</Button>
        </Form>}
    </Drawer>
}

export default Form.create({ name: 'update_status' })(UpdateStatus);