import React from 'react';
import { Card, Form, Typography, Row, Col, Input, Button, message, Divider, DatePicker } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { MyLink } from '../../component/link';
import { formatDate } from '../../component/util'
import { TextField } from '../../component/field'
import { InputText, InputTextArea } from '../../component/input'
import moment from 'moment';
import ToggleDisplay from 'react-toggle-display';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [state, setState] = React.useState({ loading: false, machine_number: '', data: null, saveLoading: false });
    const formItemLayout = {
        labelCol: {
            md: { span: 8 },
        },
        wrapperCol: {
            md: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };
    const col = { labelSpan: 8, valueSpan: 16 }
    const record = state.data

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.put(`/bpkb/${state.data.id}`, values);
                    message.info('BPKB berhasil diubah');
                    setState({ ...state, saveLoading: false, data: null, machine_number: '' })
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const machineNumberChanged = v => {
        setState({ ...state, machine_number: v.target.value })
    }

    const findMachine = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/bpkb?filter=(stock.machine_number,,eq,,${state.machine_number})`)
        if (data.data.length !== 1) message.error('No mesin tidak ditemukan')
        setState({ ...state, loading: false, data: data.data.length === 0 ? null : data.data[0] })
    } // JM41E1358491 JFS1E1426880

    React.useEffect(() => {
        if (state.data) {
            const d = state.data;
            setFieldsValue({
                number: d.number,
                local_number: d.local_number,
                recieved: d.recieved !== null ? moment(d.recieved) : undefined,
                note: d.note,
                faktur_number: d.faktur_number,
            })
        }
    }, [state.data])

    return (
        <Card title={<Title title="Tambah BPKB jadi" {...props} />}>
            <Row gutter={16}>
                <Col md={8}>
                    <Card>
                        <Typography.Text >Cari No Mesin</Typography.Text>
                        <Input style={{ marginTop: 8, marginBottom: 8 }} placeholder="Cari no mesin" onChange={machineNumberChanged} value={state.machine_number} />
                        <Button type="primary" loading={state.loading} onClick={findMachine} disabled={state.machine_number === ''}>Cari</Button>
                    </Card>
                </Col>
                <Col md={16}>
                    <ToggleDisplay show={state.data !== null}>
                        <Card>
                            <Typography.Text strong>Data SPK</Typography.Text>
                            <Divider />
                            {record && <React.Fragment>
                                <TextField {...col} label="Tanggal DO">{record ? <MyLink to={`/delivery/${record.delivery_id}`}>{formatDate(record.created_at)}</MyLink> : ''}</TextField>
                                <TextField {...col} label="No SPK">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.number}</MyLink> : ''}</TextField>
                                <TextField {...col} label="Motor">{record ? record.motor ? record.motor.name : '' : ''}</TextField>
                                <TextField {...col} label="Warna">{record ? record.color ? record.color.name : '' : ''}</TextField>
                                <TextField {...col} label="No Mesin">{record ? <MyLink to={`/stock/${record.stock.id}`}>{record.stock.machine_number}</MyLink> : ''}</TextField>
                                <TextField {...col} label="Nama STNK">{record ? record.spk ? record.spk.stnk_name : '' : ''}</TextField>
                                <TextField {...col} label="Leasing">{record ? record.leasing ? record.leasing.name : 'CASH' : 'CASH'}</TextField>
                                <TextField {...col} label="No Polisi">{record.plate_number}</TextField>
                            </React.Fragment>}
                            <Divider />
                            <Form layout="horizontal" name="create_bpkb" onSubmit={formSummited} {...formItemLayout}>
                                <Form.Item label="Tanggal Jadi">
                                    {getFieldDecorator('recieved', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <DatePicker placeholder="Masukkan tanggal BKPB jadi" format="DD-MM-YYYY" autosize />
                                    )}
                                </Form.Item>
                                <Form.Item label="Nomor BPKB">
                                    {getFieldDecorator('number', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <InputText placeholder="Masukkan nomor BPKB" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Nomor Faktur">
                                    {getFieldDecorator('faktur_number')(
                                        <InputText placeholder="Masukkan nomor faktur" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Nomor Internal">
                                    {getFieldDecorator('local_number')(
                                        <InputText placeholder="Masukkan nomor internal" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Catatan">
                                    {getFieldDecorator('note')(
                                        <InputTextArea autosize placeholder="Masukkan catatan" />
                                    )}
                                </Form.Item>
                                <div style={{ textAlign: 'right' }}>
                                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                                </div>
                            </Form>
                        </Card>
                    </ToggleDisplay>
                </Col>
            </Row>
        </Card >
    );
}

export default Form.create({ name: 'create_bpkb' })(Add);