import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, message, Popconfirm } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import { formatDate, formatMoney, filterDate } from '../../component/util'
import { biroStatusToColor, biroStatusToString, biroStatus } from '../../constant'
import Fetch from '../../Fetch'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" icon="plus" href={`/biro/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/biro/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/biro', { order: { key: 'date', order: 'descend' } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/biro/${record.id}`, '_blank')
            }
        }
    }

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/biro/${id}`);
                message.info("Berhasil menghapus tagihan biro jasa");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus tagihan biro jasa: " + err);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/biro/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            {record.biro_payment_id === 0 &&
                <Menu.Item><MyLink to={`/biro/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>}
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.number}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <a href="void()"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</a>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Biro Jasa"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            let err = filterDate(filter, 'stnk.created_at')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/biro/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="date" dataIndex="date" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'stnk.created_at', type: 'startend' })}  {...tb.sortProps('date')} />
                <Table.Column key="number" dataIndex="number" title="Nomor"  {...tb.columnProps({ key: 'number', hint: 'Cari nomor' })} />
                <Table.Column key="status" dataIndex="status" title="Status" render={r => <Tag color={biroStatusToColor(r)}>{biroStatusToString(r)}</Tag>}
                    {...tb.columnProps({ key: 'status', options: biroStatus, type: 'radio' })} />
                <Table.Column key="value" dataIndex="value" title="Total" align="right" render={formatMoney} />
                <Table.Column key="payment_number" dataIndex="biro_payment.number" title="No Pembayaran" render={(t, r) => r.biro_payment === null ? "-" : r.biro_payment.number} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;