import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, InputNumber, Checkbox, Select } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, colors: [] });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    const { ppn, buy_total, sell_ppn, sell_total, motor, ...rest } = values;
                    if (edit) {
                        await Fetch.put(`/masterprice/${props.match.params.id}`, { ...rest, motor_id: rest.motor_id, color_id: rest.color_id || 0 });
                        message.info('Master harga berhasil diedit');
                    } else {
                        await Fetch.post('/masterprice', { ...rest, motor_id: rest.motor_id, color_id: rest.color_id || 0 });
                        message.info('Master harga berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/masterprice');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/masterprice/${props.match.params.id}`);
        setFieldsValue({
            buy_price: data.buy_price,
            ppn: parseInt(data.buy_price / 11),
            buy_total: parseInt(data.buy_price / 11 + data.buy_price),
            sell_price: data.sell_price,
            sell_ppn: parseInt(data.sell_price / 11),
            sell_total: parseInt(data.sell_price / 11 + data.sell_price),
            bbn: data.bbn,
            otr: data.otr,
            motor_id: data.motor_id,
            color_id: data.color_id === 0 ? undefined : data.color_id,
            sell_price_jkt: data.sell_price_jkt,
            sell_ppn_jkt: parseInt(data.sell_price_jkt / 11),
            sell_total_jkt: parseInt(data.sell_price_jkt / 11 + data.sell_price_jkt),
            otr_jkt: data.otr_jkt,
            bbn_pph: data.bbn_pph,
            bbn_ppn: data.bbn_ppn
        });
        Fetch.get(`/color?filter=(motor_id,,eq,,${data.motor_id})`).then(res => {
            setState(s => {
                return { ...s, loading: false, motor: { id: data.motor_id }, colors: res.data }
            })

        });
    }

    const buyPriceChange = (v) => {
        setFieldsValue({
            ppn: parseInt(v * 11 / 100),
            buy_total: parseInt((v * 11 / 100) + v)
        })
    }

    const sellPriceChange = (v) => {
        setFieldsValue({
            sell_ppn: parseInt(v * 11 / 100),
            sell_total: parseInt((v * 11 / 100) + v)
        })
    }

    const sellPriceChangeJkt = (v) => {
        setFieldsValue({
            sell_ppn_jkt: parseInt(v * 11 / 100),
            sell_total_jkt: parseInt((v * 11 / 100) + v)
        })
    }

    const onMotorChange = v => {
        setFieldsValue({ color_id: undefined })
        Fetch.get(`/color?filter=(motor_id,,eq,,${v})`).then(res => setState({ ...state, colors: res.data, motor: v }))
    }

    return (
        <Card title={<Title title={edit ? "Edit master harga" : "Tambah master harga"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_masterprice" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Motor">
                            {getFieldDecorator('motor_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect dataIndex="name" valueIndex="id" placeHolder="Pilih motor" showSearch
                                    url="/motor?limit=1000" onChange={onMotorChange} />
                            )}
                        </Form.Item>
                        {(edit || state.motor) && <Form.Item label="Color">
                            {getFieldDecorator('color_id')(
                                <Select placeholder="Silakan pilih warna" allowClear>
                                    {state.colors.map(v => <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>}
                        <Form.Item label="Harga beli">
                            {getFieldDecorator('buy_price', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" onChange={buyPriceChange} style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                        <Form.Item label="PPN Beli">
                            {getFieldDecorator('ppn')(
                                <InputNumber readOnly className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Beli + PPN">
                            {getFieldDecorator('buy_total')(
                                <InputNumber readOnly className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="BBN">
                            {getFieldDecorator('bbn', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                        <Form.Item label="PPN?">
                            {getFieldDecorator('bbn_ppn', { valuePropName: 'checked' })(
                                <Checkbox>BBN PPN</Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item label="PPh">
                            {getFieldDecorator('bbn_pph', { valuePropName: 'checked' })(
                                <Checkbox>BBN PPh</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={32}>
                    <Col span={12}>
                        <Card size="small" title="Tangerang">
                            <Form.Item label="Harga jual">
                                {getFieldDecorator('sell_price', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputNumber className="my" onChange={sellPriceChange} style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                )}
                            </Form.Item>
                            <Form.Item label="PPN Jual">
                                {getFieldDecorator('sell_ppn')(
                                    <InputNumber readOnly className="my" style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                )}
                            </Form.Item>
                            <Form.Item label="Jual + PPN">
                                {getFieldDecorator('sell_total')(
                                    <InputNumber readOnly className="my" style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                )}
                            </Form.Item>

                            <Form.Item label="OTR">
                                {getFieldDecorator('otr', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                )}
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card size="small" title="Jakarta">
                            <Form.Item label="Harga jual">
                                {getFieldDecorator('sell_price_jkt', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputNumber className="my" onChange={sellPriceChangeJkt} style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                )}
                            </Form.Item>
                            <Form.Item label="PPN Jual">
                                {getFieldDecorator('sell_ppn_jkt')(
                                    <InputNumber readOnly className="my" style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                )}
                            </Form.Item>
                            <Form.Item label="Jual + PPN">
                                {getFieldDecorator('sell_total_jkt')(
                                    <InputNumber readOnly className="my" style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                )}
                            </Form.Item>
                            <Form.Item label="OTR">
                                {getFieldDecorator('otr_jkt', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                        parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                                )}
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/masterprice')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading} >Simpan</Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_masterprice' })(Add);