import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import { spkStatusToString, spkStatusToColor, surveyStatusToString, surveyStatusToColor, spkStatus, surveyStatus, complainStatusToString, complainStatusToColor, complainStatus } from '../../constant'
import { formatDate, getDefaultCurMonth, filterDate } from '../../component/util'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/spk/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/spk/add`);
                }}>Tambah</Button>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [{ filter, order }, tb] = useTable('/spk?order=spk_date desc', { filter: { 'spk.spk_date': getDefaultCurMonth() }, order: { key: 'spk_date', order: 'descend' } })

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/spk/${id}`);
                message.info("Berhasil menghapus spk");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus spk: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/spk/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/spk/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/spk/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            {record.spk_status !== 'do' && <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <a href="void()"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</a>
                </Popconfirm>
            </Menu.Item>}
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar SPK"} actions={<Actions  {...props} refresh={tb.fetchData} exportClick={() => {
            let err = filterDate(filter, 'spk.spk_date')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/spk/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="spk_date" dataIndex="spk_date" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'spk.spk_date', type: 'startend' })}  {...tb.sortProps('spk_date')} />
                <Table.Column key="number" dataIndex="number" title="No SPK" />
                <Table.Column key="region" dataIndex="region.name" title="Cabang"
                    {...tb.columnProps({ key: 'spk.region_id', type: 'selecturl', url: '/region', dataIndex: 'name', valueIndex: 'id' })} />
                <Table.Column key="buyer_name" dataIndex="buyer_name" title="Nama" {...tb.columnProps({ key: 'buyer_name', hint: 'Cari nama' })}
                    {...tb.sortProps('buyer_name')} />
                <Table.Column key="buyer_phone" dataIndex="buyer_phone" title="Telepon" />
                <Table.Column key="buyer_whatsapp" dataIndex="buyer_whatsapp" title="Whatsapp" />
                <Table.Column key="no_ktp" dataIndex="no_ktp" title="NO KTP" />
                <Table.Column key="stnk_name" dataIndex="stnk_name" title="Nama STNK" {...tb.columnProps({ key: 'stnk_name', hint: 'Cari STNK' })}
                    {...tb.sortProps('stnk_name')} />
                <Table.Column key="user" dataIndex="user" title="Marketing" render={t => t == null ? '-' : <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>}
                    {...tb.columnProps({ key: 'spk.user_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="cash" dataIndex="cash" title="Cash" render={t => <Tag color={t ? 'blue' : 'green'}>{t ? 'Cash' : 'Kredit'}</Tag>} />
                <Table.Column key="spk_status" dataIndex="spk_status" title="Status SPK"
                    render={t => <Tag color={spkStatusToColor(t)}>{spkStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'spk_status', type: 'radio', options: spkStatus })} />
                <Table.Column key="surveyor_status" dataIndex="surveyor_status" title="Status Surveyor"
                    render={t => <Tag color={surveyStatusToColor(t)}>{surveyStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'survey_status', type: 'radio', options: surveyStatus })} />
                <Table.Column key="complain_status" dataIndex="complain_status" title="Complain?"
                    render={t => <Tag color={complainStatusToColor(t)}>{complainStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'complain_status', type: 'radio', options: complainStatus })} />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;