import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, InputNumber } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop, MyLink } from '../../component/link'
import { TextField } from '../../component/field'
import { formatDate } from '../../component/util'

const Add = props => {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, net: 0, ppn: 0, pool: 0, sell_ppn: 0, sell_plus_ppn: 0, });
    const col = { labelSpan: 8, valueSpan: 16 }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/sale/${props.match.params.id}`, values);
                        message.info('Penjualan berhasil diedit');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/sale');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/sale/${props.match.params.id}`);
        setFieldsValue({
            buy_price: data.buy_price, buy_discount: data.buy_discount, sell_price: data.sell_price,
            bbn: data.bbn, otr: data.otr,
        });
        const net = data.buy_price - data.buy_discount
        setState({
            ...state, loading: false, record: data, net: net,
            ppn: parseInt(net * 11 / 100), pool: parseInt(net * 11 / 100) + net,
            sell_ppn: parseInt(data.sell_price * 11 / 100), sell_plus_ppn: parseInt(data.sell_price * 11 / 100) + data.sell_price,
        });
    }

    const calculate = (key) => {
        return v => {
            const buy = key === 'buy_price' ? v : getFieldValue('buy_price')
            const disc = key === 'buy_discount' ? v : getFieldValue('buy_discount')
            const net = buy - disc;
            const ppn = parseInt(net * 11 / 100)
            const sell = key === 'sell_price' ? v : getFieldValue('sell_price');
            setState({ ...state, net: net, ppn: ppn, pool: net + ppn, sell_ppn: parseInt(sell * 11 / 100), sell_plus_ppn: parseInt(sell * 11 / 100) + sell })
        }
    }

    const { record } = state;

    return (
        <Card title={<Title title={edit ? "Edit penjualan" : "Tambah stnk"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="edit_sale" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <TextField {...col} label="Tanggal DO">{record ? <MyLink to={`/delivery/${record.delivery_id}`}>{formatDate(record.created_at)}</MyLink> : ''}</TextField>
                        <TextField {...col} label="No DO">{record ? record.delivery.number : ''}</TextField>
                        <TextField {...col} label="Motor">{record ? record.motor.name : ''}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="No Mesin">{record ? <MyLink to={`/stock/${record.stock.id}`}>{record.stock.machine_number}</MyLink> : ''}</TextField>
                        <TextField {...col} label="No SPK">{record ? <MyLink to={`/spk/${record.spk.id}`}>{record.spk.number}</MyLink> : ''}</TextField>
                        <TextField {...col} label="Leasing">{record ? record.leasing !== null ? record.leasing.name : 'CASH' : ''}</TextField>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Harga Beli">
                            {getFieldDecorator('buy_price', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculate('buy_price')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Diskon Pembelian">
                            {getFieldDecorator('buy_discount', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculate('buy_discount')} />
                            )}
                        </Form.Item>
                        <TextField {...col} label="Net Pembelian"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {state.net.toLocaleString('in-ID')}</div></TextField>
                        <TextField {...col} label="PPN"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {state.ppn.toLocaleString('in-ID')}</div></TextField>
                        <TextField {...col} label="Harga Pool"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {state.pool.toLocaleString('in-ID')}</div></TextField>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Harga Jual">
                            {getFieldDecorator('sell_price', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculate('sell_price')} />
                            )}
                        </Form.Item>
                        <TextField {...col} label="PPN Jual"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {state.sell_ppn.toLocaleString('in-ID')}</div></TextField>
                        <TextField {...col} label="Jual + PPN"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {state.sell_plus_ppn.toLocaleString('in-ID')}</div></TextField>
                        <Form.Item label="BBN">
                            {getFieldDecorator('bbn', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                        <Form.Item label="OTR">
                            {getFieldDecorator('otr', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber className="my" style={{ width: '100%', textAlign: 'right' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={32}>
                    <Col span={12}>
                        <TextField {...col} label="Subsidi + Intensif"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {record ? record.subsidi_intensive.toLocaleString('in-ID') : ''}</div></TextField>
                        <TextField {...col} label="Diskon"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {record ? record.discount.toLocaleString('in-ID') : ''}</div></TextField>
                        <TextField {...col} label="Margin"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {record ? (record.sell_price + record.buy_discount - record.buy_price - record.discount + record.subsidi_intensive).toLocaleString('in-ID') : ''}</div></TextField>
                    </Col>
                    <Col span={12}>
                        <TextField {...col} label="DP"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {record && !record.spk.cash ? record.dp.toLocaleString('in-ID') : ''}</div></TextField>
                        <TextField {...col} label="Tanggal DP">{record && !record.spk.cash ? formatDate(record.dp_date) : ''}</TextField>
                        <TextField {...col} label="Sisa Otr"><div style={{ width: '100%', textAlign: 'right', paddingRight: 10 }}>
                            {record ? record.spk.cash ? record.otr.toLocaleString('in-ID') : (record.otr - record.dp).toLocaleString('in-ID') : ''}</div></TextField>
                        <TextField {...col} label="Tanggal Pelunasan">{record ? formatDate(record.payment_date) : ''}</TextField>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={() => pop(props, '/stnk')} >Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={state.saveLoading}> Simpan </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'edit_sale' })(Add);